import { ListResult } from 'common/api/v1/types'
import { MetadataTag } from 'common/api/v1/types'
import { SortOrder } from 'common/api/v1/types'
import { TagFilter } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'
import { Query } from 'common/query'
import { PaginatedRequestParams } from '../nm-types'
import { singleSortQueryFromPaginatedRequestParams } from '../nm-types'

export interface ITagApi {
  fetchTags(paginatedRequestParams: PaginatedRequestParams<'name'>): Promise<ListResult<MetadataTag>>
  listTags(query?: Query<TagFilter, SortOrder<'name'>>): Promise<ListResult<MetadataTag>>
}

export class TagApi implements ITagApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  fetchTags({ filter: searchName, ...paginatedRequestParams }: PaginatedRequestParams<'name'>) {
    const filter: TagFilter = { searchName }
    const query = singleSortQueryFromPaginatedRequestParams({
      filter,
      paginatedRequestParams,
    })
    return this.listTags(query)
  }
  listTags(query?: Query<TagFilter, SortOrder<'name'>>): Promise<ListResult<MetadataTag>> {
    return this.edgeClient.listTags(query)
  }
}
