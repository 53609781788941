import { Overlay } from 'common/api/v1/types'
import { deleteOverlay, listOverlays, updateOverlay } from '../actions/overlaysActions'
import { createLoadingReducer } from './shared'
import { createSlice } from '@reduxjs/toolkit'
import { logoutUser } from '../actions/userActions'

interface State {
  loading: boolean
  saving: boolean
  overlays: Overlay[]
  selectedOverlay?: Overlay
}

export const initialStateOverlays: State = {
  loading: false,
  saving: false,
  overlays: [],
  selectedOverlay: undefined,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(listOverlays)

const overlaysSlice = createSlice({
  name: 'overlays',
  initialState: initialStateOverlays,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateOverlay.pending, (state): State => ({ ...state, saving: true }))
      .addCase(updateOverlay.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(
        updateOverlay.fulfilled,
        (state, { payload: overlay }): State => ({
          ...state,
          saving: false,
          overlays: [overlay, ...state.overlays.filter((o) => o.id !== overlay.id)],
        }),
      )
      .addCase(deleteOverlay.pending, (state): State => ({ ...state, saving: true }))
      .addCase(deleteOverlay.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(
        deleteOverlay.fulfilled,
        (state, { payload: e }): State => ({
          ...state,
          saving: false,
          overlays: state.overlays.filter((o) => o.id !== e.id),
        }),
      )
      .addCase(listOverlays.fulfilled, (state, { payload: overlays }): State => ({ ...state, overlays }))
      .addCase(logoutUser.fulfilled, (): State => initialStateOverlays)
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default overlaysSlice.reducer
