import { OverlayComponent } from 'common/api/v1/types'
import { TextEditor } from './TextEditor'
import { ImageEditor } from './ImageEditor'

export const ComponentEditor = ({
  component,
  index,
  updateComponent,
}: {
  component: OverlayComponent
  index: number
  updateComponent: (index: number, component?: OverlayComponent) => void
}) => {
  if (component.type === 'image')
    return <ImageEditor component={component} index={index} updateComponent={updateComponent} />

  if (component.type === 'text')
    return <TextEditor component={component} index={index} updateComponent={updateComponent} />

  return null
}
