import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'

import { EnrichedInput } from '../../../api/nm-types'
import { TranscodingSection } from './TranscodingSection'
import { MptsDemuxSection } from './MptsDemuxSection'
import { Paper, Select } from '../../common/Form'
import { InputFormProps } from '../Edit/InputForm'
import { GlobalState } from '../../../store'
import { shallowEqual, useSelector } from 'react-redux'
import { AudioReshuffling } from './AudioReshuffling'
import { formatTransformationType } from '../../../utils'
import { pick } from 'common/util'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

export interface InputTransformationSectionProps {
  currentParentInput: EnrichedInput
}

export const InputTransformationSection = ({ currentParentInput }: InputTransformationSectionProps) => {
  const { getValues, watch } = useFormContext<InputFormProps & AllowUncontrolled>()
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => pick(settingsReducer, 'devMode'), shallowEqual)
  const [transformation, setTransformation] = useState<'mpts-demux' | 'transcode' | 'audio-reshuffling' | ''>('')

  const values = getValues()

  // Watch specific fields that control conditional rendering
  watch('deriveFrom.ingestTransform.type')

  useEffect(() => {
    if (values.deriveFrom?.ingestTransform?.type) {
      setTransformation(values.deriveFrom.ingestTransform.type)
    }
  }, [values.deriveFrom?.ingestTransform?.type])

  return (
    <Paper className="outlined" title="Transformation">
      <Grid container>
        <Paper>
          <Select
            label="Select transformation"
            name="deriveFrom.ingestTransform.type"
            options={[
              { name: formatTransformationType('mpts-demux'), value: 'mpts-demux' },
              { name: formatTransformationType('transcode'), value: 'transcode', disabled: !devMode },
              { name: formatTransformationType('audio-reshuffling'), value: 'audio-reshuffling', disabled: !devMode },
            ]}
          />

          {transformation === 'mpts-demux' && (
            <Grid item xs={12}>
              <MptsDemuxSection currentParentInput={currentParentInput} />
            </Grid>
          )}
          {transformation === 'transcode' && <TranscodingSection />}

          {transformation === 'audio-reshuffling' && <AudioReshuffling />}
        </Paper>
      </Grid>
    </Paper>
  )
}
