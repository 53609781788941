import Typography from '@mui/material/Typography'

import { ListPortSortableField } from 'common/api/v1/types'
import {
  alarmsThatDisablePort,
  getApplianceOwnerId,
  getInterfaceName,
  isEditableAppliance,
  isEditableGroup,
  isEditableInterface,
  pluralize,
  useInterfacesSelector,
  usePageParamsFilteredSelector,
  useUser,
} from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import { EnrichedPhysicalPort } from '../../api/nm-types'
import Toolbar from '@mui/material/Toolbar'
import {
  ListInterfaceFilterKey,
  makeListInterfacesFilter,
  mapInterfaceFilterToUrlParam,
  mapUrlParamToInterfaceFilter,
} from './listInterfaceFilter'

import { useRoutes } from '../../store'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'

export const ListView = () => {
  const routes = useRoutes()
  const { ports: interfaces, total, loading } = usePageParamsFilteredSelector(useInterfacesSelector)
  const user = useUser()

  const tableConfig: TableConfig<EnrichedPhysicalPort> = [
    {
      title: 'name',
      getValue: (port) => getInterfaceName(port),
      sorting: { byParameter: ListPortSortableField.portName },
    },
    {
      title: 'address',
      getValue: (port) => port.addresses[0]?.address,
      sorting: { byParameter: ListPortSortableField.address },
    },
    {
      title: 'public address',
      getValue: (port) => port.addresses[0]?.publicAddress,
      sorting: { byParameter: ListPortSortableField.publicAddress },
    },
    {
      title: 'appliance',
      getValue: ({ appliance: { name, id }, _appliance }) => (
        <Link
          to={routes.appliancesUpdate({ id })}
          underline="hover"
          available={!!_appliance && isEditableAppliance(user, getApplianceOwnerId(_appliance))}
        >
          {name}
        </Link>
      ),
      sorting: { byParameter: ListPortSortableField.applianceName },
    },
    {
      title: 'owner group',
      getValue: ({ _owner }) => (
        <Link
          to={routes.groupsUpdate({ id: _owner.id })}
          available={isEditableGroup(_owner.id, user)}
          underline="hover"
        >
          {_owner.name}
        </Link>
      ),
      sorting: { byParameter: ListPortSortableField.ownerGroupName },
    },
    {
      title: 'alarms',
      getValue: ({ _appliance, id: portId }) => {
        const alarms = _appliance?.alarms.filter((alarm) => alarm.physicalPortId == portId)
        if (!alarms || alarms.length === 0) return 'no alarms'
        const portDisabledAlarms = alarmsThatDisablePort(_appliance, portId)
        if (portDisabledAlarms && portDisabledAlarms.length > 0) {
          return (
            <>
              {pluralize(alarms.length, 'alarm')}, interface is disabled
              {portDisabledAlarms.map((portAlarm, i) => {
                return (
                  <Typography
                    key={portAlarm.alarmId.concat(i.toString())}
                    component="div"
                    variant="body2"
                    color="textSecondary"
                  >
                    {portAlarm.text}
                  </Typography>
                )
              })}
            </>
          )
        }

        return <>{pluralize(alarms.length, 'alarm')}</>
      },
    },
    {
      getValue: ({ id: portId, _appliance }) =>
        !!_appliance && isEditableInterface(getApplianceOwnerId(_appliance), user) ? (
          <MoveToEntity
            url={routes.interfacesUpdate({ id: portId })}
            entityName="interface"
            dataTestId={`edit-${portId}`}
          />
        ) : null,
      props: { align: 'right' },
    },
  ]

  const filters = makeListInterfacesFilter()
  return (
    <Wrapper name="(Deprecated) Interfaces">
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'interfaces'}
            mapUrlParamToFilterFn={mapUrlParamToInterfaceFilter}
            mapFilterToUrlParamFn={mapInterfaceFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListInterfaceFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Table
        emptyMessageComponent={<MissingContent message="No interfaces available" />}
        config={tableConfig}
        data={interfaces}
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
