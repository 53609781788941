import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useTheme, Theme } from '@mui/material/styles'

import { MaxBitrates, Role } from 'common/api/v1/types'
import { Api, type AppDispatch } from '../../../store'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import { NimbraBlue } from '../../../themes/common'

import Pendable from '../../common/Pendable'
import { useUser } from '../../../utils'
import { FailedFetchingContent } from '../../common/FailedFetchingContent'

const HEIGHT = 280

const styles = {
  empty: {
    margin: 'auto',
    padding: (theme: Theme) => theme.spacing(8, 0, 6),
  },
  container: {
    position: 'relative',
    width: '100%',
    height: `${HEIGHT}px`,
  },
}

type DataPoint = { timestamp: number; ingress: number | null; egress: number | null }
type Data = {
  series: DataPoint[]
  error: false
}
type Error = {
  error: true
}

// Merge both input and output bitrates to one series while also handling missing entries in any of the series
export function transformData({ inputMaxBitrates, outputMaxBitrates }: MaxBitrates): DataPoint[] {
  const dataPoints = new Map<number, DataPoint>()

  for (const inputBitrate of inputMaxBitrates) {
    const date = Date.parse(inputBitrate[0])
    if (!dataPoints.has(date)) {
      dataPoints.set(date, { timestamp: date, ingress: null, egress: null })
    }

    dataPoints.set(date, {
      ...dataPoints.get(date)!,
      ingress: transformBitsToMegabits(inputBitrate[1]),
    })
  }

  for (const outputBitrate of outputMaxBitrates) {
    const date = Date.parse(outputBitrate[0])
    if (!dataPoints.has(date)) {
      dataPoints.set(date, { timestamp: date, ingress: null, egress: null })
    }

    dataPoints.set(date, {
      ...dataPoints.get(date)!,
      egress: transformBitsToMegabits(outputBitrate[1]),
    })
  }

  return addNullToGaps(Array.from(dataPoints.values()).sort((a, b) => a.timestamp - b.timestamp))
}

// Add null entries at gaps where data is missing
export const addNullToGaps = (dataPoints: DataPoint[]): DataPoint[] => {
  if (!dataPoints.length) {
    return []
  }
  let prevTS = dataPoints[0].timestamp
  const dataPointsWithNullGaps: DataPoint[] = []
  for (const dataPoint of dataPoints) {
    const ts = dataPoint.timestamp
    if (ts - prevTS > 11 * 60 * 1000) {
      // if gap larger than 11 minutes
      dataPointsWithNullGaps.push({ timestamp: prevTS + 10 * 60 * 1000, ingress: null, egress: null })
    }
    dataPointsWithNullGaps.push(dataPoint)
    prevTS = ts
  }
  return dataPointsWithNullGaps
}

const transformBitsToMegabits = (bits: number | null) => {
  return bits === null ? null : bits / 1024 / 1024
}
const dateToShortForm = (date: string): string => `${format(new Date(date), 'HH:mm')}`

const Graph = () => {
  const dispatch = useDispatch<AppDispatch>()
  const user = useUser()
  const titlePrefix = user.role === Role.super ? 'Total' : 'Group'
  const [data, setData] = useState<false | Data | Error>(false)
  const timer = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api.bitratesApi.readBitrates()
        setData({ series: transformData(res), error: false })
      } catch (error) {
        dispatch(enqueueErrorSnackbar({ error, operation: 'fetch bitrate' }))
        setData({ error: true })
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    timer.current = setInterval(fetchData, 1000 * 60)
    fetchData()
    return () => {
      if (timer.current) clearInterval(timer.current)
    }
  }, [user])

  const theme = useTheme()

  if (data && data.error) {
    return <FailedFetchingContent message={'Failed to fetch ingress and egress bitrates.'} />
  }

  return data && !data.error && !data.series.length ? (
    <Box sx={styles.empty}>
      <Typography variant="h2" align="center">
        Bitrate overview
      </Typography>
      <Typography variant="body1" align="center">
        There is currently no ingress or egress related to this group.
      </Typography>
    </Box>
  ) : (
    <>
      <Toolbar disableGutters variant="dense">
        <Typography variant="h2">
          {data && data.series && !data.series.length
            ? 'Currently no ingress or egress'
            : `${titlePrefix} ingress and egress`}
        </Typography>
      </Toolbar>

      <Box sx={styles.container}>
        <Pendable pending={!data}>
          {data && (
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={data.series}>
                <XAxis
                  dataKey="timestamp"
                  type={'number'}
                  domain={[Date.now() - 24 * 60 * 60 * 1000, Date.now()]}
                  tickFormatter={(value) => dateToShortForm(value)}
                  stroke="white"
                  axisLine={{ stroke: 'white' }}
                  tick={{ fill: 'white' }}
                />
                <YAxis stroke="white" axisLine={{ stroke: 'white' }} tick={{ fill: 'white' }} />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  formatter={(value) => (typeof value === 'number' ? value.toFixed(3) : value)}
                  labelFormatter={(label) => dateToShortForm(label)}
                />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="ingress"
                  dot={false}
                  activeDot={{ r: 4 }}
                  unit={' Mbps'}
                  fill={NimbraBlue[100]}
                  fillOpacity={0.5}
                  stroke={NimbraBlue[100]}
                  strokeWidth={2}
                />
                <Area
                  type="monotone"
                  dataKey="egress"
                  dot={false}
                  activeDot={{ r: 4 }}
                  unit={' Mbps'}
                  fill={theme.palette.primary.main}
                  fillOpacity={0.5}
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Pendable>
      </Box>
    </>
  )
}

export default Graph
