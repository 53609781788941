import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'

import SearchInput from '../../../common/SearchInput'
import Pendable from '../../../common/Pendable'
import { getOutputsWithLists } from '../../../../redux/actions/outputsActions'
import { useDebouncedFilter, useOutputsWithListsSelector } from '../../../../utils'
import { AppDispatch, GlobalState } from '../../../../store'

import Item from './Item'
import { SIDE_PADDING } from './Item/style'
import { Pagination } from '../../../common/Table/Pagination'
import useWindowDimensions from '../../../../utils/hooks'
import Divider from '@mui/material/Divider'

const LOCALE_STORAGE_PER_PAGE_KEY = 'edge:sm:outputsPerPage'
const headerFooterHeight = 58

const styles = {
  container: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    background: (theme: Theme) => theme.palette.background.paper,
  },
  toolbar: {
    maxHeight: headerFooterHeight,
    padding: (theme: Theme) => theme.spacing(0, SIDE_PADDING),
  },
  outputList: {
    marginTop: 0,
    marginBottom: 0,
    padding: (theme: Theme) => theme.spacing(0, 0, 3),
    height: `calc(100% - ${headerFooterHeight * 2}px)`,
    overflowY: 'auto',
    alignContent: 'flex-start',
  },
  pagination: {
    height: headerFooterHeight,
    backgroundColor: 'background.paper',
  },
}

const Outputs = () => {
  const [filter, setFilter] = useState('')
  const [pageNumber, setPageNumber] = useState('0')
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem(LOCALE_STORAGE_PER_PAGE_KEY) || '10')
  const debouncedFilter = useDebouncedFilter(filter)
  const params = {
    pageNumber,
    rowsPerPage,
    filter: debouncedFilter || undefined,
  }
  const { outputsWithLists, total, loading, saving } = useOutputsWithListsSelector(params)
  const { dialogSaving: listSending } = useSelector(
    ({ inputsReducer }: GlobalState) => ({
      dialogSaving: inputsReducer.dialogSaving,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch<AppDispatch>()
  const { width } = useWindowDimensions()
  const hidePerPagePaginationOption = width < 1330

  useEffect(() => {
    if (saving || listSending) {
      return
    }

    dispatch(getOutputsWithLists(params))
  }, [saving, listSending, pageNumber, rowsPerPage, debouncedFilter])

  return (
    <Pendable id="outputs" pending={loading || saving || listSending} cover coverContainerProps={styles.container}>
      <Toolbar disableGutters sx={styles.toolbar} id="outputs-toolbar">
        <SearchInput
          onChange={(input) => {
            setPageNumber('0')
            setFilter(input)
          }}
        />
      </Toolbar>
      <Box sx={styles.outputList}>
        {outputsWithLists.map((item) => (
          <Item output={item} key={item.id} />
        ))}
      </Box>
      <Box sx={styles.pagination}>
        <Divider />
        <Pagination
          total={total}
          page={pageNumber}
          perPage={rowsPerPage}
          changePageCallback={setPageNumber}
          changeRowsPerPageCallback={(rows) => {
            setPageNumber('0')
            localStorage.setItem(LOCALE_STORAGE_PER_PAGE_KEY, rows)
            setRowsPerPage(rows)
          }}
          useUrlSearchParams={false}
          id={'output-pagination'}
          hidePerPageOption={true}
          hideJumpToPage={hidePerPagePaginationOption}
        />
      </Box>
    </Pendable>
  )
}

export default Outputs
