import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Theme } from '@mui/material/styles'

import { Link } from './Link'
import { usePageParams } from '../../utils'
import { PaginatedRequestParams } from '../../api/nm-types'
import { omit } from 'common/api/v1/helpers'

const styles = {
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  message: {
    textTransform: 'uppercase',
  },
  buttonWrapper: {
    margin: (theme: Theme) => theme.spacing(2),
  },
  button: {
    margin: (theme: Theme) => theme.spacing(2),
  },
}

export interface MissingContentProps {
  message: string
  buttonText?: string
  url?: string
}

/**
 * Common component to show on empty list returned for entities list page
 * @param message - the message to show
 * @param buttonText - the text for the button that leads to create the entity instance
 * @param url - url for the entity creation form
 */
export const MissingContent = ({ message, buttonText, url }: MissingContentProps) => {
  const [pageParams, setPageParams] = usePageParams<PaginatedRequestParams & { deriveFrom?: string }>()
  const nonFilteringUrlParams: Array<keyof typeof pageParams> = ['rowsPerPage', 'asc', 'desc', 'deriveFrom']
  const numAppliedFilters = Object.keys(omit(pageParams, ...nonFilteringUrlParams)).length
  const noAppliedFilters = numAppliedFilters === 0 || (numAppliedFilters === 1 && pageParams['pageNumber'] === '0') // pageNumber > 0 counts as a filter since it is possible to manually set page number in URL
  if (!noAppliedFilters) {
    const { filter: genericFilterString } = pageParams
    const msg = genericFilterString ? `Could not find "${genericFilterString}"` : 'No matching items found'
    return (
      <Box sx={styles.centered}>
        <Typography sx={styles.message} variant="h2">
          {msg}
        </Typography>
        <Button
          sx={styles.button}
          variant="outlined"
          data-test-id="clear-search-button"
          onClick={() => {
            const newPageParams = Object.keys(pageParams).reduce((acc, k) => {
              const key = k as keyof typeof pageParams
              if (!nonFilteringUrlParams.includes(key)) acc[key] = '' // key performs filtering - remove it
              return acc
            }, {} as typeof pageParams)
            setPageParams(newPageParams)
          }}
        >
          Clear search
        </Button>
      </Box>
    )
  }

  return (
    <Box sx={styles.centered}>
      <Typography sx={styles.message} variant="h2">
        {message}
      </Typography>
      {url && buttonText && (
        <Box sx={styles.buttonWrapper}>
          <Link type="button" to={url}>
            <Button variant="outlined">{buttonText}</Button>
          </Link>
        </Box>
      )}
    </Box>
  )
}
