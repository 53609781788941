import { Address, Output, RistProfile } from 'common/api/v1/types'
import { Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

export enum RistFields {
  profile = 'profile',
  sourceAddress = 'sourceAddress',
  address = 'address',
  port = 'port',
}

export const ristDefaults = () => createDefaultFiledValues(Object.keys(RistFields), [])
export const getRistFieldsToSave = () => Object.keys(RistFields)

interface RistFormProps {
  addresses: Array<Address>
  namePrefix: string
}
const RistForm = ({ namePrefix, addresses }: RistFormProps) => {
  const { getValues } = useFormContext<Output & AllowUncontrolled>()

  const sourceAddressSelector = `${namePrefix}.${RistFields.sourceAddress}`
  const selectedSourceAddress = getValues(sourceAddressSelector)
  const addressesAndEmptyAddress = [{ address: '', netmask: '' }, ...addresses]
  const addressOptions = [{ name: 'any', value: '' }, ...makeAddressOptions(selectedSourceAddress, addresses)]

  return (
    <>
      <Select name={`${namePrefix}.${RistFields.profile}`} label="Profile" options={[RistProfile.simple]} required />
      <Select
        name={sourceAddressSelector}
        label="Source Address"
        options={addressOptions}
        newLine
        validators={{
          addressIn: { addresses: addressesAndEmptyAddress },
        }}
      />
      <TextInput
        name={`${namePrefix}.${RistFields.address}`}
        label="Destination address"
        required
        validators={{ ip: {} }}
        type="text"
        newLine
      />
      <TextInput
        name={`${namePrefix}.${RistFields.port}`}
        label="Destination Port"
        required
        type="number"
        noNegative
        validators={{
          port: { even: true, isUdp: true },
        }}
      />
    </>
  )
}

export default RistForm
