import Delete from '@mui/icons-material/Delete'
import Upload from '@mui/icons-material/Upload'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { OverlayComponent, OverlayComponentImage } from 'common/api/v1/types'
import { CoordinateControls } from './CoordinateControls'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import Slider from '@mui/material/Slider'
const convertBytesToMB = (bytes: number) => bytes / 1024 / 1024
const convertMBToBytes = (mb: number) => mb * 1024 * 1024
const MAX_SIZE = convertMBToBytes(1)

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export const ImageEditor = ({
  component,
  index,
  updateComponent,
}: {
  component: OverlayComponentImage
  index: number
  updateComponent: (index: number, component?: OverlayComponent) => void
}) => {
  // get base64 png image
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (file.size > MAX_SIZE) {
          alert(
            `File size ${convertBytesToMB(file.size).toFixed(1)} MB is too big, max size is ${convertBytesToMB(
              MAX_SIZE,
            ).toFixed(0)} MB`,
          )
          return
        }

        if (e.target && typeof e.target.result === 'string') {
          updateComponent(index, { ...component, data: e.target.result })
        }
      }
      reader.readAsDataURL(file)
    }
  }
  const [areYouSure, setAreYouSure] = useState(false)

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ minWidth: '100%', pr: 1 }}
        >
          <Typography variant="h5">Image</Typography>
          <img src={component.data} style={{ maxWidth: '100%', maxHeight: '20px' }} />

          <Box sx={{ flexGrow: 1 }} />

          {!areYouSure && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setAreYouSure(true)
                setTimeout(() => setAreYouSure(false), 5000)
              }}
            >
              <Delete />
            </IconButton>
          )}
          {areYouSure && (
            <Button
              variant="text"
              color="error"
              onClick={(e) => {
                e.stopPropagation()
                setAreYouSure(false)
                updateComponent(index, undefined)
              }}
            >
              Are you sure?
            </Button>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<Upload />}>
              Upload image
              <VisuallyHiddenInput type="file" onChange={handleFileChange} accept="image/png" />
            </Button>
            <Typography variant="body2" color="textSecondary">
              {`Max size is ${convertBytesToMB(MAX_SIZE).toFixed(0)} MB. Supported formats: PNG`}
            </Typography>
          </Stack>
          <CoordinateControls component={component} index={index} updateComponent={updateComponent} />
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="bound-width-label">Bound width</InputLabel>
              <OutlinedInput
                id="bound-width"
                label="Bound width"
                type="number"
                endAdornment={<InputAdornment position="end">px</InputAdornment>}
                value={component.maxWidth}
                onChange={(event) =>
                  updateComponent(index, { ...component, maxWidth: parseInt(event.target.value) || 0 })
                }
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="bound-height-label">Bound height</InputLabel>
              <OutlinedInput
                id="bound-height"
                label="Bound height"
                type="number"
                endAdornment={<InputAdornment position="end">px</InputAdornment>}
                value={component.maxHeight}
                onChange={(event) =>
                  updateComponent(index, { ...component, maxHeight: parseInt(event.target.value) || 0 })
                }
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            </FormControl>
            <Box sx={{ minWidth: 200 }}>
              <Typography id="input-slider" gutterBottom>
                Opacity
              </Typography>
              <Slider
                id="opacity-slider"
                value={component.opacity}
                onChange={(_, newValue) =>
                  updateComponent(index, {
                    ...component,
                    opacity: newValue as number,
                  })
                }
                aria-labelledby="opacity-slider"
                step={0.1}
                min={0}
                max={1}
                valueLabelDisplay="auto"
              />
            </Box>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
