import type { DeletedOverlay, Overlay } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IOverlaysApi {
  listOverlays(): Promise<Overlay[]>
  getOverlay(id: Overlay['id']): Promise<Overlay>
  updateOverlay(overlay: Overlay): Promise<Overlay>
}

export class OverlaysApi implements IOverlaysApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  listOverlays(): Promise<Overlay[]> {
    return this.edgeClient.listOverlays()
  }

  getOverlay(id: string): Promise<Overlay> {
    return this.edgeClient.getOverlay(id)
  }

  updateOverlay(overlay: Overlay): Promise<Overlay> {
    return this.edgeClient.createOverlay(overlay)
  }

  deleteOverlay(id: string): Promise<DeletedOverlay> {
    return this.edgeClient.deleteOverlay(id)
  }
}
