import { createSlice } from '@reduxjs/toolkit'
import { saveUrlParams } from '../actions/urlParamActions'
import {
  AlarmsRequestParams,
  AppliancesRequestParams,
  GroupListRequestParams,
  GroupsRequestParams,
  InputsRequestParams,
  KubernetesNodesRequestParams,
  NetworksRequestParams,
  OutputListRequestParams,
  OutputsRequestParams,
  PaginatedRequestParams,
  PortsRequestParams,
  ServicesRequestParams,
  UsersRequestParams,
} from '../../api/nm-types'
import { logoutUser } from '../actions/userActions'

interface State {
  cachedUrlParams: UrlParamCache
}

export interface UrlParamCache {
  addressMappings?: PaginatedRequestParams
  alarms?: AlarmsRequestParams
  appliances?: AppliancesRequestParams
  groups?: GroupsRequestParams
  groupLists?: GroupListRequestParams
  inputs?: InputsRequestParams
  interfaces?: PortsRequestParams
  k8sNodes?: KubernetesNodesRequestParams
  k8sServices?: ServicesRequestParams
  networks?: NetworksRequestParams
  outputs?: OutputsRequestParams
  outputLists?: OutputListRequestParams
  regions?: PaginatedRequestParams
  users?: UsersRequestParams
}

const initialState: State = {
  cachedUrlParams: {},
}
const slice = createSlice({
  name: 'urlParam',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveUrlParams, (state, { payload: { key, urlParams } }): typeof initialState => {
        return {
          ...state,
          cachedUrlParams: {
            ...state.cachedUrlParams,
            [key]: urlParams,
          },
        }
      })
      .addCase(logoutUser.fulfilled, (): State => initialState)
  },
})

export default slice.reducer
