import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { NavigateFunction } from 'react-router-dom'
import { ThunkApi } from '../../store'
import { initAlarms } from './alarmsActions'
import { initAppliance } from './applianceActions'
import { initBuildInfo } from './buildInfoActions'

export const init = createAsyncThunk<void, NavigateFunction, ThunkApi>(
  'ui/init',
  async (navigate, { dispatch, extra: { setNavigate } }) => {
    setNavigate(navigate)
    dispatch(initAppliance())
    dispatch(initAlarms())
    dispatch(initBuildInfo())
  },
)

export const toggleSidebarMenu = createAction<boolean | undefined>('ui/toggleSidebarMenu')
