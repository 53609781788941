import React from 'react'
import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import OpenInNew from '@mui/icons-material/OpenInNew'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { Theme } from '@mui/material/styles'
import { format } from 'date-fns'

import { Appliance, ApplianceType, Role, User } from 'common/api/v1/types'
import { DATE_FORMAT_LONG, getProductName } from 'common/api/v1/helpers'
import { isEditableGroup, pluralize, runningDifferentSoftwareVersion, useUser } from '../../../../utils'
import { api } from '../../../../utils/routes'
import { GlobalState, useRoutes } from '../../../../store'
import { GridItem, Paper } from '../../../common/Form'
import DataSet from '../../../common/DataSet'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { ExternalLink, Link } from '../../../common/Link'
import { AutoUpdatingApplianceHealthIndicator } from '../../../common/Indicator'
import { defaultRegionId } from 'common/constants'
import { useSelector } from 'react-redux'
import { ApplianceProxyButton } from '../../../common/ApplianceProxyButton'
import { equals } from 'common/util'

const styles = {
  link: {
    display: 'flex',
    transition: (theme: Theme) => theme.transitions.create('color'),
    paddingTop: (theme: Theme) => theme.spacing(1),
  },
  text: {
    marginRight: (theme: Theme) => theme.spacing(1),
  },
  warningIcon: {
    verticalAlign: 'top',
    marginRight: '4px',
  },
  warningText: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.warning.light,
  },
  versionChipsContainer: {
    '& div': {
      marginRight: (theme: Theme) => theme.spacing(1),
    },
  },
}

export interface MetaProps {
  appliance: Appliance
}

const ApplianceVersionInfo = (applianceType: ApplianceType, imageVersion?: string, softwareVersion?: string) => {
  const { buildInfo } = useSelector(
    ({ buildInfoReducer }: GlobalState) => ({ buildInfo: buildInfoReducer.buildInfo }),
    equals,
  )

  return (
    <>
      <Box sx={styles.versionChipsContainer}>
        {!isVaApplianceType(applianceType) && <Chip size="small" label={`Image: ${imageVersion || 'N/A'}`} />}
        <Chip size="small" label={`Software: ${softwareVersion || 'N/A'}`} />
      </Box>
      {runningDifferentSoftwareVersion(softwareVersion, buildInfo) && (
        <Box sx={styles.warningText}>
          <ErrorOutline sx={styles.warningIcon} />
          Software version is outdated, restart appliance to update
        </Box>
      )}
    </>
  )
}

const ApplianceOwnerLink = (appliance: Appliance, user: User) => {
  const routes = useRoutes()
  return typeof appliance.owner === 'string' ? (
    ''
  ) : (
    <Link
      to={routes.groupsUpdate({ id: appliance.owner.id })}
      available={isEditableGroup(appliance.owner.id, user)}
      underline="hover"
    >
      {appliance.owner.name}
    </Link>
  )
}

const Meta: React.FunctionComponent<MetaProps> = ({ appliance }) => {
  const routes = useRoutes()
  const user = useUser()
  const controlImageVersion = appliance.version.controlImageVersion
  const controlSoftwareVersion = appliance.version.controlSoftwareVersion
  const dataImageVersion = appliance.version.dataImageVersion
  const dataSoftwareVersion = appliance.version.dataSoftwareVersion
  const linkIsShown = isVaApplianceType(appliance.type)

  const isSuperUser = user.role === Role.super
  const showHostMetrics =
    isSuperUser &&
    appliance.region.id === defaultRegionId && // External regions are not supported EDGE-2561
    [ApplianceType.core, ApplianceType.thumb].includes(appliance.type)

  const metrics = isSuperUser
    ? {
        Metrics: (
          <>
            {showHostMetrics && (
              <span style={{ marginRight: '8px' }}>
                <ExternalLink
                  underline="always"
                  href={`/grafana/d/000000127/kubernetes-node-metrics?orgId=1&var-DS_PROMETHEUS=Prometheus&var-job=node-exporter&var-name=${appliance.hostname}`}
                >
                  Kubernetes Node Metrics
                </ExternalLink>
              </span>
            )}
            <ExternalLink
              href={`/grafana/d/appliance-overview/appliance-host-metrics?orgId=1&var-appliance=${appliance.name}`}
              underline="always"
            >
              Appliance Host Metrics
            </ExternalLink>
          </>
        ),
      }
    : {}

  const lokiLabel = 'edge_component_name'
  const links = isSuperUser
    ? {
        Logs: (
          <ExternalLink
            underline="always"
            href={`/grafana/explore?left=%7B%22datasource%22:%22loki%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22datasource%22:%7B%22type%22:%22loki%22,%22uid%22:%22loki%22%7D,%22editorMode%22:%22code%22,%22expr%22:%22%7B${lokiLabel}%3D%5C%22${appliance.name}%5C%22%7D%20%7C%20json%20%7C%20line_format%20%60%7B%7B.message%7D%7D%60%22,%22queryType%22:%22range%22%7D%5D,%22range%22:%7B%22from%22:%22now-1h%22,%22to%22:%22now%22%7D%7D&orgId=1`}
          >
            Logs
          </ExternalLink>
        ),
      }
    : {}

  const meta = {
    'Node name': (
      <span>
        {appliance.name}
        <ApplianceProxyButton user={user} appliance={appliance} iconButtonProps={{ height: 24 }} />
      </span>
    ),
    Hostname: appliance.hostname,
    Tags: (appliance.tags ?? []).join(', '),
    Contact: appliance.contact,
    'Product name': getProductName(appliance.type),
    'Node serial number': appliance.serial,
    ...(isVaApplianceType(appliance.type) ? { 'Running VA software': appliance.version.vaVersion } : {}),
    'Running control software': ApplianceVersionInfo(appliance.type, controlImageVersion, controlSoftwareVersion),
    'Running data software': ApplianceVersionInfo(appliance.type, dataImageVersion, dataSoftwareVersion),
    Status: <AutoUpdatingApplianceHealthIndicator applianceId={appliance.id} inline={true} />,
    'Running since': (appliance.lastRegisteredAt && format(appliance.lastRegisteredAt, DATE_FORMAT_LONG)) || 'N/A',
    ...metrics,
    Alarms: appliance.alarms.length ? (
      <Link to={routes.alarms({ applianceId: appliance.id, applianceName: appliance.name })} underline="hover">
        {pluralize(appliance.alarms.length, 'alarm')}
      </Link>
    ) : (
      'No alarms present'
    ),
    Owner: ApplianceOwnerLink(appliance, user),
    ...links,
  }

  return (
    <Paper
      title="Metadata"
      collapsible
      actionsPane={[
        ...(user.role === Role.super
          ? [
              <Typography key="config-button" variant="body1" sx={styles.link}>
                <Link underline="hover" to={routes.appliancesConfig({ id: appliance.id })}>
                  Show configuration
                </Link>
              </Typography>,
            ]
          : []),
        ...(linkIsShown
          ? [
              <MuiLink
                href={api.appliance({ id: appliance.id })}
                key="appliance-link"
                sx={styles.link}
                underline="always"
                target="_blank"
              >
                <Typography variant="body1" component="span" sx={styles.text}>
                  Manage appliance
                </Typography>
                <OpenInNew />
              </MuiLink>,
            ]
          : []),
      ]}
    >
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
      {linkIsShown && <Divider style={{ width: '100%' }} />}
    </Paper>
  )
}

export default Meta
