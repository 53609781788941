import {
  Appliance,
  Entity,
  Input,
  ListResult,
  Output,
  OutputAdminStatus,
  OutputFilter,
  OutputInit,
  OutputOperStatus,
  OutputRecipientList,
  OutputStatus,
} from 'common/api/v1/types'
import { getApplianceOwnerId, isOutput } from '../../utils'
import {
  EnrichedOutput,
  EnrichedOutputWithPorts,
  OutputFilterHealthStatus,
  OutputsRequestParams,
  singleSortQueryFromPaginatedRequestParams,
} from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IOutputApi {
  createOutput(output: OutputInit): Promise<Output>
  getOutput(outputId: Output['id'], filter?: OutputFilter): Promise<EnrichedOutputWithPorts>
  getOutputHealth(outputId: Output['id']): Promise<OutputStatus>
  getBareOutputs(filter: OutputFilter, limit?: number): Promise<ListResult<Output>>
  getOutputs(params: OutputsRequestParams): Promise<ListResult<EnrichedOutput>>
  getOutputsWithLists(
    params: OutputsRequestParams,
  ): Promise<ListResult<EnrichedOutput | (OutputRecipientList & { _group?: { name: string } })>>
  getTotalOutputCount(): Promise<number>
  removeOutput(id: string): Promise<Entity>
  removeOutputs(ids: Array<Output['id']>): Promise<{ deleted: Array<Output['id']>; notDeleted: Array<Output['id']> }>
  updateOutput(id: string, output: Output): Promise<Output>
  enableOutputs(ids: Array<Output['id']>): Promise<Output[]>
  disableOutputs(ids: Array<Output['id']>): Promise<Output[]>
  setInputOfOutput(outputId: Output['id'], inputId: Output['input']): Promise<Output>
}

export class OutputApi implements IOutputApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  createOutput(output: OutputInit): Promise<Output> {
    return this.edgeClient.createOutput(output)
  }

  /**
   * Updates output populating response with input and group objects if present
   * @param id - output id
   * @param output - output object
   */
  async updateOutput(id: string, output: Output): Promise<EnrichedOutput> {
    const outputRes = await this.edgeClient.updateOutput(id, output)
    return this.getEnrichedOutput(outputRes)
  }

  removeOutput(id: string): Promise<Entity> {
    return this.edgeClient.deleteOutput(id)
  }

  removeOutputs(ids: Array<Output['id']>): Promise<{ deleted: Array<Output['id']>; notDeleted: Array<Output['id']> }> {
    return this.edgeClient.deleteOutputs({ ids })
  }

  getOutputHealth(id: Output['id']): Promise<OutputStatus> {
    return this.edgeClient.getOutputHealth(id)
  }

  getBareOutputs(filter: OutputFilter, limit?: number): Promise<ListResult<Output>> {
    return this.edgeClient.listOutputs({ filter, limit })
  }

  /**
   * Returns ListResult of outputs populating them with input and group objects
   * @param hasInput - to show only those which are streaming any input
   * @param applianceId - to show only those which ports belong to this appliance
   * @param input - to show only those which are streaming this input
   * @param notInput - to show only those which are not streaming this input
   * @param inputTr101290Window - Desired input tr101290 metrics window size
   * @param searchName - Term to search for
   * @param params - pagination parameters
   */
  async getOutputs({
    hasInput,
    applianceId,
    appliances,
    regions,
    input,
    inputName,
    group,
    notInput,
    inputTr101290Window,
    filter: searchName,
    adminStatus,
    health,
    tags,
    ...params
  }: OutputsRequestParams): Promise<ListResult<EnrichedOutput>> {
    const filter: OutputFilter = {
      notBelongingToInputIds: notInput ? [notInput] : undefined,
      belongingToInputIds: input ? [input] : undefined,
      hasInput: hasInput,
      appliance: applianceId,
      applianceNames: appliances?.split(','),
      inputName,
      groupName: group,
      regionNames: regions?.split(','),
      inputTr101290Window,
      searchName,
      adminStatus:
        adminStatus && [OutputAdminStatus.on.toString(), OutputAdminStatus.off.toString()].includes(adminStatus)
          ? Number(adminStatus)
          : undefined,
      healthStatuses:
        health === OutputFilterHealthStatus.healthy
          ? [OutputOperStatus.allOk]
          : health === OutputFilterHealthStatus.unhealthy
          ? Object.values(OutputOperStatus).filter((s) => s !== OutputOperStatus.allOk)
          : undefined,
      tags: tags?.split(','),
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const { total, items } = await this.edgeClient.listOutputs(query)

    const inputIds = Array.from(new Set(items.filter((output) => output.input).map((output) => output.input!)))
    const inputs = inputIds.length > 0 ? (await this.edgeClient.listInputs({ filter: { ids: inputIds } })).items : []

    const groupIds = Array.from(new Set(items.filter((output) => output.group).map((output) => output.group)))
    const groups = groupIds.length > 0 ? (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items : []

    return {
      items: items.map((output) => {
        let _input, _group
        if (output.input) {
          _input = inputs.find(({ id }) => id === output.input)
        }
        if (output.group) {
          _group = groups.find(({ id }) => id === output.group)
        }
        return { ...output, _input, _group }
      }),
      total,
    }
  }

  /**
   * Returns output populating it with input object if present and its ports with physical port and appliance
   * @param outputId
   * @param filter
   */
  async getOutput(outputId: string, filter?: OutputFilter): Promise<EnrichedOutputWithPorts> {
    const output = await this.edgeClient.getOutput(outputId, { filter })

    const input: Input | undefined = output.input ? await this.edgeClient.getInput(output.input) : undefined

    // TODO: Future improvement: Reduce number of network requests by listPorts({ filter: ids })
    const ports = await Promise.all(
      output.ports.map((port) => this.edgeClient.listPorts({ filter: { id: port.physicalPort } })),
    )

    const applianceIds = Array.from(
      new Set(
        [
          ...(input?.appliances || []).map((a) => a.id),
          ...output.appliances.map((a) => a.id),
          ...ports.map(({ items }) => items[0]?.appliance?.id),
        ].filter((id) => !!id) as string[],
      ),
    )
    const appliances = (await this.edgeClient.listAppliances({ filter: { ids: applianceIds } })).items

    const inputAppliances = appliances.filter((a) => !!input?.appliances?.find((ia) => ia.id === a.id))
    const inputApplianceOwnerIds = inputAppliances.map(getApplianceOwnerId)

    const outputAppliances = appliances.filter((a) => output.appliances.find((oa) => oa.id === a.id))
    const outputApplianceOwnerIds = outputAppliances.map(getApplianceOwnerId)

    const groupIds = Array.from(
      new Set(
        [output.group, ...outputApplianceOwnerIds, input?.owner, ...inputApplianceOwnerIds].filter(
          (o) => !!o,
        ) as string[],
      ),
    )
    const groups = (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items

    const enrichedOutputWithPorts: EnrichedOutputWithPorts = {
      ...output,
      _group: groups.find((g) => g.id === output.group),
      _input: input && {
        ...input,
        _owner: groups.find((g) => g.id === input.owner),
      },
      ports: output.ports.map((p, ind) => {
        const port = ports[ind].items[0]
        const _appliance = appliances.find(({ id }: Appliance) => id === port?.appliance.id)
        return {
          ...p,
          _port: { ...port, _appliance: _appliance as Appliance },
        }
      }),
    }
    return enrichedOutputWithPorts
  }

  /**
   * Returns ListResult of mix of outputLists and outputs populated with input and group object
   * @param notInput
   * @param searchName - term to search for
   * @param params
   */
  async getOutputsWithLists({
    notInput,
    filter: searchName,
    ...params
  }: OutputsRequestParams): Promise<
    ListResult<EnrichedOutput | (OutputRecipientList & { _group?: { name: string } })>
  > {
    const filter: OutputFilter = {
      notBelongingToInputIds: notInput ? [notInput] : undefined,
      searchName,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const { items, total } = await this.edgeClient.listOutputAndRecipientLists(query)

    const { inputIds, groupIds } = items.reduce<{ inputIds: Array<string>; groupIds: Array<string> }>(
      (acc, item) => {
        if (isOutput(item)) {
          if (item.input) acc.inputIds.push(item.input)
        }
        if (item.group) acc.groupIds.push(item.group)
        return acc
      },
      { inputIds: [], groupIds: [] },
    )
    const inputs = (await this.edgeClient.listInputs({ filter: { ids: Array.from(new Set(inputIds)) } })).items
    const groups = (await this.edgeClient.listGroups({ filter: { ids: Array.from(new Set(groupIds)) } })).items

    return {
      items: items.map((item) => {
        if (isOutput(item)) {
          let _input, _group
          if (item.input) {
            _input = inputs.find(({ id }) => id === item.input)
          }
          if (item.group) {
            _group = groups.find(({ id }) => id === item.group)
          }
          return { ...item, _input, _group }
        } else {
          if (item.group) {
            return { ...item, _group: groups.find(({ id }) => id === item.group) }
          }
        }
        return item
      }),
      total,
    }
  }

  /**
   * Returns number of outputs
   */
  async getTotalOutputCount(): Promise<number> {
    return (await this.edgeClient.listOutputs({ skip: 0, limit: 1 })).total
  }

  enableOutputs(ids: Output['id'][]) {
    return this.edgeClient.setOutputAdminStatuses(ids.map((id) => ({ id, adminStatus: OutputAdminStatus.on })))
  }

  disableOutputs(ids: Output['id'][]) {
    return this.edgeClient.setOutputAdminStatuses(ids.map((id) => ({ id, adminStatus: OutputAdminStatus.off })))
  }

  /**
   * Set input for an output populating response with input and group objects if present
   * @param outputId - output id
   * @param inputId - input id
   */
  async setInputOfOutput(outputId: Output['id'], inputId: Output['input']): Promise<EnrichedOutput> {
    const output = await this.edgeClient.setOutputInput(outputId, { input: inputId || null })
    return this.getEnrichedOutput(output)
  }

  private async getEnrichedOutput(output: Output): Promise<EnrichedOutput> {
    const enrichedOutput: EnrichedOutput = { ...output }
    if (output.input !== undefined) {
      const input = await this.edgeClient.getInput(output.input)
      enrichedOutput._input = input
    }

    if (output.group !== '') {
      const group = await this.edgeClient.getGroup(output.group)
      enrichedOutput._group = group
    }

    return enrichedOutput
  }
}
