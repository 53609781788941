import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'
import { AlarmsRequestParams } from '../../api/nm-types'

export enum ListAlarmFilterKey {
  name = 'name',
  applianceId = 'applianceId',
}

export function mapUrlParamToAlarmFilter(key: keyof AlarmsRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'applianceId':
      return {
        key: ListAlarmFilterKey.applianceId,
        operator: 'is',
        value,
      }
    case 'filter':
      return {
        key: ListAlarmFilterKey.name,
        operator: 'matches',
        value,
      }
  }
}

export function mapAlarmFilterToUrlParam(filter: FilterBy): Partial<AlarmsRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListAlarmFilterKey.name:
        return {
          filter: filter.value,
        }
      case ListAlarmFilterKey.applianceId:
        return {
          applianceId: filter.value,
        }
    }
  }
  return {}
}

export function makeListAlarmsFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListAlarmFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
