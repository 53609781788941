import { ApplianceFeatures, CoaxInputPort, CoaxPortMode, Input } from 'common/api/v1/types'
import { Select } from '../../../common/Form'
import { useEffect, useMemo } from 'react'
import { RichOption } from '../../../common/Form/Select'
import { COAX_MODES } from '../../../../utils'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

interface CoaxPortFormProps {
  namePrefix: string
  applianceFeatures: ApplianceFeatures
}

const CoaxPortForm = ({ namePrefix, applianceFeatures }: CoaxPortFormProps) => {
  const { getValues, setValue, watch } = useFormContext<Input & AllowUncontrolled>()
  const logicalPort: CoaxInputPort = getValues(namePrefix)

  const modeKey = `${namePrefix}.mode`
  watch(modeKey)

  const selectedMode = logicalPort.mode as CoaxPortMode | ''
  const modeOptions: RichOption[] = useMemo(() => {
    const coaxModes = applianceFeatures.input?.modes.filter((m) => COAX_MODES.includes(m.mode)) ?? []
    return coaxModes.map((m) => ({ name: m.prettyName ?? m.mode, value: m.mode }))
  }, [JSON.stringify(applianceFeatures.input?.modes)])

  useEffect(() => {
    const defaultMode = modeOptions.length === 1 ? modeOptions[0].value : ''
    const isValidMode = modeOptions.some((m) => m.value === selectedMode)
    if (!isValidMode && selectedMode !== defaultMode) {
      setValue(modeKey, defaultMode)
    }
  }, [modeKey, setValue, modeOptions, selectedMode])

  return (
    <Select
      label="Mode"
      name={modeKey}
      required
      newLine
      options={modeOptions}
      validators={{
        oneOf: { validValues: new Set(modeOptions.filter((o) => !o.disabled).map(({ value }) => value)) },
      }}
    />
  )
}

export default CoaxPortForm
