import { GridItem, Paper } from '../common/Form'
import { Table } from '../common/Table'
import Wrapper from '../common/Wrapper'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, GlobalState, useRoutes } from '../../store'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Link } from '../common/Link'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { listOverlays } from '../../redux/actions/overlaysActions'
import { DeleteButton } from './DeleteButton'
import { equals } from 'common/util'
import Pendable from '../common/Pendable'

const marginBetweenIconAndName = '12px'

export const ProductionPage = () => {
  const { overlays, loading } = useSelector(
    ({ overlaysReducer }: GlobalState) => ({
      overlays: overlaysReducer.overlays,
      loading: overlaysReducer.loading,
    }),
    equals,
  )
  const navigate = useNavigate()
  const routes = useRoutes()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(listOverlays())
  }, [])

  return (
    <Wrapper name="Production tools">
      <Paper title="Overlays">
        <GridItem lg={12} xl={12}>
          <Pendable pending={loading}>
            <Table
              data={overlays}
              emptyMessageComponent="No overlays found"
              noHeader
              config={[
                {
                  getValue: ({ name, id: overlayId }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flex: 1,
                      }}
                    >
                      <Link
                        underline="hover"
                        to={routes.overlaysUpdate({ id: overlayId })}
                        style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                        muiStyle={{
                          display: 'flex',
                          flex: 1,
                          gap: marginBetweenIconAndName,
                        }}
                      >
                        <span style={{ display: 'flex', flex: 1 }}>{name}</span>
                      </Link>
                    </Box>
                  ),
                },
                {
                  getValue: ({ id: overlayId }) => overlayId && <DeleteButton id={overlayId} />,
                },
              ]}
            />
          </Pendable>
        </GridItem>
        <GridItem lg={12} xl={12}>
          <Button variant="contained" onClick={() => navigate(routes.overlaysNew())} color="primary">
            Create overlay
          </Button>
        </GridItem>
      </Paper>
    </Wrapper>
  )
}
