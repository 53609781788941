import { compile, Key, parse } from 'path-to-regexp'
import drop from 'lodash/fp/drop'
import omit from 'lodash/fp/omit'
import { GetUsageReportFilter } from 'common/api/v1/types'
import { UrlParamCache } from '../redux/reducers/urlParamReducer'
import type { UsageRequestParams } from '../api/nm-types'
import { matchPath } from 'react-router-dom'

type UrlParams = object
/**
 * Generates url using path-to-regexp, using params as both path and query parameters
 * @param path
 * @param params
 */
const getUrl = (path: string, params?: UrlParams) => {
  if (!params) return path
  const keys = drop(1, parse(path))
  const pathParams = (keys as Array<Key>).map(({ name }) => name)
  if (Object.entries(params).some(([key, value]) => pathParams.includes(key) && !value)) return ''
  const searchParams = Object.entries(omit(pathParams, params))
  const queryString = searchParams.length
    ? searchParams.reduce(
        (acc, [key, value], ind) =>
          value !== undefined ? `${acc}${ind ? '&' : '?'}${key}=${encodeURIComponent(value as string)}` : acc,
        '',
      )
    : ''
  return `${compile(path)(params)}${queryString}`
}

interface Route {
  (params?: UrlParams): string
  route: string
}

/**
 * Returns Route which is route creator (to use with path-parameters to get the url) with access to the initial path
 * @param path - path with ':<...>' to use as path params
 */
const getRoute = (path: string, getDefaultUrlParamsFn?: () => UrlParams | undefined): Route => {
  const outFunc = (urlParams?: UrlParams) => getUrl(path, urlParams ?? getDefaultUrlParamsFn?.())
  outFunc.route = path
  return outFunc
}

const getCreateRoute = (path: string, query?: string) =>
  getRoute(`${path}/${SUB_PATH.NEW}` + (query ? `\\?${query}` : ''))
const getUpdateRoute = (path: string) => getRoute(`${path}/${SUB_PATH.UPDATE}`)
const getCopyRoute = (path: string) => getRoute(`${path}/${SUB_PATH.COPY}`)

export const SUB_PATH = {
  NEW: 'new',
  UPDATE: 'update/:id',
  COPY: 'copy/:id',
} as const

const root = {
  alarms: '/alarms',
  alarmLogs: '/alarmLogs',
  audit: '/audit',
  appliances: '/settings/appliances',
  billing: '/settings/billing',
  usage: '/settings/usage',
  globalSettings: '/globalSettings',
  production: '/production',
  graph: '/graph',
  inputs: '/inputs',
  interfaces: '/settings/interfaces',
  groups: '/settings/groups',
  groupLists: '/settings/groups/lists',
  outputs: '/outputs',
  outputLists: '/outputs/lists',
  overview: '/overview',
  profile: '/profile',
  regions: '/settings/regions',
  service: '/service/:id',
  setting: '/settings',
  status: '/status',
  tunnels: '/settings/tunnels',
  users: '/settings/users',
  services: '/settings/services',
  nodes: '/settings/kubernetesNodes',
  addresses: '/settings/addresses',
  addressMappings: '/settings/addressMappings',
  networks: '/settings/networks',

  // Stream Manager
  stream: '/stream',
  streamProfile: '/stream/profile',
} as const
export const ROUTES = root

/**
 * In case some api request can be used not just in IApi instance
 */
export const api = {
  billing: (params: { startDate: Date; endDate: Date; format?: string }) => {
    return getRoute('/api/billing-report')({
      startDate: params.startDate.toISOString(),
      endDate: params.endDate.toISOString(),
      format: params.format,
    })
  },
  usage: (params: UsageRequestParams) => {
    const filter: GetUsageReportFilter = {
      startDate: params.startDate,
      endDate: params.endDate,
      format: params.format,
      type: params.type,
    }
    return getRoute('/api/usage-report')() + `?q=${encodeURIComponent(JSON.stringify({ filter }))}`
  },
  appliance: (params: { id: string }) => getRoute('/api/appliance/:id/proxy')({ id: params.id }),
}

/**
 * All the page routes. Never use constants, add new instances here
 * @returns {string} absolute full path
 */
export const makeRoutes = (getCachedUrlParams: () => UrlParamCache) => ({
  overview: getRoute(root.overview),
  graph: getRoute(root.graph),
  service: getRoute(root.service),
  globalSettings: getRoute(root.globalSettings),
  production: getRoute(root.production),
  overlaysNew: getCreateRoute(`${root.production}/overlays`),
  overlaysUpdate: getUpdateRoute(`${root.production}/overlays`),
  overlaysCopy: getCopyRoute(`${root.production}/overlays/`),

  inputs: getRoute(root.inputs, () => getCachedUrlParams()['inputs']),
  inputsNew: getCreateRoute(root.inputs),
  inputsUpdate: getUpdateRoute(root.inputs),
  inputsCopy: getRoute(`${root.inputs}/copy/:id`),
  inputsDerive: getCreateRoute(root.inputs, `deriveFrom=:id`),

  outputs: getRoute(root.outputs, () => getCachedUrlParams()['outputs']),
  outputsNew: getCreateRoute(root.outputs),
  outputsUpdate: getUpdateRoute(root.outputs),

  outputLists: getRoute(root.outputLists, () => getCachedUrlParams()['outputLists']),
  outputListsNew: getCreateRoute(root.outputLists),
  outputListsUpdate: getUpdateRoute(root.outputLists),

  alarms: getRoute(root.alarms, () => getCachedUrlParams()['alarms']),
  alarmLogs: getRoute(root.alarmLogs),

  appliances: getRoute(root.appliances, () => getCachedUrlParams()['appliances']),
  appliancesConfig: getRoute(`${root.appliances}/:id/config`),
  appliancesUpdate: getUpdateRoute(root.appliances),

  groups: getRoute(root.groups, () => getCachedUrlParams()['groups']),
  groupsNew: getCreateRoute(root.groups),
  groupsUpdate: getUpdateRoute(root.groups),

  groupLists: getRoute(root.groupLists, () => getCachedUrlParams()['groupLists']),
  groupListsNew: getCreateRoute(root.groupLists),
  groupListsUpdate: getUpdateRoute(root.groupLists),

  users: getRoute(root.users, () => getCachedUrlParams()['users']),
  usersNew: getCreateRoute(root.users),
  usersUpdate: getUpdateRoute(root.users),
  profile: getRoute(root.profile),

  services: getRoute(root.services, () => getCachedUrlParams()['k8sServices']),
  servicesUpdate: getUpdateRoute(root.services),

  addressMappings: getRoute(root.addressMappings, () => getCachedUrlParams()['addressMappings']),
  addressMappingsNew: getCreateRoute(root.addressMappings),
  addressMappingsUpdate: getRoute(`${root.addressMappings}/update/:region/:privateIp`),

  interfaces: getRoute(root.interfaces, () => getCachedUrlParams()['interfaces']),
  interfacesUpdate: getUpdateRoute(root.interfaces),

  settings: getRoute(root.setting),

  status: getRoute(root.status),

  tunnel: getRoute(`${root.tunnels}/:tunnelId`),

  billing: getRoute(root.billing),

  usage: getRoute(root.usage),

  audit: getRoute(root.audit),

  regions: getRoute(root.regions, () => getCachedUrlParams()['regions']),
  regionsUpdate: getRoute(`${root.regions}/update/:id`),
  kubernetesNodes: getRoute(root.nodes, () => getCachedUrlParams()['k8sNodes']),
  kubernetesNodesUpdate: getRoute(`${root.nodes}/update/:regionId/node/:name`),

  networks: getRoute(root.networks, () => getCachedUrlParams()['networks']),
  networkNew: getCreateRoute(root.networks),
  networkUpdate: getUpdateRoute(root.networks),

  // Stream Manager
  stream: getRoute(root.stream),
  streamProfile: getRoute(root.streamProfile),
})

export function doesPathBelongTo(path: string, parentPath: string): boolean {
  return !!matchPath({ path: parentPath, end: false }, path)
}

export type Routes = ReturnType<typeof makeRoutes>
