import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Circle from '@mui/icons-material/FiberManualRecord'

import type { RegionSortableField } from 'common/api/v1/types'
import { isEditableRegion, usePageParamsFilteredSelector, useRegionsSelector, useUser } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import {
  ListRegionFilterKey,
  makeListRegionsFilter,
  mapRegionFilterToUrlParam,
  mapUrlParamToRegionFilter,
} from './listRegionFilter'
import { useRoutes } from '../../store'
import type { EnrichedRegion } from '../../api/nm-types'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'

const styles = {
  defaultIndicatorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  defaultIndicator: {
    marginLeft: '10px',
  },
}

export const List = () => {
  const { regions, total, loading } = usePageParamsFilteredSelector(useRegionsSelector)
  const user = useUser()
  const routes = useRoutes()
  const tableConfig: TableConfig<EnrichedRegion, RegionSortableField> = [
    {
      title: 'name',
      getValue: ({ name }) => name,
      sorting: { byParameter: 'name' },
    },
    {
      title: 'type',
      getValue: ({ _externalText }) => _externalText,
      sorting: { byParameter: 'external' },
    },
    {
      title: 'default',
      getValue: ({ default_region }) => {
        if (!default_region) {
          return
        }

        return (
          <Box sx={styles.defaultIndicatorWrapper}>
            <Circle sx={styles.defaultIndicator} />
          </Box>
        )
      },
      sorting: { byParameter: 'default_region' },
    },
    {
      getValue: (region) => {
        return (
          <MoveToEntity
            url={routes.regionsUpdate({ id: region.id })}
            disabled={!isEditableRegion(user)}
            entityName="region"
          />
        )
      },
    },
  ]

  const filters = makeListRegionsFilter()
  return (
    <Wrapper name="Regions">
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'regions'}
            mapUrlParamToFilterFn={mapUrlParamToRegionFilter}
            mapFilterToUrlParamFn={mapRegionFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListRegionFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Table<EnrichedRegion>
        emptyMessageComponent={<MissingContent message="No regions available" />}
        config={tableConfig}
        data={regions}
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
