import type { NavigateFunction } from 'react-router-dom'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ListResult, NewUser, Role, User, UserSettings } from 'common/api/v1/types'

import { EnrichedUser, EnrichedUserWithSettings, ExistingUserForUpdate, UsersRequestParams } from '../../api/nm-types'
import { initAppliance } from './applianceActions'
import { AppDispatch, ThunkApi } from '../../store'
import { closeAllSnackbars, enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'
import { clearState, saveState } from '../../localstorage'
import { ApplicationException, ErrorInfo, makeApplicationException } from '../../components/common/ApplicationException'
import { IUserApi } from '../../api/user/api'
import { Routes } from '../../utils/routes'

export const loginUser = createAsyncThunk<
  EnrichedUserWithSettings,
  { username: string; password: string; otp?: string },
  ThunkApi
>('user/login', async ({ username, password, otp }, { dispatch, extra: { api } }) => {
  try {
    const enrichedUser = await api.userApi.loginUser(username, password, otp)
    const userSettings: UserSettings = await api.userApi.getUserSettings(enrichedUser.id).catch((error) => {
      dispatch(enqueueErrorSnackbar({ error: makeApplicationException(error) }))
      // We don't want to prevent the user from logging in if getUserSettings() for some reason fails.
      // Should not happen but better safe than sorry...?
      return { alarm: { notificationsEnabled: true } }
    })
    const user: EnrichedUserWithSettings = { ...enrichedUser, settings: userSettings }
    const queryParams = new URLSearchParams(window.location.search)
    const redirect = queryParams.get('rd')
    if (redirect) {
      window.open(redirect)
    }
    // Store logged in user in local storage to support user refreshing the page without having to log in again
    saveState({ userReducer: { user } })
    setTimeout(() => {
      dispatch(initAppliance())
    }, 0) // Run after this thunk has finished to let reducers run and set user
    dispatch(setAuthorizationError(undefined))
    return user
  } catch (err: any) {
    const { errorInfo } = err as { errorInfo: ErrorInfo }
    errorInfo?.errorCode === '401'
      ? dispatch(setAuthorizationError(errorInfo))
      : dispatch(enqueueErrorSnackbar({ error: new ApplicationException(errorInfo) }))
    throw errorInfo
  }
})

export const setAuthorizationError = createAction<ErrorInfo | undefined>('user/setAuthorizationError')

export const createUser = createAsyncThunk<void, NewUser, ThunkApi>(
  'user/createUser',
  async (user, { dispatch, extra: { api, navigate, routes } }) => {
    try {
      await api.userApi.createUser(user)
      navigate()(routes.users())
      dispatch(enqueueSuccessSnackbar(`Added new User: ${user.username}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'create user' }))
      throw err
    }
  },
)

export const updateUser = createAsyncThunk<void, ExistingUserForUpdate, ThunkApi>(
  'user/updateUser',
  async (user, { dispatch, extra: { api, navigate, routes } }) => {
    try {
      await api.userApi.updateUser(user)
      navigate()(routes.users())
      dispatch(enqueueSuccessSnackbar(`Edited User: ${user.username}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update user' }))
      throw err
    }
  },
)

export const removeUser = async (
  user: Pick<User, 'id' | 'username'>,
  {
    dispatch,
    userApi,
    navigate,
    routes,
  }: { dispatch: AppDispatch; userApi: IUserApi; navigate: NavigateFunction; routes: Routes },
) => {
  try {
    await userApi.removeUser(user.id)
    navigate(routes.users())
    dispatch(enqueueSuccessSnackbar(`Removed User: ${user.username}`))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete user' }))
    throw { error: err, userID: user.id }
  }
}

export const saveUserSettings = createAsyncThunk<void, { userId: User['id']; settings: UserSettings }, ThunkApi>(
  'user/saveUserSettings',
  async (
    payload,
    {
      dispatch,
      extra: {
        api: { userApi },
      },
    },
  ) => {
    try {
      await userApi.setUserSettings(payload.userId, payload.settings)
      await dispatch(fetchCurrentUser())
      dispatch(enqueueSuccessSnackbar(`Updated user settings`))
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'update user settings' }))
      throw error
    }
  },
)

export const logoutAndNavigateToMainPage = createAsyncThunk<void, void, ThunkApi>(
  'user/logoutAndNavigateToMainPage',
  async (_, { dispatch }) => {
    await dispatch(logoutUser()) // logoutUser needs to run in order for reducers to run before
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(closeAllSnackbars())
    await dispatch(navigateToMainOnUserLogout())
  },
)

export const logoutUser = createAsyncThunk<void, void, ThunkApi>('user/logoutUser', async (_, { extra: { api } }) => {
  await api.userApi.logoutUser()
  clearState()
})

export const impersonateUser = createAsyncThunk<EnrichedUserWithSettings, User['id'], ThunkApi>(
  'user/impersonateUser',
  async (userId, { dispatch, extra: { api, navigate, routes } }) => {
    try {
      const user = await api.userApi.impersonateUser(userId)
      dispatch(enqueueSuccessSnackbar(`Logged in as ${user.username}`))
      saveState({ userReducer: { user } })
      navigate()(user.role === Role.basic ? routes.stream() : routes.overview())
      return user
    } catch (err) {
      // api.impersonateUser() performs multiple requests.
      // If the first request (impersonateUser) succeeds the edgeToken request cookie will be updated to the impersonated user.
      // If then one of the subsequent requests fails an error is thrown and the impersonation operation is aborted,
      // but the edgeToken cookie is still the impersonated one and will incorrectly be used in all future requests.
      // Solution: explicitly stop impersonation upon failure.
      dispatch(stopImpersonation({ showSuccessSnackbar: false }))

      dispatch(enqueueErrorSnackbar({ error: err, operation: 'impersonate user' }))
      throw err
    }
  },
)

export const stopImpersonation = createAsyncThunk<EnrichedUserWithSettings, { showSuccessSnackbar: boolean }, ThunkApi>(
  'user/stopImpersonation',
  async (args, { dispatch, extra: { api, navigate, routes } }) => {
    try {
      const user = await api.userApi.stopImpersonation()
      if (args.showSuccessSnackbar) {
        dispatch(enqueueSuccessSnackbar(`Switched back to ${user.username}`))
      }
      saveState({ userReducer: { user } })
      navigate()(user.role === Role.basic ? routes.stream() : routes.overview())
      return user
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'switch back to impersonator' }))
      throw err
    }
  },
)

const navigateToMainOnUserLogout = createAsyncThunk<void, void, ThunkApi>(
  'user/navigateToMainOnUserLogout',
  async (_, { extra: { navigate } }) => {
    navigate()('/')
  },
)

export const getUsers = async (
  params: UsersRequestParams,
  { dispatch, userApi }: { dispatch: AppDispatch; userApi: IUserApi },
): Promise<ListResult<EnrichedUser>> => {
  try {
    return await userApi.getUsers(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch users' }))
    throw err
  }
}

export const fetchCurrentUser = createAsyncThunk<EnrichedUserWithSettings, void, ThunkApi>(
  'user/current-user/fetch',
  async (_, { dispatch, extra: { api } }) => {
    try {
      const user = await api.userApi.getCurrentEnrichedUser()
      saveState({ userReducer: { user } })
      return user
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch current-user' }))
      throw err
    }
  },
)
