import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Cached from '@mui/icons-material/Cached'

import { GraphNodeType } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { clearService, getService, refresh, setEnableAnimations } from '../../../redux/actions/serviceOverviewActions'
import { usePageParams, withDefaultPagination } from '../../../utils'
import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import { Pagination } from '../../common/Table'
import Info from './Info'
import { SelectedGraphItem, ServiceOverviewGraph } from '../Graph/ServiceOverviewGraph'
import { styles } from '../../../Common'
import MuiCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { WindowSizeSelect } from './WindowSize'
import Tooltip from '../../common/Tooltip'
import { UrlParamFilteredSearchBar } from '../../common/Filters/FilterView/FilteredSearchBar'
import {
  ListServiceOverviewFilterKey,
  makeServiceOverviewFilter,
  mapServiceOverviewFilterToUrlParam,
  mapUrlParamToServiceOverviewFilter,
} from '../listServiceOverviewFilter'

const Overview = () => {
  const { id: inputId } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const [parameters] = usePageParams<Record<string, string>>()
  const outputId = parameters.outputId
  const params = withDefaultPagination(parameters)
  const { input, parent, outputs, derivedInputs, loading, graph, appliances, tr101290Window, isAnimationEnabled } =
    useSelector(
      ({ serviceOverviewReducer }: GlobalState) => ({
        input: serviceOverviewReducer.input,
        parent: serviceOverviewReducer.parent,
        outputs: serviceOverviewReducer.outputs,
        derivedInputs: serviceOverviewReducer.derivedInputs,
        loading: serviceOverviewReducer.loading,
        graph: serviceOverviewReducer.graph,
        appliances: serviceOverviewReducer.appliances,
        tr101290Window: serviceOverviewReducer.tr101290Window,
        isAnimationEnabled: serviceOverviewReducer.isAnimationEnabled,
      }),
      shallowEqual,
    )
  const [date, setDate] = useState(new Date())
  const [selected, setSelected] = useState<SelectedGraphItem>(
    outputId ? { id: outputId, type: GraphNodeType.output } : { id: inputId!, type: GraphNodeType.input },
  )
  useEffect(() => {
    if (!loading) {
      setDate(new Date())
    }
  }, [loading])

  useEffect(() => {
    inputId &&
      dispatch(
        getService({
          params: parameters,
          inputId,
          outputId,
        }),
      )
  }, [dispatch, Object.values(params).join(',')])

  useEffect(() => {
    function doRefresh() {
      dispatch(refresh())
    }

    const timerId = setInterval(doRefresh, 20 * 1000)
    return function stopRefresh() {
      clearInterval(timerId)
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearService())
    }
  }, [dispatch, parameters.outputId])

  const handleOnSelect = (selected: SelectedGraphItem | undefined) => {
    if (selected) {
      setSelected(selected)
    } else if (outputId) {
      setSelected({ id: outputId, type: GraphNodeType.output })
    } else {
      setSelected({ id: inputId!, type: GraphNodeType.input })
    }
  }

  const showSearchbar = !parameters.outputId
  const filters = makeServiceOverviewFilter()
  return (
    <Wrapper
      name={['Service overview', input?.name]}
      actions={
        showSearchbar && (
          <div style={{ flexShrink: 1, flexGrow: 1 }}>
            <UrlParamFilteredSearchBar
              urlParamCacheKey={undefined}
              mapUrlParamToFilterFn={mapUrlParamToServiceOverviewFilter}
              mapFilterToUrlParamFn={mapServiceOverviewFilterToUrlParam}
              searchTokenFilters={filters}
              rawTextFilter={filters.find((f) => f.key === ListServiceOverviewFilterKey.outputName)!}
            />
          </div>
        )
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            id="paper-service-overview"
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    component="span"
                    style={{ marginRight: 8, cursor: 'auto' }}
                  >
                    Loaded at {format(date, 'HH:mm:ss')}
                  </Typography>
                  <Tooltip title={'Refresh'}>
                    <IconButton onClick={() => void dispatch(refresh())}>
                      <Cached sx={loading ? styles.rotating : {}} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormGroup style={{ marginRight: 12 }}>
                    <FormControlLabel
                      label="Animate direction"
                      labelPlacement={'end'}
                      componentsProps={{ typography: { color: 'textSecondary', variant: 'body2' } }}
                      control={
                        <MuiCheckbox
                          size={'small'}
                          checked={isAnimationEnabled}
                          onChange={(_event, checked) => dispatch(setEnableAnimations(checked))}
                        ></MuiCheckbox>
                      }
                    />
                  </FormGroup>
                  <WindowSizeSelect currentWindow={tr101290Window} />
                </div>
              </div>
            }
          >
            <Grid item xs={12}>
              {!!input && (
                <>
                  <ServiceOverviewGraph
                    selectedItem={selected}
                    onSelect={handleOnSelect}
                    graph={JSON.parse(JSON.stringify(graph))}
                    input={input}
                    parent={parent}
                    outputs={outputs.items}
                    derivedInputs={derivedInputs}
                    appliances={appliances}
                    outputId={parameters.outputId}
                    isAnimationEnabled={isAnimationEnabled}
                  />
                  <Pagination total={outputs.total} useUrlSearchParams />
                </>
              )}
            </Grid>
          </Paper>
          {!!selected && <Info selected={selected} />}
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default Overview
