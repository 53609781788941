import { InputFilterHealthStatus, InputsRequestParams } from '../../../api/nm-types'
import { IRegionApi } from '../../../api/region/api'
import { IApplianceApi } from '../../../api/appliances/api'
import { IGroupsApi } from '../../../api/groups/api'
import { FilterBy, FilterTemplate } from '../../common/Filters/FilterView/FilterTemplate'
import { ApplianceType, ExperimentalFeatures, InputAdminStatus } from 'common/api/v1/types'
import { ITagApi } from '../../../api/tags/api'
import { distinct } from 'common/utils'

export enum ListInputFilterKey {
  name = 'name',
  healthStatus = 'health status',
  appliance = 'appliance',
  adminStatus = 'admin status',
  applianceRegion = 'appliance region',
  owner = 'owner',
  tag = 'tag',
}

// Constructs a list-input-filter populated with possible values/suggestions
export function makeListInputFilter({
  groupApi,
  regionApi,
  applianceApi,
  tagApi,
  expFeatures,
}: {
  groupApi: IGroupsApi
  regionApi: IRegionApi
  applianceApi: IApplianceApi
  tagApi: ITagApi
  expFeatures?: ExperimentalFeatures
}): FilterTemplate[] {
  const requestPaging = { pageNumber: '0', rowsPerPage: '20' }
  const supportsHealthFilter = expFeatures && expFeatures.ExtHealthAlarms
  const filterTemplates: FilterTemplate[] = [
    ...(supportsHealthFilter
      ? [
          {
            key: ListInputFilterKey.healthStatus,
            operator: 'is',
            possibleValuesSync: (searchText) => {
              return [
                { value: InputFilterHealthStatus.healthy, displayName: 'healthy' },
                { value: InputFilterHealthStatus.unhealthy, displayName: 'unhealthy' },
              ].filter((v) => !searchText || v.displayName.includes(searchText))
            },
          } as FilterTemplate,
        ]
      : []),
    {
      key: ListInputFilterKey.adminStatus,
      operator: 'is',
      possibleValuesSync: (searchText) => {
        return [
          { value: InputAdminStatus.on.toString(), displayName: 'enabled' },
          { value: InputAdminStatus.off.toString(), displayName: 'disabled' },
        ].filter((v) => !searchText || v.displayName.includes(searchText))
      },
    },
    { key: ListInputFilterKey.name, operator: 'matches' },
    {
      key: ListInputFilterKey.owner,
      operator: 'is',
      possibleValues: async (searchText) => {
        const result = await groupApi.getPureGroups({
          ...requestPaging,
          filter: searchText,
        })
        return result.items.map((a) => ({ value: a.name, displayName: a.name }))
      },
    },
    {
      key: ListInputFilterKey.appliance,
      operator: 'isOneOf',
      possibleValues: async (searchText) => {
        const result = await applianceApi.getBareAppliances({
          ...requestPaging,
          filter: searchText,
        })
        return result.items
          .filter((a) => a.type !== ApplianceType.thumb)
          .map((a) => ({ value: a.name, displayName: a.name }))
      },
    },
    {
      key: ListInputFilterKey.applianceRegion,
      operator: 'isOneOf',
      possibleValues: async (searchText) => {
        const result = await regionApi.getRegions({
          ...requestPaging,
          filter: searchText,
        })
        return result.items.map((r) => ({ value: r.name, displayName: r.name }))
      },
    },
    {
      key: ListInputFilterKey.tag,
      operator: 'isOneOf',
      possibleValues: async (searchText) => {
        const { items: tags } = await tagApi.listTags({ filter: { searchName: searchText } })
        // Super user receives tags from all groups - no need to display duplicates
        const uniqueTags = distinct(tags, (a, b) => a.name === b.name)
        return uniqueTags.map(({ name }) => ({ value: name, displayName: name }))
      },
    },
  ]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}

export function mapInputFilterToUrlParam(filter: FilterBy): Partial<InputsRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListInputFilterKey.healthStatus:
        return { health: filter.value }
      case ListInputFilterKey.name:
        return { filter: filter.value }
      case ListInputFilterKey.owner:
        return { owner: filter.value }
      case ListInputFilterKey.adminStatus: {
        return { adminStatus: filter.value }
      }
    }
  } else if ('values' in filter) {
    switch (filter.key) {
      case ListInputFilterKey.appliance:
        return { appliances: filter.values.map(({ value }) => value).join(',') }
      case ListInputFilterKey.applianceRegion:
        return { regions: filter.values.map(({ value }) => value).join(',') }
      case ListInputFilterKey.tag:
        return { tags: filter.values.map(({ value }) => value).join(',') }
    }
  }
  return {}
}

export function mapUrlParamToInputFilter(key: keyof InputsRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'appliances':
      return {
        key: ListInputFilterKey.appliance,
        operator: 'isOneOf',
        values: value.split(',').map((applianceName) => ({ value: applianceName, displayName: applianceName })),
      }
    case 'regions':
      return {
        key: ListInputFilterKey.applianceRegion,
        operator: 'isOneOf',
        values: value.split(',').map((regionName) => ({ value: regionName, displayName: regionName })),
      }
    case 'owner':
      return {
        key: ListInputFilterKey.owner,
        operator: 'is',
        value,
      }
    case 'filter':
      return {
        key: ListInputFilterKey.name,
        operator: 'matches',
        value,
      }
    case 'adminStatus': {
      if (![InputAdminStatus.on.toString(), InputAdminStatus.off.toString()].includes(value)) return undefined
      return {
        key: ListInputFilterKey.adminStatus,
        operator: 'is',
        value,
        displayName: value === InputAdminStatus.on.toString() ? 'enabled' : 'disabled',
      }
    }
    case 'health': {
      if (![InputFilterHealthStatus.healthy.toString(), InputFilterHealthStatus.unhealthy.toString()].includes(value))
        return undefined
      return {
        key: ListInputFilterKey.healthStatus,
        operator: 'is',
        value,
        displayName: value === InputFilterHealthStatus.healthy.toString() ? 'healthy' : 'unhealthy',
      }
    }
    case 'tags': {
      return {
        key: ListInputFilterKey.tag,
        operator: 'isOneOf',
        values: value.split(',').map((tagName) => ({ value: tagName, displayName: tagName })),
      }
    }
  }
  return undefined
}
