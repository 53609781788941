import { PortsRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListInterfaceFilterKey {
  name = 'name',
}

export function mapInterfaceFilterToUrlParam(filter: FilterBy): Partial<PortsRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListInterfaceFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToInterfaceFilter(key: keyof PortsRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListInterfaceFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeListInterfacesFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListInterfaceFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
