import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'

import { Appliance, ListApplianceSortableField } from 'common/api/v1/types'
import {
  getApplianceOwnerId,
  isEditableAppliance,
  isEditableGroup,
  pluralize,
  useApplianceSelector,
  usePageParamsFilteredSelector,
  useUser,
} from '../../utils'
import { useRoutes } from '../../store'
import { getProductName } from 'common/api/v1/helpers'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import { AutoUpdatingApplianceHealthIndicator } from '../common/Indicator'
import { EnrichedApplianceWithOwner } from '../../api/nm-types'
import CommonActions from './CommonActions'
import { ApplianceProxyButton } from '../common/ApplianceProxyButton'
import Button from '@mui/material/Button'
import { AddEdgeConnectDialog } from './AddEdgeConnectDialog'

export const ListView = () => {
  const routes = useRoutes()
  const { appliances, total, loading } = usePageParamsFilteredSelector(useApplianceSelector)
  const user = useUser()
  const navigate = useNavigate()
  const [selected, setSelected] = useState<Array<Appliance['id']>>([])
  const [showAddEdgeConnectDialog, setShowAddEdgeConnectDialog] = useState(false)

  const handleSelect = (id: Appliance['id']) => {
    if (selected.includes(id)) {
      setSelected([...selected.filter((item) => item !== id)])
    } else {
      setSelected([...selected, id])
    }
  }

  const handleSelectAll = () => {
    if (selected.length === appliances.length) {
      setSelected([])
    } else {
      setSelected(appliances.reduce<Array<Appliance['id']>>((acc, { id }) => acc.concat(id), []))
    }
  }

  const tableConfig: TableConfig<EnrichedApplianceWithOwner, ListApplianceSortableField> = [
    {
      title: (
        <Checkbox
          indeterminate={selected.length > 0 && selected.length < appliances.length}
          checked={selected.length === appliances.length}
          onChange={() => {
            handleSelectAll()
          }}
          inputProps={{ 'aria-label': 'select all appliances' }}
          data-test-id={'select-all'}
        />
      ),
      getValue: ({ id }) => (
        <Checkbox
          checked={selected.includes(id)}
          onChange={() => {
            handleSelect(id)
          }}
          onDoubleClick={(e) => e.stopPropagation()}
        />
      ),
      props: {
        padding: 'checkbox',
      },
    },
    {
      title: 'status',
      getValue: ({ id }) => <AutoUpdatingApplianceHealthIndicator applianceId={id} />,
      sorting: { byParameter: ListApplianceSortableField.lastSeen },
    },
    {
      title: 'name',
      getValue: ({ name }) => name,
      sorting: {
        byParameter: ListApplianceSortableField.applianceName,
      },
    },
    {
      title: 'type',
      getValue: ({ type }) => getProductName(type),
      sorting: { byParameter: ListApplianceSortableField.applianceType },
    },
    {
      title: 'regions',
      getValue: ({ region = { name: '' }, secondaryRegion = { name: '' } }) =>
        [region.name, secondaryRegion.name].filter(Boolean).join(', '),
      sorting: { byParameter: ListApplianceSortableField.regionName },
    },
    {
      title: 'owner',
      getValue: (values) => (
        <Link
          to={routes.groupsUpdate({ id: getApplianceOwnerId(values) })}
          underline="hover"
          available={values._owner && isEditableGroup(values._owner.id, user)}
        >
          {(values._owner && values._owner.name) || ''}
        </Link>
      ),
      sorting: { byParameter: ListApplianceSortableField.ownerGroupName },
    },
    {
      title: 'alarms',
      getValue: ({ id, alarms, name }) =>
        alarms.length ? (
          <Link to={routes.alarms({ applianceId: id, applianceName: name })} underline="hover">
            {pluralize(alarms.length, 'alarm')}
          </Link>
        ) : (
          'No alarms'
        ),
      sorting: { byParameter: ListApplianceSortableField.numActiveAlarms },
    },
    {
      getValue: (appliance) => {
        const isEditable = isEditableAppliance(user, getApplianceOwnerId(appliance))
        return (
          <div style={{ display: 'flex' }}>
            <MoveToEntity
              url={routes.appliancesUpdate({ id: appliance.id })}
              entityName="appliance"
              disabled={!isEditable}
              dataTestId={`edit-${appliance.id}`}
            />
            <ApplianceProxyButton user={user} appliance={appliance} />
          </div>
        )
      },
      props: { align: 'right' },
    },
  ]

  return (
    <Wrapper
      name="Appliances"
      actions={
        <Button
          variant="contained"
          color="primary"
          sx={{ marginLeft: 4 }}
          onClick={() => setShowAddEdgeConnectDialog(true)}
        >
          Add Edge Connect
        </Button>
      }
    >
      <CommonActions selected={selected} setSelected={setSelected} appliances={appliances} user={user} />
      <Table<EnrichedApplianceWithOwner>
        id="appliances"
        emptyMessageComponent={<MissingContent message="No appliances available" />}
        config={tableConfig}
        data={appliances}
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
        rowProps={(appliance) => {
          const isEditable = isEditableAppliance(user, getApplianceOwnerId(appliance))
          const isItemSelected = Boolean(appliance.id && selected.includes(appliance.id))
          return {
            onDoubleClick: () => isEditable && navigate(routes.appliancesUpdate({ id: appliance.id })),
            'aria-checked': isItemSelected,
            tabIndex: -1,
            selected: isItemSelected,
          }
        }}
      />
      <AddEdgeConnectDialog
        user={user}
        open={showAddEdgeConnectDialog}
        onClose={() => setShowAddEdgeConnectDialog(false)}
      />
    </Wrapper>
  )
}
