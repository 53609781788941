import { FC, forwardRef, useCallback } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import omit from 'lodash/fp/omit'

import GridItem, { GridItemProps, omitGridProps } from './GridItem'
import { PasswordField } from './PasswordField'
import { validate } from '../../../utils'
import { Controller, useFormContext } from 'react-hook-form'

const disallowNonNumericCharacters = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Disallow all non-numeric characters
  if (isNaN(parseFloat(event.key))) {
    event.preventDefault()
  }
}

const Comp = forwardRef((allProps: any, ref) => {
  const { field, fieldState, ...props } = allProps
  const showError = fieldState.invalid && !allProps.disabled
  const fieldError = fieldState.error ? fieldState.error.message : ''
  const muiProps: TextFieldProps & Pick<TextInputProps, 'showPassword'> = {
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    ...field,
    ...props,
    ref,
  }
  if (props.type === 'number') muiProps.onWheel = (e) => e.target instanceof HTMLElement && e.target.blur()
  return <>{props.type === 'password' ? <PasswordField {...muiProps} /> : <TextField {...muiProps} />}</>
})

type TextInputProps = {
  name?: string
  label?: string
  multiline?: boolean
  noNegative?: boolean
  unsignedInt?: boolean
  validators?: Parameters<typeof validate>[0]
  showPassword?: { show: boolean; id: string }
  variant?: TextFieldProps['variant']
  hiddenLabel?: boolean
} & GridItemProps &
  TextFieldProps

/**
 * Simple text input (string, number, email, password etc.)
 * @param props {
 *   name: react-hook-form's name
 *   label?: label to show
 *   multiline?: if it is string field and we need it to be multiline
 *   noNegative?: for number fields we can forbid setting negative values by keyboard arrows, and also it adds validation rule
 *   unsignedInt?: disallow 'e', '+', '-', '.' for number fields
 *   validators?: validation rules for react-hook-form
 *   required?: adds validation
 *   showPassword?: If true, the password is visible. It's valid only when type=password.
 * }
 * @constructor
 */
const TextInput: FC<TextInputProps> = (props) => {
  const { setValue } = useFormContext()
  const { validators = {}, name, variant = 'outlined', hiddenLabel = false, unsignedInt = true } = props
  const onKeyPress =
    props.onKeyPress ?? (props.type === 'number' && unsignedInt) ? disallowNonNumericCharacters : undefined

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = props.type === 'number' ? event.currentTarget.valueAsNumber : event.currentTarget.value

      if (typeof value === 'number' && isNaN(value)) {
        setValue(name!, '')
      } else {
        setValue(name!, value)
      }
    },
    [name, setValue],
  )

  if (props.noNegative) {
    validators.number = {
      valueAsNumber: true,
      min: 0,
      message: 'Can not be negative',
      ...validators.number,
    }
  }
  if (props.required) {
    validators.presence = true
  }

  return (
    <GridItem {...props}>
      <Controller
        name={name!}
        rules={{
          validate: validate(validators),
        }}
        render={({ field: { ref, ...field }, fieldState }) => {
          return (
            <Comp
              inputRef={ref}
              field={field}
              fieldState={fieldState}
              label={hiddenLabel ? undefined : props.name}
              fullWidth
              variant={variant}
              hiddenLabel={hiddenLabel}
              margin="normal"
              autoComplete="off"
              onKeyPress={onKeyPress}
              onChange={(e: any) => {
                field.onChange(e)
                handleOnChange(e)
              }}
              {...(props.noNegative ? { inputProps: { min: '0' } } : undefined)}
              {...omitGridProps(omit(['noNegative', 'validators'], props))}
              data-test-id={props.name ? `textinput-${props.name}` : undefined}
            />
          )
        }}
      />
    </GridItem>
  )
}

export default TextInput
