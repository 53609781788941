import Wrapper from '../../common/Wrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { Overlay, OverlayComponent } from 'common/api/v1/types'
import { GridItem, Paper, SafeRouting } from '../../common/Form'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate'
import Save from '@mui/icons-material/Save'
import TextIncrease from '@mui/icons-material/TextIncrease'
import { ComponentEditor } from './ComponentEditor'
import { PreviewCanvas } from './PreviewCanvas'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, GlobalState } from '../../../store'
import { listOverlays, updateOverlay } from '../../../redux/actions/overlaysActions'
import { ROUTES } from '../../../utils/routes'
import { useConfirmationDialog } from '../../../utils/hooks'
import { equals } from 'common/util'
import TextField from '@mui/material/TextField'

export const EditOverlay = () => {
  const { id: overlayId } = useParams()
  const [selectedOverlay, setSelectedOverlay] = useState<Overlay | undefined>()
  const [originalOverlay, setOriginalOverlay] = useState<Overlay | undefined>()
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(false)
  const overlays = useSelector(({ overlaysReducer }: GlobalState) => overlaysReducer.overlays, equals)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const resolutions: string[] = ['720x576', '1280x720', '1920x1080', '3840x2160']

  const setConfirm = useConfirmationDialog()

  useEffect(() => {
    if (overlayId) {
      const overlay = overlays.find((overlay) => overlay.id === overlayId)
      setSelectedOverlay(overlay)
      setOriginalOverlay(overlay)
    } else {
      setSelectedOverlay({
        name: '',
        previewResolution: '1280x720',
        components: [],
      })
    }
  }, [overlays])

  useEffect(() => {
    if (overlays.length === 0) dispatch(listOverlays())
  }, [])

  const handleSave = () => {
    if (!selectedOverlay) return
    if (!selectedOverlay.name || selectedOverlay.name.length === 0) {
      setError(true)
      return
    }
    setSaving(true)

    if (overlayId) {
      setConfirm(() => {
        dispatch(updateOverlay(selectedOverlay))
        navigate(ROUTES.production)
      }, 'Are you sure you want to save this overlay? This will cause all streams using the overlay to glitch for a moment.')
    } else {
      dispatch(updateOverlay(selectedOverlay))
      navigate(ROUTES.production)
    }
  }

  const updateComponent = (index: number, component?: OverlayComponent) => {
    if (!selectedOverlay) return

    const updatedComponents = selectedOverlay.components.slice()

    if (!component) {
      updatedComponents.splice(index, 1)
    } else {
      updatedComponents[index] = component
    }

    setSelectedOverlay({
      ...selectedOverlay,
      components: updatedComponents,
    })
  }

  const addImage = () => {
    if (!selectedOverlay) return

    const emptyImage: OverlayComponent = {
      type: 'image',
      data: '',
      x: {
        value: 0,
        baseline: 'start',
      },
      y: {
        value: 0,
        baseline: 'start',
      },
      maxWidth: 100,
      maxHeight: 100,
      opacity: 1,
    }

    const firstTextIndex = selectedOverlay.components.findIndex((component) => component.type === 'text')

    if (firstTextIndex === -1) {
      setSelectedOverlay({
        ...selectedOverlay,
        components: [...selectedOverlay.components, emptyImage],
      })
      return
    }

    const updatedComponents = selectedOverlay.components.slice()
    updatedComponents.splice(firstTextIndex, 0, emptyImage)

    setSelectedOverlay({
      ...selectedOverlay,
      components: updatedComponents,
    })
  }

  const addText = () => {
    if (!selectedOverlay) return

    const newText: OverlayComponent = {
      type: 'text',
      text: '',
      x: {
        value: 0,
        baseline: 'start',
      },
      y: {
        value: 0,
        baseline: 'start',
      },
      color: '#000000',
      fontSize: 32,
    }

    setSelectedOverlay({
      ...selectedOverlay,
      components: [...selectedOverlay.components, newText],
    })
  }

  if (!selectedOverlay) return null

  return (
    <Wrapper name={['Production tools', 'Overlays', overlayId && selectedOverlay ? selectedOverlay.name : 'New']}>
      <SafeRouting enabled={!equals(originalOverlay, selectedOverlay) && !saving} />

      <Paper>
        <GridItem lg={6} xl={6}>
          <Stack direction="column" spacing={2} sx={{ minWidth: '100%' }}>
            {selectedOverlay && (
              <PreviewCanvas overlay={selectedOverlay} resolution={selectedOverlay.previewResolution} />
            )}
            <Typography variant="body2" color="textSecondary">
              Preview may not reflect the final output perfectly. Test on a stream to evaluate the result.
            </Typography>
          </Stack>
        </GridItem>
        <GridItem lg={6} xl={6}>
          <Stack direction="column" spacing={2} sx={{ minWidth: '100%' }}>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                id="name"
                label="Name"
                required
                helperText={error && (!selectedOverlay.name || selectedOverlay.name.length === 0) && 'Name is required'}
                error={error && (!selectedOverlay.name || selectedOverlay.name.length === 0)}
                value={selectedOverlay.name}
                onChange={(event) =>
                  setSelectedOverlay({
                    ...selectedOverlay,
                    name: event.target.value,
                  })
                }
              />

              <FormControl fullWidth>
                <InputLabel id="resolution-label">Simulated resolution</InputLabel>
                <Select
                  labelId="resolution-label"
                  id="resolution"
                  value={selectedOverlay.previewResolution}
                  label="Simulated resolution"
                  onChange={(event: SelectChangeEvent) => {
                    setSelectedOverlay({
                      ...selectedOverlay,
                      previewResolution: event.target.value as '1280x720' | '1920x1080' | '3840x2160',
                    })
                  }}
                >
                  {resolutions.map((resolution) => (
                    <MenuItem key={'overlay-resolution-select-' + resolution} value={resolution}>
                      {resolution.replace('x', ' x ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
              <Typography variant="h4">Components</Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" startIcon={<AddPhotoAlternate />} onClick={addImage}>
                Add Image
              </Button>
              <Button variant="outlined" color="primary" startIcon={<TextIncrease />} onClick={addText}>
                Add Text
              </Button>
              <Button variant="contained" color="primary" startIcon={<Save />} onClick={handleSave}>
                Save
              </Button>
            </Stack>
            {selectedOverlay?.components.length === 0 && (
              <Stack direction="column" spacing={1} alignItems="center" sx={{ py: 10 }}>
                <Typography variant="h4">No components found</Typography>
                <Typography variant="body2">Try adding some above!</Typography>
              </Stack>
            )}
            <div>
              {selectedOverlay?.components.map((component, index) => (
                <ComponentEditor key={index} index={index} component={component} updateComponent={updateComponent} />
              ))}
            </div>
          </Stack>
        </GridItem>
      </Paper>
    </Wrapper>
  )
}
