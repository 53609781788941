import { UsersRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListUserFilterKey {
  name = 'name',
}

export function mapUserFilterToUrlParam(filter: FilterBy): Partial<UsersRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListUserFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToUserFilter(key: keyof UsersRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListUserFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeListUsersFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListUserFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
