import { combineReducers } from 'redux'

import alarmLogsReducer from './alarmLogsReducers'
import alarmsReducer from './alarmsReducers'
import appliancesReducer from './appliancesReducer'
import auditReducer from './auditReducers'
import billingReducer from './billingReducers'
import usageReducer from './usageReducers'
import inputsReducer from './inputsReducer'
import graphReducer from './graphReducer'
import groupListsReducer from './groupListsReducer'
import notificationsReducer from './notificationsReducer'
import groupsReducer from './groupsReducer'
import outputsReducer from './outputsReducer'
import outputListsReducer from './outputListsReducer'
import portsReducer from './portsReducer'
import regionsReducer from './regionsReducer'
import serviceOverviewReducer from './serviceOverviewReducers'
import settingsReducer from './settingsReducer'
import uiReducer from './uiReducer'
import urlParamReducer from './urlParamReducer'
import userReducer from './userReducer'
import buildInfoReducer from './buildInfoReducer'
import thumbnailReducer from './thumbnailReducer'
import addressMappingReducer from './addressMappingReducer'
import k8sReducer from './k8sReducer'
import networksReducer from './networksReducer'
import overlaysReducer from './overlaysReducer'

const reducerMap = {
  addressMappingReducer,
  alarmLogsReducer,
  alarmsReducer,
  appliancesReducer,
  auditReducer,
  billingReducer,
  buildInfoReducer,
  graphReducer,
  groupListsReducer,
  groupsReducer,
  inputsReducer,
  k8sReducer,
  networksReducer,
  notificationsReducer,
  outputsReducer,
  outputListsReducer,
  portsReducer,
  regionsReducer,
  serviceOverviewReducer,
  overlaysReducer,
  settingsReducer,
  thumbnailReducer,
  uiReducer,
  urlParamReducer,
  usageReducer,
  userReducer,
}

export default combineReducers(reducerMap)
