import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-async-hook'
import uniq from 'lodash/uniq'
import Grid from '@mui/material/Grid'

import { createUser, removeUser, updateUser } from '../../redux/actions/userActions'
import { Group, NewUser, Role } from 'common/api/v1/types'
import { Api, AppDispatch, GlobalState, useRoutes } from '../../store'
import { Autocomplete, ButtonsPane, Form, Paper, SafeRouting, Select, TextInput } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { formTransform, useConfirmationDialog, useUser } from '../../utils'
import { EnrichedUser, ExistingUserForUpdate } from '../../api/nm-types'
import { FailedFetchingContent } from '../common/FailedFetchingContent'
import { useCallback } from 'react'
import RHF, { FormProps } from '../common/Form/RHF'

type UserFormValues = Omit<EnrichedUser, 'role'> & {
  role: string
  password: string
}

const { userApi } = Api

const getInitialStateUpdating = (userToEdit: EnrichedUser): UserFormValues => ({
  password: '',
  ...userToEdit,
})

const getInitialStateCreating = (currentUser: EnrichedUser): UserFormValues => ({
  id: '',
  group: currentUser.group,
  role: '',
  username: '',
  password: '',
  _group: { id: '', name: '' },
})

const UserForm = (props: FormProps<UserFormValues>, navigate: NavigateFunction, isUpdating: boolean) => {
  const values = props.getValues()
  const routes = useRoutes()
  const dispatch = useDispatch<AppDispatch>()
  const showConfirm = useConfirmationDialog()
  const { saving } = useSelector(({ userReducer }: GlobalState) => userReducer, shallowEqual)
  const user = useUser()

  let roleOptions: string[] = [Role.basic]
  switch (user.role) {
    case Role.admin:
      roleOptions.push(Role.admin)
      break
    case Role.super:
      roleOptions.push(Role.admin)
      if (user._group.id === values.group) {
        roleOptions.push(Role.super)
      }
      break
  }
  if (isUpdating && values.role) roleOptions = uniq([...roleOptions, values.role])

  const deleteUser = () => {
    showConfirm(
      () => void removeUser(values, { dispatch, navigate, userApi: Api.userApi, routes }),
      `Are you sure you want to delete user ${values.username}?`,
    )
  }

  const groupsApi = useCallback(Api.groupsApi.getPureGroups.bind(Api.groupsApi), [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting formState={props.formState} />
        <Form id="user-form" noValidate>
          <Paper title="User settings">
            <TextInput
              name="username"
              label="Name"
              required
              type="text"
              validators={props.defaultValues.username === 'admin' ? {} : { username: {} }}
              autoFocus
              disabled={isUpdating}
              autoComplete="off"
            />
            <TextInput
              name="password"
              label="Password"
              required={!isUpdating}
              type="password"
              autoComplete="new-password"
              validators={{
                pwd: {},
              }}
            />

            {user.role === Role.super ? (
              <Autocomplete<Group>
                name="group"
                api={groupsApi}
                required={true}
                defaultOption={
                  isUpdating
                    ? {
                        id: values._group.id,
                        name: values._group.name,
                      }
                    : undefined
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                optionComparator={(o1, o2) => o1.id === o2.id}
              />
            ) : (
              <Select
                name="group"
                label="Group"
                required
                options={[{ value: user._group.id, name: user._group.name }]}
                disabled
              />
            )}
            <Select name="role" label="Role" required options={roleOptions} />

            {isUpdating ? <TextInput name="_mfa._totp" label="MFA" type="text" disabled /> : null}
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.users()),
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={
              isUpdating && user.id !== values.id
                ? {
                    'Remove user': { onClick: deleteUser },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = () => {
  const { id } = useParams()
  const user = useUser()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const isUpdating = !!id
  const { result, loading, error } = useAsync(async () => (isUpdating ? userApi.getUser(id!) : undefined), [id])
  const userToEdit: EnrichedUser | undefined = result
    ? {
        ...result,
        _mfa: { _totp: result.mfa?.totp?.enabled ? 'Configured' : 'Not configured' },
      }
    : undefined

  const onSubmit = async (user: ExistingUserForUpdate | NewUser) => {
    if (isUpdating) {
      dispatch(updateUser(user as ExistingUserForUpdate))
    } else {
      dispatch(createUser(user as NewUser))
    }
  }

  return (
    <Wrapper name={['Users', id ? userToEdit?.username : 'New']}>
      <Grid container spacing={0}>
        {error ? (
          <FailedFetchingContent message={'Failed to fetch user'} />
        ) : (
          <Pendable pending={loading}>
            <RHF
              onSubmit={(values) => {
                return onSubmit(formTransform(values))
              }}
              defaultValues={
                isUpdating && userToEdit ? getInitialStateUpdating(userToEdit) : getInitialStateCreating(user)
              }
            >
              {(props) => UserForm(props, navigate, isUpdating)}
            </RHF>
          </Pendable>
        )}
      </Grid>
    </Wrapper>
  )
}
