import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import omit from 'lodash/omit'

import { Output, OutputOrRecipientList, OutputRecipientList, OutputRedundancyMode } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../../store'
import { DraftActions, draftInputs, updateInputRecipients } from '../../../../redux/actions/inputsActions'
import { isOutput, useConfirmationDialog } from '../../../../utils'

import Title from '../../../common/Dialog/Title'

import Recipients from './Recipients'
import AvailableOutputs from './AvailableOutputs'
import Pendable from '../../../common/Pendable'
import { inputRedundancy } from 'common/utils'

const SendingDialog = () => {
  const {
    draft: { inputs, action },
    dialogSaving,
  } = useSelector(
    ({ inputsReducer }: GlobalState) => ({ draft: inputsReducer.draft, dialogSaving: inputsReducer.dialogSaving }),
    shallowEqual,
  )
  const dispatch = useDispatch<AppDispatch>()
  const editedInput = inputs[0]
  const [receiversChanges, setReceiversChanges] = useState<Array<Output['id']>>([])
  const [availableChanges, setAvailableChanges] = useState<{ [key: string]: Output }>({})
  const [listChanges, setListChanges] = useState<Array<OutputRecipientList['id']>>([])
  const [initialOpen, setInitialOpen] = useState(true)
  const setConfirmation = useConfirmationDialog()

  const onClose = () => {
    setReceiversChanges([])
    setAvailableChanges({})
    setListChanges([])
    dispatch(draftInputs({ inputs: [] }))
  }
  const onSave = () => {
    const action = () => {
      dispatch(
        updateInputRecipients({
          id: editedInput.id,
          outputsAdded: Object.keys(availableChanges),
          listsAdded: listChanges,
          outputsRemoved: receiversChanges,
        }),
      )
    }
    const isOutputStreamingOtherInput = Object.values(availableChanges)
      .map((output) => !!output.input)
      .some((val) => val)
    const isRedundantInput = inputRedundancy(editedInput)
    const isOutputRedundancyBeingRemoved = Object.values(availableChanges).some((output) => {
      const isRedundantOutput = output.redundancyMode != OutputRedundancyMode.none
      return isRedundantOutput && !isRedundantInput
    })

    if (isOutputStreamingOtherInput || isOutputRedundancyBeingRemoved) {
      return setConfirmation(
        action,
        <>
          {isOutputStreamingOtherInput && <Typography>- Some outputs are streaming other inputs </Typography>}
          {isOutputRedundancyBeingRemoved && (
            <Typography>- You have selected a non-redundant input - output redundancy will be disabled </Typography>
          )}
          <Typography> Proceed? </Typography>
        </>,
      )
    }
    return action()
  }

  useEffect(() => {
    if (initialOpen) setInitialOpen(false)
    else if (!dialogSaving) onClose()
  }, [dialogSaving])

  if (!editedInput) return null

  const onRemove = (output: Output) => setReceiversChanges([...receiversChanges, output.id])
  const onCancelRecipient = (outputId: Output['id']) =>
    setReceiversChanges(receiversChanges.filter((id) => id !== outputId))
  const onAdd = (item: OutputOrRecipientList) =>
    isOutput(item)
      ? setAvailableChanges({ ...availableChanges, [item.id]: item })
      : setListChanges(listChanges.concat(item.id))
  const onCancelAvailable = (itemId: string, isList = false) =>
    isList
      ? setListChanges(listChanges.filter((id) => id !== itemId))
      : setAvailableChanges(omit(availableChanges, itemId))

  return (
    <Dialog
      key="send-dialog"
      open={!!editedInput && action === DraftActions.send}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      id="send-input-dialog"
    >
      <Title title={`Send "${editedInput.name}" to:`} onClose={onClose} />
      <DialogContent sx={{ paddingBottom: 0 /* Remove bot padding for sticky pagination to look good */ }}>
        <Recipients input={editedInput} onCancel={onCancelRecipient} onRemove={onRemove} changes={receiversChanges} />
        <AvailableOutputs
          input={editedInput}
          changes={availableChanges}
          onSend={onAdd}
          onCancel={onCancelAvailable}
          listChanges={listChanges}
        />
      </DialogContent>
      <DialogActions>
        <Pendable pending={!!dialogSaving}>
          <Button variant="outlined" color="secondary" onClick={onClose} data-test-id="cancel-btn">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSave} data-test-id="save-btn">
            Save
          </Button>
        </Pendable>
      </DialogActions>
    </Dialog>
  )
}

export default SendingDialog
