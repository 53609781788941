import { Controller } from 'react-hook-form'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'

import GridItem, { GridItemProps, omitGridProps } from './GridItem'

const Comp = ({ field, ...props }: { field: any } & MuiCheckboxProps & CheckboxProps) => {
  const muiProps: MuiCheckboxProps = {
    ...field,
    ...props,
  }

  muiProps.checked = (muiProps.value as boolean) || false

  return <FormControlLabel control={<MuiCheckbox {...muiProps} />} label={props.label || props.name} />
}

export interface CheckboxProps extends GridItemProps {
  name?: string
  label?: string
  control?: FormControlLabelProps['control']
  disabled?: boolean
  onClick?: React.MouseEventHandler
}

/**
 * Checkbox for forms using React Hook Form
 * @param props {
 *   name?: if we use it as RHF field - the name for the form
 *   label?: the label to show
 *   control?: if we don't want to have the value in RHF state we have to define our own control to display it
 * }
 * @constructor
 */
const Checkbox = (props: CheckboxProps) => (
  <GridItem {...props}>
    <FormControl margin="normal">
      {props.control ? (
        <FormControlLabel onClick={props.onClick} control={props.control} label={props.label || props.name} />
      ) : (
        <Controller
          name={props.name!}
          render={({ field: { ref, ...field } }) => <Comp inputRef={ref} field={field} {...omitGridProps(props)} />}
        />
      )}
    </FormControl>
  </GridItem>
)

export default Checkbox
