import { ServiceOverviewRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListServiceOverviewFilterKey {
  outputName = 'output name',
}

export function mapServiceOverviewFilterToUrlParam(filter: FilterBy): Partial<ServiceOverviewRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListServiceOverviewFilterKey.outputName:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToServiceOverviewFilter(
  key: keyof ServiceOverviewRequestParams,
  value: string,
): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListServiceOverviewFilterKey.outputName,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeServiceOverviewFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListServiceOverviewFilterKey.outputName, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
