import { createDefaultFiledValues } from '../../../../utils'
import { Select } from '../../../common/Form'
import { RichOption } from '../../../common/Form/Select'
import { useAsync } from 'react-async-hook'
import { Api } from '../../../../store'
import { ComprimatoNdiInput } from 'common/api/v1/types'

import IconButton from '@mui/material/IconButton'
import Cached from '@mui/icons-material/Cached'
import { useEffect, useMemo, useState } from 'react'
import { styles } from '../../../../Common'
import { GridItem } from '../../../common/Form'
import { useFormContext } from 'react-hook-form'

export enum NdiFields {
  name = 'name',
}
export const ndiDefaults = () => createDefaultFiledValues(Object.keys(NdiFields), [])

interface NdiFormProps {
  applianceId: string
  namePrefix: string
}

const { appliancesApi } = Api
const emptyNdiSource: RichOption = { name: 'No sources available', value: undefined, disabled: true }

const NdiForm = ({ applianceId, namePrefix }: NdiFormProps) => {
  const { getValues, setValue, watch } = useFormContext()

  const logicalPort: ComprimatoNdiInput = getValues(namePrefix)

  const modeKey = `${namePrefix}.mode`
  const sourceKey = `${namePrefix}.${NdiFields.name}`

  watch(modeKey)
  watch(sourceKey)

  const selectedMode = logicalPort.mode
  const modeOptions: RichOption[] = useMemo(() => [{ name: 'NDI', value: 'comprimatoNdi' }], [])
  useEffect(() => {
    const defaultMode = modeOptions[0]
    const isValidMode = modeOptions.some((m) => m.value === selectedMode)
    if (!isValidMode && defaultMode) {
      setValue(modeKey, defaultMode.value)
    }
  }, [modeKey, setValue, modeOptions, selectedMode])

  const [refreshSources, setRefreshSources] = useState(false)
  const { result: ndiSourcesListResult, loading: isFetchingNdiSources } = useAsync(
    async () => appliancesApi.listApplianceNdiSources(applianceId),
    [applianceId, refreshSources],
  )

  const sourceOptions: RichOption[] = ndiSourcesListResult?.items.map((it) => ({ name: it.name, value: it.name })) || []
  const hasSelectedSource = (logicalPort.name?.length ?? 0) > 0
  const isSelectedSourceAvailable = sourceOptions.some((s) => s.value === logicalPort.name)
  if (hasSelectedSource && !isSelectedSourceAvailable) {
    sourceOptions.push({ name: `${logicalPort.name} (unavailable)`, value: logicalPort.name, disabled: true })
  } else if (sourceOptions.length === 0) {
    sourceOptions.push(emptyNdiSource)
  }

  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        xs={4}
        lg={4}
        xl={4}
        required
        newLine
        options={modeOptions}
        disabled={selectedMode && modeOptions.length === 1}
        validators={{
          oneOf: { validValues: new Set(modeOptions.filter((o) => !o.disabled).map(({ value }) => value)) },
        }}
      />
      <GridItem xs={8} lg={8} xl={8}>
        <Select disabled={isFetchingNdiSources} label="Source" name={sourceKey} required options={sourceOptions} />
        <IconButton
          disabled={isFetchingNdiSources}
          onClick={() => !isFetchingNdiSources && setRefreshSources((prev) => !prev)}
        >
          <Cached sx={isFetchingNdiSources ? styles.rotating : {}} />
        </IconButton>
      </GridItem>
    </>
  )
}

export default NdiForm
