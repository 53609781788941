import Button from '@mui/material/Button'

import { Network, NetworkSortableField, Role } from 'common/api/v1/types'

import { useNetworksSelector, usePageParamsFilteredSelector, useUser } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import { NetworkIndicator } from '../common/Indicator'
import Toolbar from '@mui/material/Toolbar'
import {
  ListNetworkFilterKey,
  makeListNetworksFilter,
  mapNetworkFilterToUrlParam,
  mapUrlParamToNetworkFilter,
} from './listNetworkFilter'

import { useRoutes } from '../../store'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'

export const NetworksList = () => {
  const { networks, total, loading } = usePageParamsFilteredSelector(useNetworksSelector)
  const user = useUser()
  const routes = useRoutes()

  const tableConfig: TableConfig<Network, NetworkSortableField> = [
    {
      title: 'name',
      getValue: ({ name }) => name,
      sorting: { byParameter: NetworkSortableField.name },
    },
    {
      title: 'enabled',
      getValue: ({ enabled }) => <NetworkIndicator enabled={enabled} />,
    },

    {
      getValue: ({ id }) => <MoveToEntity url={routes.networkUpdate({ id })} entityName="network" />,
      props: { align: 'right' },
    },
  ]
  if (!user || user.role !== Role.super) {
    return null
  }
  const filters = makeListNetworksFilter()
  return (
    <Wrapper
      name="Networks"
      actions={
        user.role === Role.super && (
          <div style={{ marginLeft: 'auto' }}>
            <Link key="addGroup" id="groups-table-create-button" to={routes.networkNew()}>
              <Button variant="contained" color="primary">
                Add network
              </Button>
            </Link>
          </div>
        )
      }
    >
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'networks'}
            mapUrlParamToFilterFn={mapUrlParamToNetworkFilter}
            mapFilterToUrlParamFn={mapNetworkFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListNetworkFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Table<Network>
        emptyMessageComponent={
          <MissingContent message="No networks available" buttonText="Add network" url={routes.networkNew()} />
        }
        config={tableConfig}
        data={networks}
        id="networks-table"
        pending={loading}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
