import { ReactNode } from 'react'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

import { getNode, graphNodeNames, layout } from './graph'
import { Position, RenderGraph } from './types'
import Thumbnail from '../../common/Thumbnail'
import {
  AnyRistServerInputMetric,
  Appliance,
  ApplianceConnectionState,
  GraphNodeType,
  Input,
  InputAdminStatus,
  InputOperStatus,
  InputPort,
  InputStatus,
  IpPortMode,
  LimitedAppliance,
  MetricWindow,
  NonRistServerOutputMetrics,
  Output,
  OutputAdminStatus,
  OutputOperStatus,
  OutputStatus,
  RistInputMultipathState,
  RistMetricType,
  RistServerEgressMetric,
  RtmpMetricType,
  SrtCallerOutputPort,
  SrtInputMetrics,
  SrtMetricType,
  SrtMode,
  SrtOutputMetrics,
  ThumbnailMode,
  Tr101290Metrics,
  UdpOutputMetrics,
  ZixiMetricType,
} from 'common/api/v1/types'
import {
  getMetricTypeForOutput,
  getMinimumAcceptableInputBitrateBps,
  isAnyRistServerInputMetrics,
  isComprimatoPortMode,
  isMatroxPortMode,
  isRistServerEgressMetrics,
  isRistServerPortMode,
} from 'common/api/v1/helpers'
import { IconData, InputSvg, NotInterestedSvg, OndemandVideoOutlinedSvg, ThumbIconSvg, TranscodeSvg } from './icons'
import { getConnectionMetrics } from '../Overview/Info'
import { UdpInputStatusCode } from 'common/rist'
import { extractTr101290Metrics, getActiveChannelId, isApplianceStandby } from '../Overview/utils'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { SrtConnectionStatus } from 'common/srt'
import { channelIdFromChannelGroupAndPriority, ChannelPriority } from 'common/channelId'
import React from 'react'

const CIRCLE_RADIUS = 1.8

const CHANNEL_COLORS = [
  { default: 'rgb(51, 102, 204)', highlight: 'rgb(103, 226, 191)' }, // blue
  { default: 'rgb(166,8,195)', highlight: 'rgb(211,113,227)' }, // purple
  { default: 'rgb(84, 116, 9)', highlight: 'rgb(167,230,17)' }, // green
  { default: 'rgb(187, 109, 0)', highlight: 'rgb(255,151,2)' }, // orange
  { default: 'rgb(0, 155, 255)', highlight: 'rgb(97,187,243)' }, // light blue
]
const defaultStyle = {
  baseColor: '#3366CC',
  highlightColor: 'rgb(103, 226, 191)',
  fillBaseColor: '#202631',
  fillHighlightColor: '#3D4451',
  textColor: '#ccc',
  errorColor: 'hsl(353, 57%, 61%)',
  errorHighlightColor: 'hsl(353, 57%, 75%)',
  warningColor: 'hsl(48, 75.3%, 63.5%)',
  warningHighlightColor: 'hsl(48, 75.3%, 83.5%)',
  disabledColor: 'rgba(255, 255, 255, 0.3)',
  disabledHighlightColor: 'rgba(255, 255, 255, 0.5)',
}

type ObjectStyle = 'base' | 'disabled' | 'error' | 'warning' | 'standby' | 'errorStandby'

function makeParentStyle(property: 'stroke' | 'fill', hoverColor = defaultStyle.highlightColor) {
  return {
    '&:hover .svgElement, & .highlight': {
      [property]: hoverColor,
      '&.error': {
        [property]: defaultStyle.errorHighlightColor,
      },
      '&.errorStandby': {
        [property]: defaultStyle.errorHighlightColor,
      },
      '&.disabled': {
        [property]: defaultStyle.disabledHighlightColor,
      },
      '&.warning': {
        [property]: defaultStyle.warningHighlightColor,
      },
    },
  }
}

function makeColorStyle(property: 'stroke' | 'fill') {
  return {
    [property]: defaultStyle.baseColor,
    '&.error': {
      [property]: defaultStyle.errorColor,
    },
    '&.warning': {
      [property]: defaultStyle.warningColor,
    },
    '&.disabled': {
      [property]: defaultStyle.disabledColor,
    },
  }
}

const HoverableSvgParent = styled('g')<{ fillType: 'stroke' | 'fill'; hoverColor?: string }>(
  ({ fillType, hoverColor }) => makeParentStyle(fillType, hoverColor),
)

const Circle = styled('circle')({
  fill: defaultStyle.fillBaseColor,
  stroke: defaultStyle.baseColor,
  '&.highlight': {
    fill: defaultStyle.fillHighlightColor,
    stroke: defaultStyle.highlightColor,
    '&.error': {
      stroke: defaultStyle.errorHighlightColor,
    },
    '&.warning': {
      stroke: defaultStyle.warningHighlightColor,
    },
  },
  '&.error': {
    stroke: defaultStyle.errorColor,
  },
  '&.warning': {
    stroke: defaultStyle.warningColor,
  },
  '&.disabled': {
    stroke: defaultStyle.disabledColor,
  },
})

const Icon = styled('path')({
  ...makeColorStyle('fill'),
})

function makeLine({
  animationsEnabled,
  strokeColor,
  highlightColor,
}: {
  animationsEnabled: boolean
  strokeColor: string
  highlightColor: string
}) {
  function lineCommon({ isAnimated, isStandby }: { isAnimated: boolean; isStandby?: boolean }): object {
    const common = {
      strokeWidth: isStandby ? '0.6' : '0.35',
      strokeLinecap: 'round',
      '@keyframes so-streaming-data': {
        '0%': { strokeDashoffset: '100%' },
        '100%': { strokeDashoffset: '0%' },
      },
    }
    const specifics =
      animationsEnabled && isAnimated
        ? {
            animation: `so-streaming-data ${isStandby ? 180 : 90}s linear infinite`,
            strokeDasharray: isStandby ? '0.1 1.6' : '5 0.5',
          }
        : {
            animation: 'none',
            strokeDasharray: isStandby ? '0.1 1.6' : '0',
          }
    return {
      ...common,
      ...specifics,
    }
  }
  return {
    ...lineCommon({ isAnimated: true }),
    stroke: strokeColor,
    '&.highlight': {
      stroke: highlightColor,
    },
    '&.error': {
      ...lineCommon({ isAnimated: false }),
      stroke: defaultStyle.errorColor,
      '&.highlight': {
        stroke: defaultStyle.errorHighlightColor,
      },
    },
    '&.warning': {
      stroke: defaultStyle.warningColor,
      '&.highlight': {
        stroke: defaultStyle.warningHighlightColor,
      },
    },
    '&.standby': {
      ...lineCommon({ isAnimated: true, isStandby: true }),
    },
    '&.errorStandby': {
      ...lineCommon({ isAnimated: false, isStandby: true }),
      stroke: defaultStyle.errorColor,
      '&.highlight': {
        stroke: defaultStyle.errorHighlightColor,
      },
    },
    '&.disabled': {
      ...lineCommon({ isAnimated: false }),
      stroke: defaultStyle.disabledColor,
      '&.highlight': {
        stroke: defaultStyle.disabledHighlightColor,
      },
    },
  }
}

const Line = styled('path')<{ strokeColor: string; highlightColor: string; animationsEnabled: boolean }>(
  ({ animationsEnabled = true, strokeColor = defaultStyle.baseColor, highlightColor = defaultStyle.highlightColor }) =>
    makeLine({ animationsEnabled, strokeColor, highlightColor }),
)

export interface SelectedGraphItem<TData = any> {
  type: GraphNodeType
  id: string
  data?: TData
}

export interface ServiceOverviewGraphProps {
  selectedItem: SelectedGraphItem | undefined
  onSelect: <TData>(selected: SelectedGraphItem<TData> | undefined) => void
  graph: RenderGraph
  outputId?: string
  input: Input
  parent?: Input
  outputs: Output[]
  derivedInputs: Input[]
  appliances: (Appliance | LimitedAppliance)[]
  isAnimationEnabled: boolean
}

type Coords = [number, number]

function coordsFromPosition(p?: Position): Coords {
  if (!p) {
    return [0, 0]
  }
  return [p.x, p.y]
}

export interface ConnectionData {
  fromType: GraphNodeType
  toType: GraphNodeType
  type: GraphNodeType
  logicalPortId?: string
  streamId?: number
  channelId?: number
  fromId?: string
  toId?: string
}

function getRenderObjects(
  graph: RenderGraph,
  input: Input,
  parent: Input | undefined,
  outputs: Output[],
  derivedInputs: Input[],
  appliances: (Appliance | LimitedAppliance)[],
): { lines: Line<ConnectionData>[]; circles: Circle[]; icons: Icon[] } {
  const types = [
    [LineType.curveAntiClockwise, CurveBendSize.small],
    [LineType.curveAntiClockwise, CurveBendSize.big],
  ] as const
  const inputIndex: { [applianceId: string]: number } = {}
  const hasMultipleInputPorts =
    graph.edges.filter((edge) => getNode(graph, edge.source).data.type === GraphNodeType.input).length > 1

  if (parent) {
    const inputNode = Object.values(graph.nodes).find((node) => node.data.type === GraphNodeType.input)!
    inputNode.position.x += 1
  }
  const lines = graph.edges.map((edge): Line<ConnectionData> => {
    const { source, target } = edge
    const sourceNode = getNode(graph, source)
    const targetNode = getNode(graph, target)
    const sourcePosition = coordsFromPosition(sourceNode.position)
    const targetPosition = coordsFromPosition(targetNode.position)
    const fromType = sourceNode.data.type
    const toType = targetNode.data.type
    const type =
      toType === GraphNodeType.derivedInput
        ? GraphNodeType.deriveFromParent
        : fromType === GraphNodeType.parentInput
        ? GraphNodeType.deriveFrom
        : fromType === GraphNodeType.input
        ? GraphNodeType.inputPort
        : toType === GraphNodeType.output
        ? GraphNodeType.outputPort
        : GraphNodeType.connection

    const id =
      type === GraphNodeType.connection
        ? `${sourceNode.data.id}>${targetNode.data.id}`
        : type === GraphNodeType.inputPort
        ? `${sourceNode.data.id}-${edge.logicalPortId}`
        : `${targetNode.data.id}-${edge.logicalPortId}`

    let output: Output | undefined = undefined
    if (type === GraphNodeType.outputPort) {
      output = outputs.find((o) => o.id === targetNode.data.id)
    }

    const { lineClass } =
      type === GraphNodeType.deriveFromParent
        ? getDerivedInputHandoverStatus(derivedInputs.find((d) => d.id === targetNode.data.id)!, input, graph)
        : type === GraphNodeType.deriveFrom
        ? getDerivedInputHandoverStatus(input, parent, graph)
        : type === GraphNodeType.inputPort
        ? getInputPortStatus(input, edge.streamId)
        : type === GraphNodeType.outputPort
        ? getOutputPortStatus(
            output,
            edge.streamId,
            edge.logicalPortId,
            output?.appliances?.find((a) => a.id === sourceNode.data.id),
            input.adminStatus === InputAdminStatus.off,
          )
        : getConnectionStatus(type, input, outputs, sourceNode.data.id, targetNode.data.id)

    if (type === GraphNodeType.inputPort && !inputIndex[targetNode.data.id]) {
      inputIndex[targetNode.data.id] = 0
    }

    let index: number | undefined = undefined
    if (hasMultipleInputPorts && type === GraphNodeType.inputPort) {
      index = inputIndex[targetNode.data.id]
      inputIndex[targetNode.data.id] = index + 1
    }

    let activeChannelId: number | undefined = undefined
    if (sourceNode.data.type === GraphNodeType.input) {
      const logicalPort = input.ports?.find((p) => p.id === edge.logicalPortId)
      activeChannelId = channelIdFromChannelGroupAndPriority(
        input.channelGroup,
        (logicalPort?.priority ?? 0) as ChannelPriority,
      )
    } else {
      const isApplianceNode = (node: string) => node.startsWith('appliance')
      const applianceNode = isApplianceNode(source) ? sourceNode : isApplianceNode(target) ? targetNode : undefined
      if (applianceNode) {
        const { sendMetrics } = getConnectionMetrics(
          input,
          output ? [output] : [],
          sourceNode.data.id,
          targetNode.data.id,
        )
        activeChannelId =
          sendMetrics?.channelId ?? getActiveChannelId(applianceNode.data.id, input, output ? [output] : [])
      }
    }

    const [lineType, curveBendSize] =
      parent || typeof index === 'undefined' ? [LineType.straight, undefined] : types[index % types.length]
    return {
      from: sourcePosition,
      to: targetPosition,
      curveType: lineType,
      curveBendSize: curveBendSize,
      id,
      data: {
        type,
        fromType,
        toType,
        fromId: sourceNode.data.id,
        toId: targetNode.data.id,
        logicalPortId: edge.logicalPortId,
        streamId: edge.streamId,
        channelId: activeChannelId,
      },
      lineClass,
    }
  })
  const nodeNames = graphNodeNames(graph)
  const { circles, icons }: { circles: Circle[]; icons: Icon[] } = nodeNames.reduce(
    ({ circles, icons }, n) => {
      const node = getNode(graph, n)
      const coords = coordsFromPosition(node.position)
      switch (node.data.type) {
        case GraphNodeType.input:
          {
            const { svg, style } = getInputIconStatus(
              input?.health,
              input?.adminStatus === InputAdminStatus.off,
              parent,
            )
            icons.push({
              svg,
              textPositioning: IconTextPositioning.top,
              rect: {
                width: 5.2,
                height: 4.6,
                xOffset: parent ? -2.6 : -3.1,
                yOffset: -2.3,
              },
              xOffset: -12,
              yOffset: -12,
              coords: coords,
              scale: 0.24,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          }
          break
        case GraphNodeType.parentInput:
          {
            const { svg, style } = getInputIconStatus(
              parent?.health,
              parent?.adminStatus === InputAdminStatus.off,
              undefined,
            )
            icons.push({
              svg,
              textPositioning: IconTextPositioning.top,
              rect: {
                width: 5.2,
                height: 4.6,
                xOffset: -3.1,
                yOffset: -2.3,
              },
              xOffset: -12,
              yOffset: -12,
              coords: coords,
              scale: 0.24,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          }
          break

        case GraphNodeType.output:
          {
            const output = outputs.find((o) => o.id === node.data.id)
            const { svg, style } = getOutputIconStatus(
              output?.health,
              output?.adminStatus === OutputAdminStatus.off || input?.adminStatus === InputAdminStatus.off,
            )
            icons.push({
              svg,
              textPositioning: IconTextPositioning.top,
              rect: {
                width: 5.4,
                height: 4.8,
                xOffset: -2.1,
                yOffset: -2.5,
              },
              xOffset: -11,
              yOffset: -11,
              coords: coords,
              scale: 0.24,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          }
          break

        case GraphNodeType.derivedInput:
          {
            const derivedInput = derivedInputs.find((d) => d.id === node.data.id)
            const { svg, style } = getDerivedInputIconStatus(
              derivedInput?.health,
              derivedInput?.adminStatus === InputAdminStatus.off,
            )
            icons.push({
              svg,
              textPositioning: IconTextPositioning.top,
              rect: {
                width: 5.2,
                height: 4.6,
                xOffset: -2.6,
                yOffset: -2.3,
              },
              xOffset: -12,
              yOffset: -12,
              coords: coords,
              scale: 0.24,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          }
          break

        default: {
          const allMetrics = extractTr101290Metrics(input, outputs)

          const { style } = getApplianceIconStatus(
            input?.tr101290Enabled ?? true,
            isApplianceStandby(node.data.id, input, outputs),
            appliances.find((a) => a.id === node.data.id),
            allMetrics.find((v) => v.applianceId === node.data.id),
            input.adminStatus === InputAdminStatus.off,
          )

          if (node.data.type === GraphNodeType.thumbAppliance) {
            icons.push({
              svg: ThumbIconSvg,
              textPositioning: IconTextPositioning.top,
              rect: {
                width: 5.4,
                height: 4.8,
                xOffset: -2.1,
                yOffset: -2.5,
              },
              xOffset: -11,
              yOffset: -11,
              coords: coords,
              scale: 0.22,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          } else {
            circles.push({
              center: coords,
              radius: CIRCLE_RADIUS,
              title: node.name,
              type: node.data.type,
              id: node.data.id,
              style,
            })
          }
        }
      }
      return { circles, icons }
    },
    { circles: [] as Circle[], icons: [] as Icon[] },
  )

  return { lines, circles, icons }
}

export function ServiceOverviewGraph({
  graph,
  selectedItem,
  onSelect,
  outputId,
  input,
  parent,
  outputs,
  derivedInputs,
  appliances,
  isAnimationEnabled,
}: ServiceOverviewGraphProps) {
  const { textColor, fillBaseColor } = defaultStyle
  void outputId
  const graphToRender = layout(graph)
  const { lines, circles, icons } = getRenderObjects(graphToRender, input, parent, outputs, derivedInputs, appliances)
  avoidOverlappingLines(lines.filter((line) => line.curveType === LineType.straight))
  const xColSize = 30
  const yColSize = 10
  let maxX = 0
  let maxY = 0
  for (const {
    from: [x1, y1],
    to: [x2, y2],
  } of lines) {
    if (x1 > maxX) {
      maxX = x1
    }
    if (x2 > maxX) {
      maxX = x2
    }
    if (y1 > maxY) {
      maxY = y1
    }
    if (y2 > maxY) {
      maxY = y2
    }
  }
  maxX++
  maxY++
  const radius = 1

  let nextColorIdx = 0
  const channelColorMap = lines.reduce((map, line) => {
    let channelColorIndex = nextColorIdx
    const channelId = line.data?.channelId
    if (channelId !== undefined) {
      channelColorIndex = map[channelId] ?? nextColorIdx++ % CHANNEL_COLORS.length
      map[channelId] = channelColorIndex
    }
    return map
  }, {} as { [channelId: number]: number })

  const numChannels = Object.keys(channelColorMap).length
  let channelColorMappingSvg: ReactNode | null = null
  if (numChannels > 1) {
    const rowHeight = yColSize * 2
    const totalHeight = rowHeight * numChannels
    const children = Object.entries(channelColorMap).map(([channelId, colorIndex], lineIndex) => {
      const baseYOffset = rowHeight
      const y = baseYOffset + lineIndex * rowHeight
      const lineYMargin = -5
      const channelColors = CHANNEL_COLORS[colorIndex]
      return (
        <g key={channelId} height={rowHeight}>
          <text height={rowHeight} dx={0} y={y} style={{ fontSize: '13px' }} fill={textColor}>
            {`Channel ID: ${channelId}`}
          </text>
          <Line
            height={rowHeight}
            d={`M${150},${y + lineYMargin} h${130}`}
            strokeColor={channelColors.default}
            highlightColor={channelColors.highlight}
            animationsEnabled={isAnimationEnabled}
            fill={'transparent'}
            style={{ pointerEvents: 'none', strokeWidth: 4 }}
            className={cn('svgElement')}
          />
        </g>
      )
    })
    channelColorMappingSvg = (
      <Box width="320px">
        <svg style={{ marginTop: 50 }} width={'100%'} height={totalHeight}>
          {children}
        </svg>
      </Box>
    )
  }

  const svgMarginTop = '40px'

  return (
    <div style={{ overflow: 'auto' }}>
      <div style={{ overflow: 'auto' }} data-test-id="overview-graph">
        <svg
          style={{ overflow: 'visible', minWidth: '800px', maxWidth: '1200px', marginTop: svgMarginTop }}
          viewBox={`-12 -5 ${xColSize * Math.max(maxX, 5)} ${yColSize * maxY}`}
          width="100%"
        >
          {lines
            .map((line) => {
              const { from, to, id, data, lineClass, curveType: lineType, curveBendSize: curveSize } = line
              const type = (data && data.type) || GraphNodeType.connection
              const scale = scalePoint(xColSize, yColSize)
              const [x1, y1] = scale(from)
              const [x2, y2] = scale(to)
              const highlighted = selectedItem?.type == type && selectedItem?.id == id
              const pathPos = `M${x1},${y1}`
              const pathString =
                lineType === LineType.straight
                  ? `${pathPos} L${x2},${y2}`
                  : `${pathPos} Q${controlPoint([x1, y1], [x2, y2], lineType, curveSize).join(',')} ${x2},${y2}`

              const channelId = line.data?.channelId
              const channelColorIndex = channelId !== undefined ? channelColorMap[channelId] : 0
              const channelColors = CHANNEL_COLORS[channelColorIndex]
              return (
                <HoverableSvgParent key={id} fillType={'stroke'} hoverColor={channelColors.highlight}>
                  <path
                    fill={'transparent'}
                    d={pathString}
                    strokeWidth={3}
                    stroke={'transparent'}
                    onClick={() => onSelect({ type, id, data })}
                    style={{ cursor: 'pointer' }}
                  />
                  <Line
                    d={pathString}
                    strokeColor={channelColors.default}
                    highlightColor={channelColors.highlight}
                    fill={'transparent'}
                    style={{ pointerEvents: 'none' }}
                    animationsEnabled={isAnimationEnabled}
                    className={cn('svgElement', lineClass, highlighted ? 'highlight' : '')}
                  />
                </HoverableSvgParent>
              )
            })
            .reverse()}
          {circles.map((circle) => {
            const {
              center: [cx, cy],
              id,
              title,
              radius: r,
              style,
            } = circle
            let highlighted = false
            if (selectedItem) {
              const { id: selectedId, type: selectedType } = selectedItem
              highlighted = highlighted || (selectedType == circle.type && selectedId == id)
            }

            return (
              <HoverableSvgParent key={id} fillType="stroke">
                <Circle
                  cx={cx * xColSize}
                  cy={cy * yColSize}
                  r={r}
                  strokeWidth={0.4}
                  onClick={() => onSelect({ type: circle.type, id })}
                  className={cn('svgElement', style, highlighted ? ' highlight' : '')}
                  style={{ cursor: 'pointer' }}
                >
                  <title>{title}</title>
                </Circle>
                <text
                  style={{ fontSize: '2.5px', cursor: 'pointer' }}
                  fill={textColor}
                  textAnchor="middle"
                  x={cx * xColSize}
                  y={cy * yColSize - radius * 2.8}
                  onClick={() => {
                    onSelect({ type: circle.type, id })
                  }}
                >
                  {maxLength(title, 22)}
                </text>
              </HoverableSvgParent>
            )
          })}

          {icons.map((icon) => {
            const {
              coords: [x, y],
              id,
              textPositioning,
              title,
              scale,
              rect,
              xOffset,
              yOffset,
              svg,
              style,
            } = icon
            const name = id
            let highlighted = false
            if (selectedItem) {
              const { id: selectedId, type: selectedType } = selectedItem
              highlighted = highlighted || (selectedType == icon.type && selectedId == id)
            }
            const {
              x: textPosX,
              y: textPosY,
              textAnchor,
            } = getIconTextPos({ pos: { x, y }, scale: { x: xColSize, y: yColSize }, textPositioning })
            return (
              <HoverableSvgParent key={name} fillType={svg.fillType}>
                <rect
                  width={rect.width}
                  height={rect.height}
                  fill={fillBaseColor}
                  x={x * xColSize + rect.xOffset}
                  y={y * yColSize + rect.yOffset}
                  style={{ cursor: 'pointer' }}
                  onClick={() => onSelect({ type: icon.type, id })}
                />
                <Icon
                  transform={`translate(${x * xColSize} ${
                    y * yColSize
                  }) scale(${scale}) translate(${xOffset} ${yOffset})`}
                  d={svg.path}
                  onClick={() => onSelect({ type: icon.type, id })}
                  className={cn('svgElement', style, highlighted ? ' highlight' : '')}
                  style={{ cursor: 'pointer' }}
                >
                  <title>{title}</title>
                </Icon>
                <text
                  style={{ fontSize: '2.5px', cursor: 'pointer' }}
                  fill={textColor}
                  textAnchor={textAnchor}
                  x={textPosX}
                  y={textPosY}
                  onClick={() => {
                    onSelect({ type: icon.type, id })
                  }}
                >
                  {maxLength(title, 18)}
                </text>
              </HoverableSvgParent>
            )
          })}
        </svg>
      </div>
      <ThumbnailSection
        additionalTopMargin={outputs.length + (derivedInputs?.length ?? 0) <= 1}
        input={input}
        parent={parent}
        channelColorMappingSvg={channelColorMappingSvg}
      />
    </div>
  )
}

function ThumbnailSection({
  input,
  parent,
  channelColorMappingSvg,
  additionalTopMargin,
}: {
  input: Input
  parent?: Input
  channelColorMappingSvg: ReactNode
  additionalTopMargin: boolean
}) {
  return (
    <Grid
      id="thumbnails-section"
      style={{ display: 'flex', justifyContent: 'flex-start', ...(additionalTopMargin ? { marginTop: '40px' } : {}) }}
    >
      {(parent && <InputThumbnails key={parent.id} input={parent} channelColorMappingSvg={channelColorMappingSvg} />) ||
        null}
      <InputThumbnails key={input.id} input={input} channelColorMappingSvg={channelColorMappingSvg} />
    </Grid>
  )
}

function InputThumbnails({ input, channelColorMappingSvg }: { input: Input; channelColorMappingSvg: ReactNode }) {
  return (
    <Grid item container xs style={{ flexGrow: 0 }}>
      {channelColorMappingSvg}
      {input.adminStatus === InputAdminStatus.on && input.thumbnailMode === ThumbnailMode.core ? (
        <Box key={input.id} width="320px" marginLeft="8px" position="relative">
          <Thumbnail input={input} />
          <ThumbnailLabel>{input.name}</ThumbnailLabel>
        </Box>
      ) : (
        input.channelIds?.map((channelId, index) => (
          <Box
            key={channelId.toString()}
            width="320px"
            marginLeft="8px"
            marginTop={index > 0 ? '8px' : 0}
            position="relative"
          >
            <Thumbnail input={input} channelId={channelId} ageOverlayThreshold={15_000} />
            <ThumbnailLabel>
              {parent && <div>{input.name}</div>}
              {input.channelIds?.length > 1 && <div>Channel ID: {channelId}</div>}
            </ThumbnailLabel>
          </Box>
        ))
      )}
    </Grid>
  )
}

function ThumbnailLabel(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bgcolor="rgba(0, 0, 0, 0.5)" // Slightly grayed-out background
      color="white" // Text color
      padding="4px"
      textAlign="center"
    >
      {props.children}
    </Box>
  )
}

function getIconTextPos({
  pos,
  scale,
  textPositioning,
}: {
  pos: { x: number; y: number }
  scale: { x: number; y: number }
  textPositioning: IconTextPositioning
}) {
  const xColSize = scale.x
  const yColSize = scale.y

  switch (textPositioning) {
    case IconTextPositioning.top: {
      const x = pos.x * xColSize
      const y = pos.y * yColSize - 2.8
      const textAnchor = 'middle'
      return { x, y, textAnchor }
    }
    case IconTextPositioning.right: {
      const x = pos.x * xColSize + 2.8
      const y = pos.y * yColSize + 0.75
      const textAnchor = 'start'
      return { x, y, textAnchor }
    }
  }
}

export interface Line<TData = never> {
  from: Coords
  to: Coords
  // title: string,
  curveType: LineType
  curveBendSize?: CurveBendSize
  id: string
  data?: TData
  lineClass: ObjectStyle
}

export enum LineType {
  straight = 'straight',
  curveClockwise = 'curveClockwise',
  curveAntiClockwise = 'curveAntiClockwise',
}

export enum CurveBendSize {
  small = 'small',
  big = 'big',
}

export interface Circle<TData = never> {
  center: Coords
  radius: number
  title: string
  id: string
  type: GraphNodeType
  data?: TData
  style: ObjectStyle
}

enum IconTextPositioning {
  top = 'top',
  right = 'right',
}
export interface Icon<TData = never> {
  coords: Coords
  svg: IconData
  rect: {
    width: number
    height: number
    xOffset: number
    yOffset: number
  }
  textPositioning: IconTextPositioning
  xOffset: number
  yOffset: number
  scale: number
  title: string
  id: string
  type: GraphNodeType
  data?: TData

  style: ObjectStyle
}

function maxLength(v: string, length: number) {
  if (v.length <= length) {
    return v
  }
  return v.substring(0, length) + '...'
}

const getConnectionStatus = (
  type: GraphNodeType,
  input: Input,
  outputs: Output[],
  from: string,
  to: string,
): {
  lineClass: ObjectStyle
} => {
  if (type !== GraphNodeType.connection) {
    return { lineClass: 'base' }
  }

  if (input.adminStatus === InputAdminStatus.off) {
    return { lineClass: 'disabled' }
  }

  const { sendMetrics, receiveMetrics } = getConnectionMetrics(input, outputs, from, to)
  const minimumAcceptableBitrate = getMinimumAcceptableInputBitrateBps((input.tsInfo || [])[0])

  const metricsMissing = !sendMetrics || !receiveMetrics
  if (metricsMissing) {
    return { lineClass: 'error' }
  }

  const standby = receiveMetrics?.multipathState === RistInputMultipathState.standby
  if (standby) {
    // Low bitrate is expected if it's in standby
    return { lineClass: 'standby' }
  }

  const bitrateTooLow =
    sendMetrics.sendBitrate < minimumAcceptableBitrate || receiveMetrics.receiveBitrate < minimumAcceptableBitrate
  if (bitrateTooLow) {
    return { lineClass: 'error' }
  }

  return { lineClass: 'base' }
}

const getDerivedInputHandoverStatus = (
  input: Input,
  parentInput: Input | undefined,
  graph: RenderGraph,
): {
  lineClass: ObjectStyle
} => {
  if (!parentInput) {
    // This should never happen (this function should only be called when there is a parent input)
    return { lineClass: 'error' }
  }
  const nodeIds = Object.keys(graph.nodes) as string[]
  let derivedInputNodeId: string | undefined = undefined
  for (const id of nodeIds) {
    if (graph.nodes[id].data.id === input.id) {
      derivedInputNodeId = id
      break
    }
  }
  const parentToDerivedEdge = graph.edges.find((edge) => edge.target === derivedInputNodeId)
  const derivedInputSinkStreamId = parentToDerivedEdge?.streamId
  if (!derivedInputSinkStreamId) {
    return { lineClass: 'error' }
  }
  const handoverMetric = parentInput.metrics?.ristMetrics.find(
    (m) =>
      m.streamId === derivedInputSinkStreamId && m.window === MetricWindow.s10 && m.type === RistMetricType.udpOutput,
  ) as UdpOutputMetrics
  const minimumAcceptableBitrate = getMinimumAcceptableInputBitrateBps((parentInput.tsInfo || [])[0])
  if (!handoverMetric || handoverMetric.sendBitrate < minimumAcceptableBitrate) {
    return { lineClass: 'error' }
  }
  return { lineClass: 'base' }
}

const getInputPortStatus = (
  input: Input,
  streamId?: number,
): {
  lineClass: ObjectStyle
} => {
  if (input.adminStatus === InputAdminStatus.off) {
    return { lineClass: 'disabled' }
  }

  const inputPort = input.ports?.find((port) => port.internalStreamId === streamId)
  const streamMetrics = input.metrics?.ristMetrics.find((m) => m.streamId === streamId)
  const applianceId = streamMetrics?.applianceId
  const portsOnThisAppliance = input.ports?.filter((p) => p.appliance === applianceId) || []
  const isBondedSrt =
    portsOnThisAppliance.length > 1 &&
    portsOnThisAppliance.every((p) => p.mode === IpPortMode.srt) &&
    new Set(portsOnThisAppliance.map((p) => p.priority)).size === 1
  const receiveMetrics = input.metrics?.ristMetrics.find(
    (metric) => (metric.streamId === streamId || isBondedSrt) && isAnyRistServerInputMetrics(metric),
  ) as AnyRistServerInputMetric
  const minimumAcceptableBitrate = getMinimumAcceptableInputBitrateBps((input.tsInfo || [])[0])
  let notOk =
    !receiveMetrics ||
    (receiveMetrics?.type !== RistMetricType.udpInput && receiveMetrics?.receiveBitrate < minimumAcceptableBitrate) ||
    // Source failover RTP inputs are actually UDP inputs with packetFormat: RTP which thus gets handled below here
    (receiveMetrics?.type === RistMetricType.udpInput &&
      receiveMetrics?.receiveBitrate === 0 &&
      (receiveMetrics?.packetsWhileInactive === 0 ||
        (receiveMetrics?.status === UdpInputStatusCode.active && receiveMetrics?.packetsWhileInactive > 0)))

  if (!notOk && isBondedSrt) {
    const srtInputMetrics = input.metrics?.ristMetrics.find(
      (m) => m.type === SrtMetricType.srtInput && m.streamId === streamId,
    ) as SrtInputMetrics
    notOk = srtInputMetrics?.connectionStatus !== SrtConnectionStatus.connected
  }
  const standbyChecks: { [m in InputPort['mode']]?: () => boolean } = {
    [IpPortMode.srt]: () => {
      if (!isBondedSrt) {
        return false
      }
      const srtInputMetrics = input.metrics?.ristMetrics.find(
        (m) => m.type === SrtMetricType.srtInput && m.streamId === streamId,
      ) as SrtInputMetrics
      return (
        srtInputMetrics &&
        srtInputMetrics.connectionStatus === SrtConnectionStatus.connected &&
        srtInputMetrics.bitrate === 0
      )
    },
  }
  const defaultStandbyCheck = () =>
    receiveMetrics?.type === RistMetricType.udpInput && receiveMetrics?.status === UdpInputStatusCode.standby
  const standbyCheck = (inputPort?.mode && standbyChecks[inputPort?.mode]) || defaultStandbyCheck

  if (notOk) {
    if (standbyCheck()) {
      return { lineClass: 'errorStandby' }
    } else {
      return { lineClass: 'error' }
    }
  } else {
    if (standbyCheck()) {
      return { lineClass: 'standby' }
    } else {
      return { lineClass: 'base' }
    }
  }
}

const getOutputPortStatus = (
  output?: Output,
  streamId?: number,
  logicalPortId?: string,
  portAppliance?: LimitedAppliance,
  inputDisabled?: boolean,
): {
  lineClass: ObjectStyle
} => {
  if (!output) {
    return { lineClass: 'error' }
  }

  if (inputDisabled) {
    return { lineClass: 'disabled' }
  }

  const outputPort = output.ports.find((port) => port.id === logicalPortId)
  const isBondedSrtOutput = output.ports.every((port) => port.mode === IpPortMode.srt)
  const firstPort = output.ports[0]
  const isBondedSrtCallerOutput =
    isBondedSrtOutput && firstPort.mode === IpPortMode.srt && firstPort.srtMode === SrtMode.caller
  const isMainBackupSrtBondedOutput =
    isBondedSrtCallerOutput && new Set(output.ports.map((p) => (p as SrtCallerOutputPort).failoverPriority)).size > 1
  const isBondedSrtListener = isBondedSrtOutput && !isBondedSrtCallerOutput
  // If an input with a channel group (i.e. multi-appliance input) is assigned to an output
  // then, in order for the ristserver to be able to perform failover between the different channels,
  // one ristserver-output is created for each channel in the channel group (in the appliance config, not in the db) .
  // These outputs all have the same stream id, but only one of them is active at any given time (i.e. the one with a sendBitrate).
  const activeRistserverSendMetric = (output.metrics?.ristMetrics ?? [])
    // For bonded srt outputs, there will be a single ristserver output for all the bonded srt ports
    .filter(
      (m): m is RistServerEgressMetric =>
        (m.streamId === streamId || isBondedSrtOutput) && isRistServerEgressMetrics(m),
    )
    .reduce(
      (prev, current) => ((prev?.sendBitrate ?? 0) > (current?.sendBitrate ?? 0) ? prev : current),
      undefined as undefined | RistServerEgressMetric,
    )

  // Use minimum acceptable input bitrate as the required output bitrate
  const minimumAcceptableBitrate = getMinimumAcceptableInputBitrateBps((output.tsInfo || [])[0])

  // Check the ristserver send metrics (i.e. the outgoing udp/rtp/rist bitrates)
  if (!activeRistserverSendMetric || activeRistserverSendMetric?.sendBitrate < minimumAcceptableBitrate) {
    return { lineClass: 'error' }
  }

  const outputApplianceType = portAppliance?.type
  const isVa = outputApplianceType && isVaApplianceType(outputApplianceType)
  const ristserverOutputPortMode = isRistServerPortMode(outputPort?.mode)
  const isMatroxOutput = outputPort && isMatroxPortMode(outputPort.mode)
  const isComprimatoOutput = outputPort && isComprimatoPortMode(outputPort.mode)
  if (ristserverOutputPortMode || isVa || isMatroxOutput || isComprimatoOutput) {
    return { lineClass: 'base' }
  }

  // Check the corresponding non-native output metrics (i.e. RTMP/Zixi/SRT etc)
  const nonRistserverSendMetric = output.metrics?.ristMetrics.find(
    (metric) =>
      outputPort &&
      outputApplianceType &&
      metric.type === getMetricTypeForOutput(outputPort.mode, outputApplianceType) &&
      metric.streamId === streamId,
  ) as NonRistServerOutputMetrics | undefined

  let nonRistserverOutputBitrate: number | undefined
  if (nonRistserverSendMetric) {
    switch (nonRistserverSendMetric.type) {
      case RtmpMetricType.rtmpOutput:
        nonRistserverOutputBitrate = nonRistserverSendMetric.sendBitrateKbps * 1000
        break
      case ZixiMetricType.zixiOutput:
      case SrtMetricType.srtOutput:
        nonRistserverOutputBitrate = nonRistserverSendMetric.bitrate
        if ((isMainBackupSrtBondedOutput || isBondedSrtListener) && nonRistserverOutputBitrate === 0) {
          const otherSrtOutputMetric = output.metrics?.ristMetrics.find(
            (m) => m.type === SrtMetricType.srtOutput && m.streamId !== streamId,
          ) as SrtOutputMetrics | undefined
          if (
            otherSrtOutputMetric &&
            otherSrtOutputMetric.bitrate > 0 &&
            (nonRistserverSendMetric as SrtOutputMetrics).connectionStatus === SrtConnectionStatus.connected
          ) {
            return { lineClass: 'standby' }
          } // else this will be handled as an error per below
        }
        break
    }
  }

  if (!nonRistserverOutputBitrate || nonRistserverOutputBitrate < minimumAcceptableBitrate) {
    return { lineClass: 'error' }
  }

  return { lineClass: 'base' }
}

const getApplianceIconStatus = (
  isTr101290Enabled: boolean,
  isApplianceStandby: boolean,
  appliance?: Appliance | LimitedAppliance,
  tr101290Metrics?: Tr101290Metrics,
  disabled?: boolean,
): { style: ObjectStyle } => {
  const isMissingTr101290Metrics =
    isTr101290Enabled && !isApplianceStandby && (!tr101290Metrics || !tr101290Metrics.prio1 || !tr101290Metrics.prio2)
  const hasTr101290Errors = isTr101290Enabled && Object.values({ ...tr101290Metrics?.prio1 }).some((value) => value > 0)
  if (disabled) {
    return { style: 'disabled' }
  }
  switch ((appliance as Appliance)?.health?.state) {
    case ApplianceConnectionState.missing:
    case ApplianceConnectionState.neverConnected:
      return { style: 'error' }
    case ApplianceConnectionState.connected:
    // Default case handles where user can't see Appliance stats but can see tr101290
    default:
      if (isMissingTr101290Metrics || hasTr101290Errors) {
        return { style: 'warning' }
      } else {
        return { style: 'base' }
      }
  }
}

const getInputIconStatus = (
  status?: InputStatus,
  disabled?: boolean,
  parentInput?: Input,
): {
  svg: IconData
  style: ObjectStyle
} => {
  if (disabled)
    return {
      svg: NotInterestedSvg,
      style: 'disabled',
    }

  const isDerivedInput = !!parentInput
  const inputIcon = isDerivedInput ? TranscodeSvg : InputSvg
  switch (status?.state) {
    case InputOperStatus.allOk:
      return {
        svg: inputIcon,
        style: 'base',
      }
    case InputOperStatus.inputError:
      return {
        svg: inputIcon,
        style: 'error',
      }
    case InputOperStatus.notConfigured:
      return {
        svg: inputIcon,
        style: 'base',
      }
    case InputOperStatus.tr101290Priority1Error:
      return {
        svg: inputIcon,
        style: 'warning',
      }
    case InputOperStatus.transportError:
      return {
        svg: inputIcon,
        style: 'error',
      }
    case InputOperStatus.reducedRedundancy:
      return {
        svg: inputIcon,
        style: 'base',
      }
    case InputOperStatus.metricsMissing:
    default:
      return {
        svg: inputIcon,
        style: 'warning',
      }
  }
}

const getDerivedInputIconStatus = (
  status?: InputStatus,
  disabled?: boolean,
): {
  svg: IconData
  style: ObjectStyle
} => {
  if (disabled)
    return {
      svg: NotInterestedSvg,
      style: 'disabled',
    }

  const derivedInputIcon = TranscodeSvg
  switch (status?.state) {
    case InputOperStatus.allOk:
      return {
        svg: derivedInputIcon,
        style: 'base',
      }
    case InputOperStatus.inputError:
      return {
        svg: derivedInputIcon,
        style: 'error',
      }
    case InputOperStatus.notConfigured:
      return {
        svg: derivedInputIcon,
        style: 'base',
      }
    case InputOperStatus.tr101290Priority1Error:
      return {
        svg: derivedInputIcon,
        style: 'warning',
      }
    case InputOperStatus.transportError:
      return {
        svg: derivedInputIcon,
        style: 'error',
      }
    case InputOperStatus.reducedRedundancy:
      return {
        svg: derivedInputIcon,
        style: 'base',
      }
    case InputOperStatus.metricsMissing:
    default:
      return {
        svg: derivedInputIcon,
        style: 'warning',
      }
  }
}

const getOutputIconStatus = (
  status?: OutputStatus,
  disabled?: boolean,
): {
  svg: IconData
  style: ObjectStyle
} => {
  const outputIcon = OndemandVideoOutlinedSvg
  if (disabled)
    return {
      svg: NotInterestedSvg,
      style: 'disabled',
    }
  switch (status?.state) {
    case OutputOperStatus.allOk:
      return {
        svg: outputIcon,
        style: 'base',
      }
    case OutputOperStatus.notAcknowledged:
      return {
        svg: outputIcon,
        style: 'base',
      }
    case OutputOperStatus.inputError:
      return {
        svg: outputIcon,
        style: 'error',
      }
    case OutputOperStatus.notConfigured:
      return {
        svg: outputIcon,
        style: 'base',
      }
    case OutputOperStatus.outputError:
      return {
        svg: outputIcon,
        style: 'error',
      }
    case OutputOperStatus.reducedRedundancy:
      return {
        svg: outputIcon,
        style: 'base',
      }
    case OutputOperStatus.tr101290Priority1Error:
      return {
        svg: outputIcon,
        style: 'warning',
      }
    case OutputOperStatus.metricsMissing: //fallthrough
    case OutputOperStatus.alarm: //fallthrough
    default:
      return {
        svg: outputIcon,
        style: 'warning',
      }
  }
}

interface LineWithSlope<T> {
  line: Line<T>
  slope: number
}

function avoidOverlappingLines<T>(lines: Line<T>[]) {
  const linesAndSlope = lines.map((l) => {
    const lineWithSlope: LineWithSlope<T> = {
      line: l,
      slope: slope(l),
    }
    return lineWithSlope
  })
  linesAndSlope.sort((l1, l2) => {
    if (l1.slope < l2.slope) {
      return -1
    }
    if (l1.slope > l2.slope) {
      return 1
    }
    if (l1.line.from[0] < l2.line.from[0]) {
      return -1
    }
    if (l1.line.from[0] > l2.line.from[0]) {
      return 1
    }
    if (l1.line.to[0] > l2.line.to[0]) {
      return -1
    }
    if (l1.line.to[0] < l2.line.to[0]) {
      return 1
    }
    return 0
  })

  let lastItem: (typeof linesAndSlope)[number] | undefined
  let overlapReference: typeof lastItem
  for (const item of linesAndSlope) {
    if (lastItem && overlaps(overlapReference || lastItem, item)) {
      if (!overlapReference) {
        overlapReference = lastItem
      }
      overlapReference.line.curveType = LineType.curveAntiClockwise
    } else {
      overlapReference = undefined
    }
    lastItem = item
  }

  const ylength = ({ from, to }: Line<T>) => Math.abs(to[1] - from[1])
  const infinitySlope = linesAndSlope.filter(
    (l) => (l.slope === -Infinity || l.slope === Infinity) && ylength(l.line) > 1,
  )
  for (const { line } of infinitySlope) {
    if (line.curveType === LineType.straight) {
      line.curveType = LineType.curveAntiClockwise
    }
  }
}

function overlaps<T>(
  { line: line1, slope: slope1 }: LineWithSlope<T>,
  { line: line2, slope: slope2 }: LineWithSlope<T>,
) {
  if (slope1 != slope2) {
    return false
  }
  const m1 = line1.from[1] - slope1 * line1.from[0]
  const m2 = line2.from[1] - slope1 * line2.from[0]
  const doesOverlap = line1.to[0] > line2.from[0] && m1 == m2
  return doesOverlap
}

function slope<T>(line: Line<T>) {
  const { from, to } = line
  const dx = to[0] - from[0]
  const dy = to[1] - from[1]
  return dy / dx
}

function controlPoint(from: Coords, to: Coords, lineType: LineType, curveSize?: CurveBendSize): Coords {
  const offset = (curveSize === CurveBendSize.big ? 6 : 3) * CIRCLE_RADIUS
  const [x1, y1] = from
  const [x2, y2] = to
  const dx = x2 - x1
  const dy = y2 - y1
  const tanAlpha = dy / dx
  const alpha = Math.atan(tanAlpha)
  const midx = (x2 + x1) / 2
  const midy = (y1 + y2) / 2
  const sinAlpha = Math.sin(alpha)
  const cosAlpha = Math.cos(alpha)
  const tmpOffsetX = -sinAlpha * offset
  const tmpOffsetY = cosAlpha * offset
  const offsetX = lineType === LineType.curveAntiClockwise ? tmpOffsetX : -tmpOffsetX
  const offsetY = lineType === LineType.curveAntiClockwise ? tmpOffsetY : -tmpOffsetY
  const x3 = midx + offsetX
  const y3 = midy + offsetY
  // console.log(`Control point from`, { from, to, x3, y3, offsetX, offsetY })
  return [x3, y3]
}

function scalePoint(xscale: number, yscale: number) {
  return function (c: Coords) {
    return [c[0] * xscale, c[1] * yscale]
  }
}
