import { NetworksRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListNetworkFilterKey {
  name = 'name',
}

export function mapNetworkFilterToUrlParam(filter: FilterBy): Partial<NetworksRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListNetworkFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToNetworkFilter(key: keyof NetworksRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListNetworkFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeListNetworksFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListNetworkFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
