/* eslint no-param-reassign: 'off' */
/* eslint valid-typeof: 'off' */
// https://tools.ietf.org/html/draft-rundgren-json-canonicalization-scheme-06
// JSON canonicalize function. Creates crypto safe predictable canocalization of JSON as defined by draft-rundgren-json-canonicalization-scheme
// Implementation from https://github.com/erdtman/canonicalize adapted to typescript

// Changed serialization to remove undefined properties

// NOTE! Be careful with updating this function as it might cause MAJOR SERVICE DISRUPTIONS if all stream configurations are suddenly considered new
export function canonicalize(object: any): string {
    return serialize(object)

    function serialize(object: any): string {
        if (typeof object === 'bigint') {
            return JSON.stringify(`${object.toString()}n`)
        }
        if (object === null || typeof object !== 'object' || object.toJSON != null) {
            return JSON.stringify(object)
        }
        if (Array.isArray(object) && object.length === 0) {
            return '[]'
        }
        if (Array.isArray(object) && object.length === 1) {
            return '[' + serialize(object[0]) + ']'
        }
        if (Array.isArray(object)) {
            return (
                '[' +
                object.reduce((t, cv, ci) => {
                    t = ci === 1 ? serialize(t) : t
                    return t + ',' + serialize(cv)
                }) +
                ']'
            )
        }
        const keys = Object.entries(object)
            .filter(([_, v]) => typeof v !== 'undefined')
            .map(([k]) => k)
        if (keys.length === 0) {
            return '{}'
        }
        if (keys.length === 1) {
            // eslint-disable-next-line no-constant-binary-expression
            if (typeof object[keys[0]] === undefined) {
                return '{}'
            } else {
                return '{' + serialize(keys[0]) + ':' + serialize(object[keys[0]]) + '}'
            }
        }
        return (
            '{' +
            keys
                .sort()
                .filter((k) => typeof object[k] !== 'undefined')
                .reduce((t, cv, ci) => {
                    t = ci === 1 ? serialize(t) + ':' + serialize(object[t]) : t
                    return t + ',' + serialize(cv) + ':' + serialize(object[cv])
                }) +
            '}'
        )
    }
}
