import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Info from '@mui/icons-material/Info'
import { Theme } from '@mui/material/styles'

import { GlobalState } from '../../../store'
import { BillingDetail, BillingPeriod, EgressBillingDetail } from 'common/api/v1/types'
import Pendable from '../../common/Pendable'
import { dateToYearMonthDayMaxNowString, dateToYearMonthDayString } from 'common/api/v1/helpers'

const styles = {
  container: {
    flex: 1,
  },
  noCreditsConsumed: {
    color: (theme: Theme) => theme.palette.grey[600],
  },
  idData: {
    color: (theme: Theme) => theme.palette.grey[600],
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  maxConcurrencyDate: {
    color: (theme: Theme) => theme.palette.grey[600],
    marginLeft: '5px',
    fontWeight: 'normal',
  },
}

const rowStyles = {
  root: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
}

const Summary = () => {
  const { billing } = useSelector(
    ({ billingReducer }: GlobalState) => ({ billing: billingReducer.billing }),
    shallowEqual,
  )
  if (!billing) {
    return null
  }
  const periods = billing.periods.slice()
  return (
    <Grid item sx={styles.container}>
      <TableContainer component={Paper}>
        <Table id="billingperiods" aria-label="collapsible table" size="medium">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>from</TableCell>
              <TableCell>to</TableCell>
              <TableCell align="right">ingress stream credits</TableCell>
              <TableCell align="right">egress stream credits</TableCell>
              <TableCell align="right">total stream credits consumed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {periods.map((row) => (
              <Row key={row.startDate.toISOString()} row={row} />
            ))}
            <TableRow>
              <TableCell align="right" colSpan={3}>
                <Typography variant={'h4'}>Total</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant={'h4'}>{billing.summary.ingressCredits}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant={'h4'}>{billing.summary.egressCredits}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant={'h4'}>{billing.summary.totalCredits}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

const Row = (props: { row: BillingPeriod }) => {
  const [open, setOpen] = useState(false)

  const { row } = props
  const { ingressCredits, egressCredits } = row
  const creditsConsumed = ingressCredits > 0 || egressCredits > 0
  return (
    <>
      <TableRow hover sx={rowStyles.root} onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" data-test-id={'expand-btn'}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {dateToYearMonthDayString(row.startDate)}
        </TableCell>
        <TableCell component="th" scope="row">
          {dateToYearMonthDayMaxNowString(row.endDate)}
        </TableCell>
        <TableCell align="right">{row.ingressCredits}</TableCell>
        <TableCell align="right">{row.egressCredits}</TableCell>
        <TableCell align="right">{row.totalCredits}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Pendable pending={false}>
              {!creditsConsumed ? (
                <Box margin={1} marginBottom={4} data-test-id={'detailed-report-data'}>
                  <Typography variant="h6" component="div">
                    Detailed billing report for the period is not available
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box margin={1} marginBottom={4} data-test-id={'detailed-report-data'}>
                    <Typography variant="h6" gutterBottom component="div">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          columnGap: '8px',
                        }}
                      >
                        Credit Usage
                        <Box sx={styles.maxConcurrencyDate}>{formatDateTime(row.maxConcurrencyDate)}</Box>
                        <Tooltip
                          title={
                            'Point in time when the max number of concurrent streams were sampled during the selected period'
                          }
                          placement="top"
                        >
                          <Info />
                        </Tooltip>
                      </div>
                    </Typography>
                    {!creditsConsumed ? (
                      <Typography sx={styles.noCreditsConsumed}>No credits consumed during this period</Typography>
                    ) : (
                      <StreamCreditUsageTable data={row.details} />
                    )}
                  </Box>
                </>
              )}
            </Pendable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const StreamCreditUsageTable = (props: { data: BillingDetail[] }) => {
  const { data } = props
  const rows = data.slice() // sort((a, b) => a.streamId.localeCompare(b.streamId))
  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>type</TableCell>
          <TableCell>name</TableCell>
          <TableCell>programs</TableCell>
          <TableCell>id</TableCell>
          {/* <TableCell>Start</TableCell>
          <TableCell>Stop</TableCell> */}
          <TableCell align="right">credits</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => (
          <StreamCreditUsageRow row={row} key={i} />
        ))}
      </TableBody>
    </Table>
  )
}

function isEgressBillingDetail(d: BillingDetail): d is EgressBillingDetail {
  return 'outputId' in d
}

const StreamCreditUsageRow = (props: { row: BillingDetail }) => {
  const { row } = props
  const isEgress = isEgressBillingDetail(row)
  const id = isEgress ? row.outputId : row.inputId
  const redundant = (isEgress && row.outputRedundancy) || (!isEgress && row.inputRedundancy)
  const merge = !isEgress && row.inputMerge
  const protection = (isEgress && row.outputPortCount > 1) || (!isEgress && row.inputPortCount > 1 && !merge)
  const numServices = row.inputServiceCount || 1
  // const mpts = numServices > 1
  return (
    <TableRow hover>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item>
            <Chip label={isEgress ? 'egress' : 'ingress'} size="small" />
          </Grid>
          {redundant && (
            <Grid item>
              <Chip label={'redundant'} size="small" />
            </Grid>
          )}
          {merge && (
            <Grid item>
              <Chip label={'merge'} size="small" />
            </Grid>
          )}
          {protection && (
            <Grid item>
              <Chip label={'protected'} size="small" />
            </Grid>
          )}
        </Grid>
      </TableCell>
      <TableCell scope="row">{isEgress ? row.outputName : row.inputName}</TableCell>
      <TableCell scope="row">{numServices}</TableCell>
      <TableCell title={id} sx={styles.idData}>
        {id}
      </TableCell>

      {/* <TableCell scope="row">{formatDateTime(row.startDate)}</TableCell>
      <TableCell scope="row">{(row.endDate && formatDateTime(row.endDate)) || ''}</TableCell> */}
      <TableCell align="right">{row.credits} </TableCell>
    </TableRow>
  )
}

function formatDateTime(d: Date): string {
  return d.toISOString().substring(0, 16).replace('T', ' ')
}

export default Summary
