import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Delete from '@mui/icons-material/Delete'
import { OverlayComponent, OverlayComponentText } from 'common/api/v1/types'
import { CoordinateControls } from './CoordinateControls'
import { MuiColorInput } from 'mui-color-input'
import { BoxControls } from './BoxControls'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

const validPresets = ['timecode', 'datetime', 'codec', 'encoder']

export const TextEditor = ({
  component,
  index,
  updateComponent,
}: {
  component: OverlayComponentText
  index: number
  updateComponent: (index: number, component?: OverlayComponent) => void
}) => {
  const [areYouSure, setAreYouSure] = useState(false)

  return (
    <Accordion key={index}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ minWidth: '100%', pr: 1 }}
        >
          <Typography variant="h5">Text</Typography>
          <Typography variant="body1">
            {component.preset
              ? `Preset - ${component.preset.charAt(0).toUpperCase() + component.preset.slice(1)}`
              : component.text}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          {!areYouSure && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setAreYouSure(true)
                setTimeout(() => setAreYouSure(false), 5000)
              }}
            >
              <Delete />
            </IconButton>
          )}
          {areYouSure && (
            <Button
              variant="text"
              color="error"
              onClick={(e) => {
                e.stopPropagation()
                setAreYouSure(false)
                updateComponent(index, undefined)
              }}
            >
              Are you sure?
            </Button>
          )}
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <FormControl variant="outlined" fullWidth disabled={component.preset ? true : false}>
              <InputLabel htmlFor="text-label">Text</InputLabel>
              <OutlinedInput
                id="text"
                label="Text"
                value={component.text}
                onChange={(event) => updateComponent(index, { ...component, text: event.target.value })}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="preset-label">Preset</InputLabel>
              <Select
                labelId="preset-label"
                id="preset"
                value={component.preset || ''}
                label="Preset"
                onChange={(event: SelectChangeEvent) => {
                  if (!event.target.value) {
                    updateComponent(index, { ...component, preset: undefined })
                    return
                  }

                  if (validPresets.includes(event.target.value))
                    updateComponent(index, {
                      ...component,
                      preset: event.target.value as OverlayComponentText['preset'],
                      text: '',
                    })
                }}
              >
                <MenuItem value="">None</MenuItem>
                {validPresets.map((preset) => (
                  <MenuItem key={'overlay-text-preset-select-' + preset} value={preset}>
                    {preset.charAt(0).toUpperCase() + preset.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <CoordinateControls component={component} index={index} updateComponent={updateComponent} />
          <Stack direction="row" spacing={2}>
            <MuiColorInput
              format="hex"
              value={component.color}
              onChange={(color) => updateComponent(index, { ...component, color })}
              isAlphaHidden={true}
              fullWidth
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="font-size-label">Font size</InputLabel>
              <OutlinedInput
                id="font-size"
                label="Font size"
                type="number"
                endAdornment={<InputAdornment position="end">px</InputAdornment>}
                value={component.fontSize}
                onChange={(event) =>
                  updateComponent(index, { ...component, fontSize: parseInt(event.target.value) || 0 })
                }
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            </FormControl>
          </Stack>
          <BoxControls component={component} index={index} updateComponent={updateComponent} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
