import { OutputListRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListOutputListFilterKey {
  name = 'name',
}

export function mapOutputListFilterToUrlParam(filter: FilterBy): Partial<OutputListRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListOutputListFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToOutputListFilter(key: keyof OutputListRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListOutputListFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeListOutputListsFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListOutputListFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
