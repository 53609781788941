import {
  Address,
  ApplianceFeatures,
  ApplianceType,
  Input,
  InputAdminStatus,
  IpPortMode,
  OccupiedPort,
  SrtInputPort,
  SrtMode,
} from 'common/api/v1/types'

import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import {
  createDefaultFiledValues,
  isCoreNode,
  makeAddressOptions,
  PortToValidate,
  usePrevious,
} from '../../../../../utils'
import { WhitelistCidrBlockAutoComplete } from '../../../../common/WhitelistCidrBlockAutoComplete'
import { SuggestedLocalPortTextField } from '../../../../common/SuggestedPortTextField'
import { SrtBondingMode, srtBondingOptions } from '../../../../../api/nm-types'
import { useEffect } from 'react'
import Divider from '@mui/material/Divider'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'
import { areMultipleLogicalPortsPerApplianceSupported } from '../../../../common/Interface/Base'

const LATENCY_MAX = 6 * 60 * 1000
const TTL_MAX = 255
const MTU_MIN = 76

export enum SrtFields {
  srtMode = 'srtMode',
  remoteIp = 'remoteIp',
  remotePort = 'remotePort',
  localIp = 'localIp',
  localPort = 'localPort',
  latency = 'latency',
  ipttl = 'ipttl',
  mss = 'mss',
  passphrase = 'passphrase',
  streamId = 'streamId',
  reducedBitrateDetection = 'reducedBitrateDetection',
  reducedBitrateThreshold = 'reducedBitrateThreshold',
  unrecoveredPacketsDetection = 'unrecoveredPacketsDetection',
  unrecoveredPacketsThreshold = 'unrecoveredPacketsThreshold',
  whitelistCidrBlock = 'whitelistCidrBlock',
  bondingMode = 'bondingMode',
}

export const srtDefaults = (numDistinctFailoverPriorities: number) =>
  createDefaultFiledValues(
    Object.keys(SrtFields),
    [SrtFields.reducedBitrateDetection, SrtFields.unrecoveredPacketsDetection],
    {
      [SrtFields.latency]: 120,
      [SrtFields.whitelistCidrBlock]: [],
      [SrtFields.bondingMode]:
        numDistinctFailoverPriorities === 0
          ? SrtBondingMode.none
          : numDistinctFailoverPriorities > 1
          ? SrtBondingMode.activeBackup
          : SrtBondingMode.activeActive,
    },
  )
export const getSrtFieldsToSave = (port: SrtInputPort) => [
  SrtFields.srtMode,
  SrtFields.latency,
  SrtFields.ipttl,
  SrtFields.mss,
  SrtFields.streamId,
  SrtFields.passphrase,
  SrtFields.reducedBitrateDetection,
  SrtFields.unrecoveredPacketsDetection,
  ...(port.reducedBitrateDetection ? [SrtFields.reducedBitrateThreshold] : []),
  ...(port.unrecoveredPacketsDetection ? [SrtFields.unrecoveredPacketsThreshold] : []),
  ...(function () {
    switch (port.srtMode) {
      case SrtMode.listener:
        return [SrtFields.localIp, SrtFields.localPort, SrtFields.whitelistCidrBlock]
      case SrtMode.caller:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localPort]
      default:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localIp, SrtFields.whitelistCidrBlock]
    }
  })(),
]

interface SrtFormProps {
  addresses: Array<Address>
  namePrefix: string
  applianceType: ApplianceType
  applianceFeatures: ApplianceFeatures
  index: number
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  onAddLogicalPortRequested: () => void
}

const SrtForm = ({
  addresses,
  namePrefix,
  applianceType,
  applianceFeatures,
  index,
  occupiedPorts,
  adminStatus,
  onAddLogicalPortRequested,
}: SrtFormProps) => {
  const { getValues, setValue, watch } = useFormContext<Input & AllowUncontrolled>()

  const portsNamePrefix = namePrefix.substr(0, namePrefix.length - 2)
  const ports: SrtInputPort[] = getValues(portsNamePrefix)
  const previousNumberOfPorts = usePrevious(ports.length)

  const firstPort = ports[0] as SrtInputPort & { [SrtFields.bondingMode]: SrtBondingMode | undefined }
  const secondPort: SrtInputPort | undefined = ports[1]
  const currentPort: SrtInputPort | undefined = ports[index]

  // Watch specific fields since they control conditional rendering
  ;[0, 1].forEach((i) => {
    const portNamePrefix = `${portsNamePrefix}.${i}`
    watch(`${portNamePrefix}.srtMode`)
    watch(`${portNamePrefix}.bondingMode`)
    watch(`${portNamePrefix}.reducedBitrateDetection`)
    watch(`${portNamePrefix}.unrecoveredPacketsDetection`)
  })

  const supportedModes = applianceFeatures.input?.modes.find((m) => m.mode === IpPortMode.srt)?.subModes ?? []
  const localAddressSelector = `${namePrefix}.${SrtFields.localIp}`
  const currentPortInfo: PortToValidate = {
    isInput: true,
    isPortDisabled: adminStatus === InputAdminStatus.off,
    mode: currentPort.mode,
    existingLogicalPortsOnSameNic: occupiedPorts,
  }

  const srtModeSupportsBonding = !!firstPort.srtMode && firstPort.srtMode !== SrtMode.rendezvous
  const isBondingSupported =
    srtModeSupportsBonding &&
    areMultipleLogicalPortsPerApplianceSupported({
      isInput: true,
      portMode: IpPortMode.srt,
      appliance: { type: applianceType },
    })
  const isBondingEnabled = Boolean(firstPort.bondingMode) && firstPort.bondingMode !== SrtBondingMode.none

  useEffect(() => {
    if (
      index === 1 &&
      firstPort &&
      secondPort &&
      firstPort.mode === IpPortMode.srt &&
      secondPort.mode === IpPortMode.srt
    ) {
      // Ensure that the second srt mode mirrors the first if bonding is enabled
      if (firstPort.srtMode !== secondPort.srtMode && isBondingEnabled) {
        setValue(`${portsNamePrefix}.1.srtMode`, firstPort.srtMode, { shouldValidate: true })
      }
    }
  }, [index, firstPort?.srtMode, secondPort?.srtMode, isBondingEnabled, setValue, portsNamePrefix])

  useEffect(() => {
    if (index === 0 && isBondingEnabled) {
      if (!isBondingSupported) {
        // Ensure that bonding mode is .None if bonding is not supported
        setValue(`${portsNamePrefix}.0.bondingMode`, SrtBondingMode.none)
      } else if (firstPort.srtMode === SrtMode.listener && firstPort.bondingMode === SrtBondingMode.activeBackup) {
        // Ensure that bonding mode is not active/backup if listener is chosen
        setValue(`${portsNamePrefix}.0.bondingMode`, SrtBondingMode.activeActive)
      }
    }
  }, [index, isBondingEnabled, isBondingSupported, setValue, portsNamePrefix, firstPort])

  useEffect(() => {
    if (isBondingEnabled && ports.length === 1) {
      // Bonding is enabled but only one logical port
      const didDeletePort = previousNumberOfPorts !== undefined ? ports.length < previousNumberOfPorts : false
      if (didDeletePort) {
        // User just deleted the second logical port - disable bonding
        setValue(`${portsNamePrefix}.0.bondingMode`, SrtBondingMode.none)
      } else if (isBondingSupported) {
        // User selected bonding - automatically add an additional logical port (two ports are required for bonding)
        onAddLogicalPortRequested()
      }
    }
  }, [
    isBondingEnabled,
    ports,
    previousNumberOfPorts,
    setValue,
    portsNamePrefix,
    isBondingSupported,
    onAddLogicalPortRequested,
  ])

  return (
    <>
      <Select
        name={`${namePrefix}.${SrtFields.srtMode}`}
        label="Connection mode"
        disabled={index !== 0 && firstPort && isBondingEnabled}
        options={supportedModes}
        required
      />
      {index === 0 && (
        <Select
          name={`${namePrefix}.${SrtFields.bondingMode}`}
          label="Bonding mode"
          options={srtBondingOptions(firstPort.srtMode)}
          required={isBondingSupported}
          disabled={!isBondingSupported}
        />
      )}

      {[SrtMode.caller, SrtMode.rendezvous].includes(currentPort?.srtMode) && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.remoteIp}`}
            label="Remote host"
            newLine
            required
            validators={{
              ipOrHostname: {},
            }}
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.remotePort}`}
            label={currentPort?.srtMode === SrtMode.rendezvous ? 'Local and remote port' : 'Remote port'}
            required
            type="number"
            noNegative
            validators={{
              port: { isUdp: true },
              isPortAvailable: {
                ...currentPortInfo,
                existingLogicalPortsOnSameNic: currentPort.srtMode === SrtMode.rendezvous ? occupiedPorts : [],
              },
            }}
          />
        </>
      )}
      {[SrtMode.listener, SrtMode.rendezvous].includes(currentPort?.srtMode) && (
        <Select
          name={localAddressSelector}
          label="Local address"
          options={makeAddressOptions(getValues(localAddressSelector), addresses)}
          required
          newLine
          validators={{
            addressIn: { addresses },
          }}
        />
      )}
      {[SrtMode.listener, SrtMode.caller].includes(currentPort?.srtMode) && (
        <>
          {currentPort.srtMode === SrtMode.caller && (
            <Divider
              component="div"
              light
              sx={{
                width: '100%',
                borderColor: 'transparent',
              }}
            />
          )}
          <SuggestedLocalPortTextField
            name={`${namePrefix}.${SrtFields.localPort}`}
            label={currentPort.srtMode === SrtMode.listener ? 'Local port' : 'Local outgoing port'}
            required={currentPort.srtMode === SrtMode.listener}
            namePrefix={namePrefix}
            portInfo={currentPortInfo}
          />
        </>
      )}
      <TextInput
        name={`${namePrefix}.${SrtFields.latency}`}
        label="Retransmission buffer (ms)"
        type="number"
        noNegative
        required
        newLine
        tooltip="The constant delay of playout of the stream that allows time for retransmissions to occur"
        validators={{
          number: {
            lessThanOrEqualTo: LATENCY_MAX,
            message: `Must be no more than ${LATENCY_MAX}`,
          },
        }}
      />
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.ipttl}`}
          label="TTL"
          type="number"
          noNegative
          tooltip='Defines IP socket "time to live" option.'
          validators={{
            number: {
              lessThanOrEqualTo: TTL_MAX,
              message: `Must be no more than ${TTL_MAX}`,
            },
          }}
        />
      )}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.mss}`}
          label="MTU"
          type="number"
          noNegative
          newLine
          tooltip="MTU size"
          validators={{
            number: {
              greaterThanOrEqualTo: MTU_MIN,
              message: `Must be more than ${MTU_MIN}`,
            },
          }}
        />
      )}
      <TextInput
        name={`${namePrefix}.${SrtFields.passphrase}`}
        label="Passphrase"
        tooltip="Same passphrase must be configured at SRT output to encrypt the stream"
        validators={{
          noAmpersand: {},
          len: {
            minimum: 10,
            maximum: 79,
          },
        }}
      />

      {currentPort?.srtMode === SrtMode.caller && (
        <TextInput name={`${namePrefix}.${SrtFields.streamId}`} label="Stream Id" newLine tooltip="Stream ID" />
      )}
      {isVaApplianceType(applianceType) && (
        <Checkbox
          name={`${namePrefix}.${SrtFields.reducedBitrateDetection}`}
          label="Reduced bitrate alarm"
          newLine
          tooltip="Raise alarm when bitrate is below or equal."
        />
      )}

      {currentPort.reducedBitrateDetection && (
        <TextInput
          name={`${namePrefix}.${SrtFields.reducedBitrateThreshold}`}
          label="Bitrate (kbps)"
          type="number"
          noNegative
          validators={{
            number: {
              lessThanOrEqualTo: Math.pow(2, 64),
              message: `Must be no more than ${Math.pow(2, 64)}`,
            },
          }}
        />
      )}
      {isVaApplianceType(applianceType) && (
        <Checkbox
          name={`${namePrefix}.${SrtFields.unrecoveredPacketsDetection}`}
          label="Unrecovered packets alarm"
          tooltip="Turn detection of unrecovered packets on or off, including associated alarm. Threshold is in packets per minute. Generates an alarm if unrecovered packets per minute is higher than threshold."
          newLine
        />
      )}
      {currentPort.unrecoveredPacketsDetection && (
        <TextInput
          name={`${namePrefix}.${SrtFields.unrecoveredPacketsThreshold}`}
          label="Threshold (packets/min)"
          type="number"
          noNegative
          validators={{
            number: {
              lessThanOrEqualTo: Math.pow(2, 64),
              message: `Must be no more than ${Math.pow(2, 64)}`,
            },
          }}
        />
      )}
      {!isVaApplianceType(applianceType) && [SrtMode.listener, SrtMode.rendezvous].includes(currentPort?.srtMode) && (
        <WhitelistCidrBlockAutoComplete
          name={`${namePrefix}.${SrtFields.whitelistCidrBlock}`}
          required={isCoreNode(applianceType)}
        />
      )}
    </>
  )
}

export default SrtForm
