import { GroupListRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListGroupListFilterKey {
  name = 'name',
}

export function mapGroupListFilterToUrlParam(filter: FilterBy): Partial<GroupListRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListGroupListFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToGroupListFilter(key: keyof GroupListRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListGroupListFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeListGroupListsFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListGroupListFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
