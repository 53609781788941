import { Checkbox, GridItem, Paper, Select, TextInput } from '../../../common/Form'
import grid from '../../../../img/4Grid.png'
import {
  MatroxAudioChannelPair,
  MatroxEncoderAACEncoder,
  MatroxEncoderAACFormat,
  MatroxEncoderAACQualityLevel,
  MatroxEncoderBitrateControlMode,
  MatroxEncoderConfig,
  MatroxEncoderEncodingMode,
  MatroxEncoderIncludeOption,
  MatroxEncoderPixelFormat,
  MatroxEncoderVideoProfile,
  PhysicalPort,
  PortType,
} from 'common/api/v1/types'
import Grid from '@mui/material/Grid'
import { RichOption } from '../../../common/Form/Select'
import {
  getAllowedBitratesForMatroxAACEncoder,
  getCompatibleMatroxVideoProfilesForPixelFormat,
  makeMatroxEncoderProcessorAudioSourceSettings,
  makeMatroxEncoderProcessorVideoSourceSettings,
  MATROX_ENCODER_IFRAME_INTERVAL_MAX,
  MATROX_ENCODER_IFRAME_INTERVAL_MIN,
  MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER,
  MATROX_ENCODER_MAX_ALLOWED_AUDIOSTREAMS,
  MATROX_ENCODER_MAXBITRATE_MAX,
  MATROX_ENCODER_MAXBITRATE_MIN,
  MATROX_ENCODER_MIN_ALLOWED_AUDIOSTREAMS,
  MATROX_ENCODER_PFRAME_INTERVAL_MAX,
  MATROX_ENCODER_PFRAME_INTERVAL_MIN,
  MATROX_ENCODER_QP_CONSTANT_BITRATE_MAX,
  MATROX_ENCODER_QP_VARIABLE_BITRATE_MAX,
  MATROX_ENCODER_TARGET_BITRATE_MIN,
} from 'common/encoderSettingsUtil'
import MuiCheckbox from '@mui/material/Checkbox'
import { useEffect } from 'react'
import { isVirtualAggregatedPort } from 'common/matrox'
import { useFormContext } from 'react-hook-form'

interface Props {
  namePrefix: string
  physicalPort: PhysicalPort
  matroxSettings: MatroxEncoderConfig
}

const inputSourceOptions: RichOption[] = [0, 1, 2, 3].map((i) => ({ name: `SDI ${i + 1}`, value: i }))

const encoderIncludeOptions: RichOption[] = Object.values(MatroxEncoderIncludeOption)
  .map((option: MatroxEncoderIncludeOption) => {
    switch (option) {
      case MatroxEncoderIncludeOption.none:
        return undefined
      case MatroxEncoderIncludeOption.audioOnly:
        return { name: 'Audio only', value: option }
      case MatroxEncoderIncludeOption.videoOnly:
        return { name: 'Video only', value: option }
      case MatroxEncoderIncludeOption.audioAndVideo:
        return { name: 'Audio and video', value: option }
    }
  })
  .filter(Boolean) as RichOption[]

const encodingProfileOptions: RichOption[] = Object.values(MatroxEncoderVideoProfile).map(
  (option: MatroxEncoderVideoProfile) => {
    switch (option) {
      case MatroxEncoderVideoProfile.Baseline:
        return { name: 'Baseline', value: option }
      case MatroxEncoderVideoProfile.Main:
        return { name: 'Main', value: option }
      case MatroxEncoderVideoProfile.High:
        return { name: 'High', value: option }
      case MatroxEncoderVideoProfile.High10Bit:
        return { name: 'High 10-bit', value: option }
      case MatroxEncoderVideoProfile.High422:
        return { name: 'High YUV 4:2:2', value: option }
    }
  },
)

const encodingModeOptions: RichOption[] = Object.values(MatroxEncoderEncodingMode).map(
  (option: MatroxEncoderEncodingMode) => {
    switch (option) {
      case MatroxEncoderEncodingMode.lowLatency:
        return { name: 'Optimised for low latency', value: option }
      case MatroxEncoderEncodingMode.highQuality:
        return { name: 'Favour image quality', value: option }
    }
  },
)

const bitrateControlOptions: RichOption[] = Object.values(MatroxEncoderBitrateControlMode).map(
  (option: MatroxEncoderBitrateControlMode) => {
    switch (option) {
      case MatroxEncoderBitrateControlMode.constant:
        return { name: 'Constant', value: option }
      case MatroxEncoderBitrateControlMode.variable:
        return { name: 'Variable', value: option }
    }
  },
)

const aacEncoderOptions: RichOption[] = Object.values(MatroxEncoderAACEncoder).map(
  (option: MatroxEncoderAACEncoder) => {
    switch (option) {
      case MatroxEncoderAACEncoder.lc:
        return { name: 'LC', value: option }
      case MatroxEncoderAACEncoder.hev1:
        return { name: 'HEV1', value: option }
      case MatroxEncoderAACEncoder.hev2:
        return { name: 'HEV2', value: option }
    }
  },
)

const aacQualityLevelOptions: RichOption[] = Object.values(MatroxEncoderAACQualityLevel)
  .map((option: MatroxEncoderAACQualityLevel) => {
    switch (option) {
      case MatroxEncoderAACQualityLevel.low:
        return { name: 'Low', value: option }
      case MatroxEncoderAACQualityLevel.medium:
        return { name: 'Medium', value: option }
      case MatroxEncoderAACQualityLevel.high:
        return { name: 'High', value: option }
      case MatroxEncoderAACQualityLevel.unknown:
        return undefined
    }
  })
  .filter(Boolean) as RichOption[]

const aacFormatOptions: RichOption[] = Object.values(MatroxEncoderAACFormat)
  .map((option: MatroxEncoderAACFormat) => {
    switch (option) {
      case MatroxEncoderAACFormat.noContainer:
        return { name: 'No container/Raw', value: option }
      case MatroxEncoderAACFormat.adts:
        return { name: 'ADTS', value: option }
      case MatroxEncoderAACFormat.adif:
        return undefined
      case MatroxEncoderAACFormat.unknown:
        return undefined
    }
  })
  .filter(Boolean) as RichOption[]

function formatAudioPair(audioPair: MatroxAudioChannelPair) {
  switch (audioPair) {
    case MatroxAudioChannelPair.channel1And2:
      return '1-2'
    case MatroxAudioChannelPair.channel3And4:
      return '3-4'
    case MatroxAudioChannelPair.channel5And6:
      return '5-6'
    case MatroxAudioChannelPair.channel7And8:
      return '7-8'
    case MatroxAudioChannelPair.channel9And10:
      return '9-10'
    case MatroxAudioChannelPair.channel11And12:
      return '11-12'
    case MatroxAudioChannelPair.channel13And14:
      return '13-14'
    case MatroxAudioChannelPair.channel15And16:
      return '15-16'
  }
}
const MatroxEncoderSettingsComp = ({
  namePrefix,
  physicalPort,
  matroxSettings: {
    processorSettings,
    encoderSettings: { include, audioSettings, videoSettings },
    tsOutputSettings,
  },
}: Props) => {
  const { setValue, watch } = useFormContext()

  const includeKey = `${namePrefix}.encoderSettings.include`
  const forcePixelFormatKey = `${namePrefix}.encoderSettings.videoSettings.forcePixelFormat`
  const bitrateControlKey = `${namePrefix}.encoderSettings.videoSettings.rateControl.bitrateControl`
  const videoLayoutKey = `${namePrefix}.processorSettings.videoLayout`

  // Watch fields that control the form
  watch(includeKey)
  watch(forcePixelFormatKey)
  watch(bitrateControlKey)
  watch(videoLayoutKey)

  useEffect(() => {
    if (!processorSettings.followVideoSourceInput && tsOutputSettings.isAncEnabled) {
      setValue(`${namePrefix}.tsOutputSettings.isAncEnabled`, false)
    }
  }, [processorSettings.followVideoSourceInput])

  useEffect(() => {
    const quantizationMax =
      videoSettings.rateControl.bitrateControl === MatroxEncoderBitrateControlMode.constant
        ? MATROX_ENCODER_QP_CONSTANT_BITRATE_MAX
        : MATROX_ENCODER_QP_VARIABLE_BITRATE_MAX
    if (videoSettings.rateControl.quantizationMax !== quantizationMax) {
      setValue(`${namePrefix}.encoderSettings.videoSettings.rateControl.quantizationMax`, quantizationMax)
    }
  }, [videoSettings.rateControl.bitrateControl])

  useEffect(() => {
    if (physicalPort.portType !== PortType.coax) {
      return
    }

    const isVirtualQuadPortSelected = isVirtualAggregatedPort(physicalPort)
    if (isVirtualQuadPortSelected) {
      if (processorSettings.videoLayout !== 'quad') {
        // The virtual aggregated port only supports quad mode
        setValue(`${namePrefix}.processorSettings.videoLayout`, 'quad')
      }
      if (processorSettings.videoSourceSettings.length !== 4) {
        setValue(`${namePrefix}.processorSettings.videoSourceSettings`, [
          makeMatroxEncoderProcessorVideoSourceSettings(0),
          makeMatroxEncoderProcessorVideoSourceSettings(1),
          makeMatroxEncoderProcessorVideoSourceSettings(2),
          makeMatroxEncoderProcessorVideoSourceSettings(3),
        ])
      }
      if (processorSettings.followVideoSourceInput) {
        // Cannot use video input settings when there are multiple video sources
        setValue(`${namePrefix}.processorSettings.followVideoSourceInput`, false)
      }
    } else {
      // Port indexes of the currently selected interfaces
      const availableInputSources: Set<number> = [physicalPort].reduce((set, port) => {
        const indexes = (port.index ?? '')
          .split(',')
          .map((str) => Number(str))
          .filter((i) => !isNaN(i))
        for (const i of indexes) {
          set.add(i)
        }
        return set
      }, new Set<number>())

      // Regular (non virtual-aggregated) interface:
      const physicalPortIndex = availableInputSources.values().next().value ?? 0
      if (processorSettings.videoLayout !== 'single') {
        // Going from 'quad' --> 'single'
        setValue(`${namePrefix}.processorSettings.videoLayout`, 'single')
        setValue(`${namePrefix}.processorSettings.followVideoSourceInput`, true)
        setValue(`${namePrefix}.processorSettings.videoSourceSettings`, [
          makeMatroxEncoderProcessorVideoSourceSettings(physicalPortIndex),
        ])
      } else {
        // Going from 'single' --> 'single', e.g. SDI 1 --> SDI 2
        if (processorSettings.videoSourceSettings[0].portIndex !== physicalPortIndex) {
          setValue(`${namePrefix}.processorSettings.videoSourceSettings`, [
            { ...processorSettings.videoSourceSettings[0], portIndex: physicalPortIndex },
          ])
        }
      }

      if (processorSettings.audioSourceSettings.portIndex !== physicalPortIndex) {
        setValue(
          `${namePrefix}.processorSettings.audioSourceSettings`,
          makeMatroxEncoderProcessorAudioSourceSettings(physicalPortIndex),
        )
      }
    }
  }, [physicalPort, processorSettings, setValue, namePrefix])

  const audioBitrateConstraints = getAllowedBitratesForMatroxAACEncoder(audioSettings.aacEncoder)

  const audioCheckboxes = Object.values(MatroxAudioChannelPair)
    .filter((pair) => !isNaN(Number(pair)))
    .map((pair) => {
      const currentValue = tsOutputSettings.selectedAudioPairs
      const handleClick = () => {
        const newValue = currentValue.includes(pair as any)
          ? currentValue.filter((p) => p !== pair)
          : [...currentValue, pair]

        setValue(`${namePrefix}.tsOutputSettings.selectedAudioPairs`, newValue, { shouldValidate: true })
      }
      const isSelected = tsOutputSettings.selectedAudioPairs.includes(pair as MatroxAudioChannelPair)
      const canDeselectAdditional = tsOutputSettings.selectedAudioPairs.length > MATROX_ENCODER_MIN_ALLOWED_AUDIOSTREAMS // Min 1
      const canSelectAdditional = tsOutputSettings.selectedAudioPairs.length < MATROX_ENCODER_MAX_ALLOWED_AUDIOSTREAMS // Max 4
      return (
        <Checkbox
          key={pair}
          label={formatAudioPair(pair as any)}
          control={
            <MuiCheckbox
              checked={isSelected}
              disabled={(isSelected && !canDeselectAdditional) || (!isSelected && !canSelectAdditional)}
              onChange={handleClick}
            />
          }
        />
      )
    })

  const isVideoFormDisabled = include === MatroxEncoderIncludeOption.audioOnly
  const isAudioFormDisabled = include === MatroxEncoderIncludeOption.videoOnly

  const hasMultipleVideoSources = processorSettings.videoLayout !== 'single'

  const selectedPixelFormat = videoSettings.forcePixelFormat
    ? videoSettings.forcedPixelFormat
    : processorSettings.followVideoSourceInput
    ? undefined
    : processorSettings.overriddenCanvasSettings.pixelFormat

  const isCBR = videoSettings.rateControl.bitrateControl === MatroxEncoderBitrateControlMode.constant

  return (
    <Grid item xs={12}>
      {hasMultipleVideoSources && (
        <Paper title="Quad View Input Sources" collapsible>
          <Select
            label="Audio input source"
            name={`${namePrefix}.processorSettings.audioSourceSettings.portIndex`}
            disabled={!hasMultipleVideoSources}
            required
            options={inputSourceOptions}
          />
          <GridItem lg={12} xl={12}>
            <div
              style={{
                width: '100%',
                gap: 20,
                justifyContent: 'space-evenly',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={grid} alt={'Grid'} />
              {processorSettings.videoSourceSettings.map((_, index) => {
                const name = `${namePrefix}.processorSettings.videoSourceSettings.${index}`
                const source = ['A', 'B', 'C', 'D'][index]
                return (
                  <Select
                    key={name}
                    label={`Video input source ${source}`}
                    name={`${name}.portIndex`}
                    disabled={!hasMultipleVideoSources}
                    required
                    options={inputSourceOptions}
                  />
                )
              })}
            </div>
          </GridItem>
        </Paper>
      )}

      <Paper id="matrox-encoder-settings" title="Encoder Settings">
        <Select label="Include" name={includeKey} required options={encoderIncludeOptions} />

        <Checkbox
          label={'Enable ancillary data streaming'}
          name={`${namePrefix}.tsOutputSettings.isAncEnabled`}
          disabled={!processorSettings.followVideoSourceInput}
        />

        <Paper title="Audio" collapsible>
          <Select
            label="AAC encoder"
            name={`${namePrefix}.encoderSettings.audioSettings.aacEncoder`}
            disabled={isAudioFormDisabled}
            required
            options={aacEncoderOptions}
          />

          <TextInput
            label="Bit rate (kb/s)"
            name={`${namePrefix}.encoderSettings.audioSettings.bitrateKbps`}
            disabled={isAudioFormDisabled}
            required
            type="number"
            noNegative
            validators={
              audioBitrateConstraints && {
                numericality: {
                  greaterThanOrEqualTo: audioBitrateConstraints.minAllowedBitrateKbps,
                  lessThanOrEqualTo: audioBitrateConstraints.maxAllowedBitrateKbps,
                  message: `Must be between ${audioBitrateConstraints.minAllowedBitrateKbps}-${audioBitrateConstraints.maxAllowedBitrateKbps} for the selected AAC encoder (${audioSettings.aacEncoder})`,
                  noStrings: false,
                },
              }
            }
          />

          <Select
            label="AAC quality"
            name={`${namePrefix}.encoderSettings.audioSettings.aacQuality`}
            disabled={isAudioFormDisabled}
            required
            options={aacQualityLevelOptions}
          />

          {/*  Currently only allow format ADTS due to Matrox API-errors when using e.g. Raw format */}
          <Select
            label="AAC format"
            name={`${namePrefix}.encoderSettings.audioSettings.aacFormat`}
            disabled={true}
            required
            options={aacFormatOptions}
          />

          <Checkbox
            label={'Use temporal noise shaping'}
            name={`${namePrefix}.encoderSettings.audioSettings.useTemporalNoiseShaping`}
            disabled={isAudioFormDisabled}
          />

          <Paper className="outlined" title="Audio Pair Selection">
            {audioCheckboxes}
          </Paper>
        </Paper>

        <Paper title="Video" collapsible>
          <Checkbox name={forcePixelFormatKey} label={'Enforce a pixel format'} disabled={isVideoFormDisabled} />

          <Select
            label="Forced pixel format"
            name={`${namePrefix}.encoderSettings.videoSettings.forcedPixelFormat`}
            disabled={isVideoFormDisabled || !videoSettings.forcePixelFormat}
            required
            options={Object.values(MatroxEncoderPixelFormat)}
          />

          <Select
            label="Encoding profile"
            name={`${namePrefix}.encoderSettings.videoSettings.encodingProfile`}
            disabled={isVideoFormDisabled}
            required
            tooltip={`Must be compatible with the selected pixel format`}
            options={encodingProfileOptions}
            validators={{
              oneOf: {
                validValues: new Set(
                  selectedPixelFormat
                    ? getCompatibleMatroxVideoProfilesForPixelFormat(selectedPixelFormat)
                    : [MatroxEncoderVideoProfile.High422],
                ),
              },
            }}
          />

          <Paper className="outlined" title="Group Of Pictures (GOP)">
            <TextInput
              label="GOP length"
              name={`${namePrefix}.encoderSettings.videoSettings.gop.iFrameInterval`}
              disabled={isVideoFormDisabled}
              required
              tooltip={`Insert a key frame every "${videoSettings.gop.iFrameInterval}" pictures`}
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: videoSettings.gop.pFrameInterval,
                  lessThanOrEqualTo: MATROX_ENCODER_IFRAME_INTERVAL_MAX,
                  divisibleBy: MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER,
                  message: `Must be a multiplier of ${MATROX_ENCODER_IFRAME_INTERVAL_MULTIPLIER} between ${MATROX_ENCODER_IFRAME_INTERVAL_MIN}-${MATROX_ENCODER_IFRAME_INTERVAL_MAX} and greater than or equal to the selected P-frame interval (${videoSettings.gop.pFrameInterval})`,
                  noStrings: false,
                },
              }}
            />
            <TextInput
              label="P-frame interval"
              name={`${namePrefix}.encoderSettings.videoSettings.gop.pFrameInterval`}
              disabled={isVideoFormDisabled}
              required
              tooltip={`Insert a P-frame every "${videoSettings.gop.pFrameInterval}":th frame`}
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROX_ENCODER_PFRAME_INTERVAL_MIN,
                  lessThanOrEqualTo: MATROX_ENCODER_PFRAME_INTERVAL_MAX,
                  message: `Must be between ${MATROX_ENCODER_PFRAME_INTERVAL_MIN}-${MATROX_ENCODER_PFRAME_INTERVAL_MAX} and less than or equal to the selected GOP length (${videoSettings.gop.iFrameInterval})`,
                  noStrings: false,
                },
              }}
            />
          </Paper>
          <Paper className="outlined" title="Rate Control">
            <Select
              label="Encoding Mode"
              name={`${namePrefix}.encoderSettings.videoSettings.rateControl.mode`}
              disabled={isVideoFormDisabled}
              required
              options={encodingModeOptions}
            />

            <Select
              label="Bit rate control"
              name={bitrateControlKey}
              disabled={isVideoFormDisabled}
              required
              options={bitrateControlOptions}
            />

            <TextInput
              label="Target bitrate (Mbps)"
              name={`${namePrefix}.encoderSettings.videoSettings.targetBitrateMbps`}
              disabled={isVideoFormDisabled}
              required
              type="number"
              noNegative
              validators={{
                numericality: {
                  greaterThanOrEqualTo: MATROX_ENCODER_TARGET_BITRATE_MIN,
                  lessThanOrEqualTo: isCBR ? MATROX_ENCODER_MAXBITRATE_MAX : videoSettings.rateControl.maxBitrateMbps,
                  message: `Must be between ${MATROX_ENCODER_TARGET_BITRATE_MIN}-${MATROX_ENCODER_MAXBITRATE_MAX}${
                    isCBR
                      ? ''
                      : ` and less than or equal to the selected max bitrate (${videoSettings.rateControl.maxBitrateMbps})`
                  }`,
                  noStrings: false,
                },
              }}
            />

            <TextInput
              label="Max bit rate (Mbps)"
              name={`${namePrefix}.encoderSettings.videoSettings.rateControl.maxBitrateMbps`}
              disabled={isVideoFormDisabled || isCBR}
              required
              type="number"
              noNegative
              validators={
                isVideoFormDisabled || isCBR
                  ? undefined
                  : {
                      numericality: {
                        greaterThanOrEqualTo: Math.max(MATROX_ENCODER_MAXBITRATE_MIN, videoSettings.targetBitrateMbps),
                        lessThanOrEqualTo: MATROX_ENCODER_MAXBITRATE_MAX,
                        message: `Must be between ${MATROX_ENCODER_MAXBITRATE_MIN}-${MATROX_ENCODER_MAXBITRATE_MAX} and greater than or equal to the selected target bitrate (${videoSettings.targetBitrateMbps})`,
                        noStrings: false,
                      },
                    }
              }
            />
          </Paper>
        </Paper>
      </Paper>
    </Grid>
  )
}

export default MatroxEncoderSettingsComp
