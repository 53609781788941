import { toggleSidebarMenu } from '../actions/uiActions'
import { logoutUser } from '../actions/userActions'
import { createSlice } from '@reduxjs/toolkit'

interface State {
  open?: boolean
}
export const initialStateUi: State = {}

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialStateUi,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        toggleSidebarMenu,
        (state, { payload: isOpen }): State => ({
          ...state,
          open: isOpen ?? !state.open,
        }),
      )
      .addCase(logoutUser.fulfilled, (): State => initialStateUi)
  },
})

export default uiSlice.reducer
