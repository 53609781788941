import { useDispatch } from 'react-redux'
import { useNavigate, useParams, type NavigateFunction } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import { updateService } from '../../redux/actions/serviceActions'
import { Service, ServiceUpdate } from 'common/api/v1/types'
import { Api, AppDispatch } from '../../store'
import { ButtonsPane, Checkbox, Paper, SafeRouting, TextInput, Form } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { formTransform } from '../../utils'
import { Meta } from './meta'
import { useAsync } from 'react-async-hook'
import { FailedFetchingContent } from '../common/FailedFetchingContent'

import { useRoutes } from '../../store'
import { RHF } from '../common/Form'
import { FormProps } from '../common/Form/RHF'

const { serviceApi } = Api

const getInitialState = (service: Service): ServiceUpdate => ({
  ...service,
  desiredRetentionPeriod: service?.retentionPeriods?.[0].desired ?? service?.retentionPeriods?.[0].current,
})

const ServiceForm = ({ formState, getValues }: FormProps<Service>, navigate: NavigateFunction) => {
  const values = getValues()
  const routes = useRoutes()

  const product = getValues().product
  const desiredVolumeCapacityTooltip =
    `Increase the volume capacity of all service instances in Kubernetes (if it's enabled by the Kubernetes provider). The volume capacity cannot be decreased.` +
    (product === 'Redpanda'
      ? `\n\nRemember to also increase the retention.bytes setting for the topics using kubectl and the rpk command or using the Redpanda Console at ${window.origin}/kafka. The retention.bytes setting applies per partition, so the total disk usage per topic and node will be retention.bytes multiplied by 8.`
      : '')

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={formState.isDirty && !formState.isSubmitting} />
        <Form id="service-form" noValidate>
          <Meta service={values} />
          <Paper title="Settings">
            <Checkbox
              name="collectLogs"
              label="Collect logs"
              tooltip="Enabling log collection will ensure the logs are collected, indexed and stored."
            />
            {values.allowEnableDisable && <Checkbox name="enabled" label="Enable" tooltip="Enable the service" />}
            {values.allowsVolumeAdministration && (
              <TextInput
                name="desiredVolumeCapacity"
                label="Desired volume capacity"
                tooltip={desiredVolumeCapacityTooltip}
              />
            )}
            {values.allowsRetentionPeriodAdministration && (
              <TextInput
                name="desiredRetentionPeriod"
                type="number"
                label="Desired retention period (days)"
                tooltip={'Set the retention period in days. 0 means no retention.'}
                validators={{
                  number: {
                    onlyInteger: true,
                    greaterThanOrEqualTo: 0,
                    message: 'Must be a positive integer',
                  },
                }}
              />
            )}
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => {
                  navigate(routes.services())
                },
              },
              Save: { savingState: formState.isSubmitting, primary: true, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const routes = useRoutes()
  const navigate = useNavigate()

  const onSubmit = async (service: Service) => {
    await updateService(service, { dispatch, serviceApi: Api.serviceApi, navigate, routes })
  }

  const { result: serviceToEdit, loading, error } = useAsync(async () => serviceApi.getService(id!), [id])

  return (
    <Wrapper name={['Services', serviceToEdit ? serviceToEdit.purpose : ' ']}>
      <Grid container spacing={0}>
        {error ? (
          <FailedFetchingContent message={'Failed to fetch service'} />
        ) : (
          <Pendable pending={loading}>
            <RHF
              onSubmit={(values) => {
                return onSubmit(formTransform(values))
              }}
              defaultValues={getInitialState(serviceToEdit!)}
              component={(props) => ServiceForm(props, navigate)}
            />
          </Pendable>
        )}
      </Grid>
    </Wrapper>
  )
}
