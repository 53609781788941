import { GraphNodeType, InputGraph as Graph } from 'common/api/v1/types'
import { sort } from 'common/util'
import { AnnotatedGraph } from './types'

export function graphNodeNames(graph: Graph) {
  return Object.keys(graph.nodes)
}

function findNode(graph: Graph, predicate: (nodeName: string, node: any) => boolean) {
  const nodeNames = graphNodeNames(graph)
  return nodeNames.find((n) => {
    return predicate(n, graph.nodes[n])
  })
}

function getRootNode(graph: Graph) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return findNode(graph, (nodeName: string) => !graph.edges.find((e) => e.target == nodeName))!
}

export function layout<T extends Graph>(graph: T) {
  const startX = 0
  const root = getRootNode(graph)
  const annotatedGraph = annotateNodes(graph, root)
  const allNodes = Object.values(annotatedGraph.nodes)
  const inputNodes = allNodes.filter((n) => n.data.type === GraphNodeType.input)
  const inputRegions = inputNodes.map((n) => n.data.region)

  const inputSecondaryRegion = inputNodes[0].data.secondaryRegion
  const outputs = allNodes
    .filter(
      (n) =>
        n.data.type === GraphNodeType.output ||
        n.data.type === GraphNodeType.thumbAppliance ||
        n.data.type === GraphNodeType.derivedInput,
    )
    .sort((n1, n2) => {
      return compare(
        n1,
        n2,
        (n) => (inputRegions.includes(n.data.region) ? '' : n.data.region),
        (n) =>
          Math.min(
            ...n.data.parents.map((p) =>
              [
                undefined,
                GraphNodeType.inputEdgeAppliance,
                GraphNodeType.inputRegionCore,
                GraphNodeType.inputRegionOutputEdgeAppliance,
                GraphNodeType.outputRegionCore,
                GraphNodeType.outputRegionOutputEdgeAppliance,
              ].indexOf(p.data.type),
            ),
          ),
        (n) => n.data.parents[0].name, // upstream appliance name
        (n) => n.name, // output name
        (n) => n.data.name, // output id
      )
    })

  const numInputApplianceOutputs = outputs.filter(
    (o) => o.data.parents[0]?.data.type === GraphNodeType.inputEdgeAppliance,
  ).length
  const singleRegionOffset = outputs.every(
    (o) =>
      o.data.region == inputRegions[0] &&
      (!o.data.secondaryRegion ||
        o.data.secondaryRegion == inputRegions[0] ||
        o.data.secondaryRegion == inputSecondaryRegion),
  )
    ? -1
    : 0

  const regions: string[] = []
  outputs.forEach((o, i) => {
    for (const p of o.data.parents) {
      if (p.data.type == GraphNodeType.outputRegionCore) {
        if (!regions.includes(p.data.region)) {
          regions.push(p.data.region)
        }
      } else {
        for (const coreParent of p.data.parents) {
          if (!regions.includes(coreParent.data.region)) {
            regions.push(coreParent.data.region)
          }
        }
      }
    }

    o.position = {
      x: startX + 5 + singleRegionOffset,
      y: i,
    }
  })

  const regionOutputStats: {
    [region: string]: {
      maxNonOutputApplianceOutputY: number
      numCoreOutputs: number
      maxCoreOutputY: number
      minY: number
    }
  } = {}
  for (const o of outputs) {
    const r = o.data.region
    const y = o.position.y
    let regionStats = regionOutputStats[r]
    if (!regionStats) {
      regionStats = regionOutputStats[r] = {
        maxNonOutputApplianceOutputY: -1,
        numCoreOutputs: 0,
        maxCoreOutputY: -1,
        minY: Number.MAX_SAFE_INTEGER,
      }
    }
    if (y < regionStats.minY) {
      regionStats.minY = y
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (
      [GraphNodeType.inputEdgeAppliance, GraphNodeType.inputRegionCore, GraphNodeType.outputRegionCore].includes(
        o.data.parents[0]?.data.type,
      )
    ) {
      regionStats.numCoreOutputs += 1
      if (y > regionStats.maxNonOutputApplianceOutputY) {
        regionStats.maxNonOutputApplianceOutputY = y
      }
      if ([GraphNodeType.inputRegionCore, GraphNodeType.outputRegionCore].includes(o.data.parents[0].data.type)) {
        if (y > regionStats.maxCoreOutputY) {
          regionStats.maxCoreOutputY = y
        }
      }
    }
  }

  const outputAppliances = allNodes.filter((n) =>
    [GraphNodeType.inputRegionOutputEdgeAppliance, GraphNodeType.outputRegionOutputEdgeAppliance].includes(n.data.type),
  )
  outputAppliances.sort((n1, n2) => {
    return compare(n1, n2, (n) =>
      outputs.findIndex(
        (o) => o.data.parents[0].data.name == n.data.name && o.data.parents[0].data.type == n.data.type,
      ),
    )
  })

  let maxOutputApplianceY = -1
  outputAppliances.forEach((o) => {
    const regionStats = regionOutputStats[o.data.region]
    let outputMinY = 0
    for (const output of outputs.filter((outp) => outp.data.parents[0].data.name == o.data.name)) {
      if (output.position.y < outputMinY) {
        outputMinY = output.position.y
      }
    }

    const minFromNonOutputAppliance =
      regionStats.maxNonOutputApplianceOutputY == -1 ? 0 : regionStats.maxNonOutputApplianceOutputY + 1

    const y = outputMinY

    const minLimits = [y, minFromNonOutputAppliance, regionStats.minY, maxOutputApplianceY + 1]

    const actualY = Math.max(...minLimits)
    o.position = {
      x: startX + 4 + singleRegionOffset,
      y: actualY,
    }
    maxOutputApplianceY = actualY
  })

  const outputRegionCoresInitial = allNodes.filter((n) => n.data.type === GraphNodeType.outputRegionCore)
  const outputRegionCores: typeof allNodes = []
  for (const region of regions) {
    const singleOutputRegionCores = outputRegionCoresInitial.filter((c) => c.data.region == region)
    singleOutputRegionCores.sort((n1, n2) => {
      return compare(
        n1,
        n2,
        (n) => {
          const outputIndex = outputs.findIndex(
            (o) => o.data.parents[0].data.name == n.data.name && o.data.parents[0].data.type == n.data.type,
          )
          if (outputIndex !== -1) {
            return outputIndex
          }
          const outputApplianceIndex = outputAppliances.findIndex(
            (outputAppliance) => outputAppliance.data.parents[0].data.region == n.data.region,
          )
          if (outputApplianceIndex !== -1) {
            return outputs.length + outputApplianceIndex
          }
          return 1000000
        },
        (n) => n.name,
      )
    })
    outputRegionCores.push(...singleOutputRegionCores)
  }

  const inputRegionCores = allNodes.filter((n) => n.data.type === GraphNodeType.inputRegionCore)

  inputRegionCores.sort((n1, n2) => {
    return compare(
      n1,
      n2,
      (n) => {
        const outputIndex = outputs.findIndex(
          (o) => o.data.parents[0].data.name == n.data.name && o.data.parents[0].data.type == n.data.type,
        )
        if (outputIndex !== -1) {
          return outputIndex
        }
        const outputApplianceIndex = outputAppliances.findIndex(
          (outputAppliance) => outputAppliance.data.parents[0].data.name == n.data.name,
        )
        if (outputApplianceIndex !== -1) {
          return outputs.length + 1 + outputApplianceIndex
        }

        const outputRegionIndex = outputRegionCores.findIndex((c) => c.data.parents[0].data.name == n.data.name)
        if (outputRegionIndex !== -1) {
          return outputs.length + 1 + outputAppliances.length + 1 + outputRegionIndex
        }
        return 1000000
      },
      (n) => n.name,
    )
  })

  for (const inputRegionCore of inputRegionCores) {
    if (!inputRegions.includes(inputRegionCore.data.region)) {
      inputRegions.push(inputRegionCore.data.region)
    }
  }

  inputRegionCores.sort((c1, c2) => {
    const index1 = inputRegions.indexOf(c1.data.region)
    const index2 = inputRegions.indexOf(c2.data.region)
    if (index1 < index2) {
      return -1
    }
    if (index1 > index2) {
      return 1
    }
    return c1.name.localeCompare(c2.name) // appliance name
  })

  inputRegionCores.forEach((o, i) => {
    o.position = {
      x: startX + 2,
      y: i + numInputApplianceOutputs,
    }
  })

  const inputApplianceNodes = allNodes
    .filter((n) => n.data.type === GraphNodeType.inputEdgeAppliance)
    .sort(sort((n) => n.data.meta?.priority))

  inputApplianceNodes.forEach((o, i) => {
    o.position = {
      x: startX + 1,
      y: i,
    }
  })

  const numOutputsInInputRegion = outputs.filter((o) => inputRegions.includes(o.data.region)).length

  outputRegionCores.forEach((o, i) => {
    o.position = {
      x: startX + 3,
      y: i + numOutputsInInputRegion,
    }
  })

  inputNodes.forEach((i) => (i.position = { x: startX, y: 0 }))

  return graph
}

function getAdjacent(graph: Graph, nodeRef: string) {
  const adjacent = graph.edges.filter((edge) => edge.source == nodeRef).map((edge) => edge.target)
  return adjacent
}

// Annotate nodes with depth and parent metadata
function annotateNodes(
  graph: Graph,
  nodeName: string,
  rootDepth = 0,
  parent?: AnnotatedGraph<Graph>['nodes'][number],
  ancestors: string[] = [],
): AnnotatedGraph<Graph> {
  const annotatedGraph = graph as AnnotatedGraph<Graph>
  const node = getNode(annotatedGraph, nodeName)
  if (!node) {
    throw new Error(`Could not find node ${nodeName}`)
  }

  node.depth = rootDepth
  if (!node.data.parents) {
    node.data.parents = []
  }
  if (parent) {
    if (!node.data.parents.includes(parent)) {
      node.data.parents.push(parent)
    }
  }

  node.data.name = nodeName

  const adjacentDepth = rootDepth + 1
  const adjacentNodes = getAdjacent(annotatedGraph, nodeName)
  if (adjacentNodes.length === 0) {
    node.leaf = true
    node.maxHeight = 0
    node.minHeight = 0
    let height = 0
    while (ancestors.length > 0) {
      height++
      const ancestor = ancestors.pop()
      if (!ancestor) {
        throw new Error()
      }
      const ancestorNode = getNode(annotatedGraph, ancestor)
      if (typeof ancestorNode.maxHeight === 'undefined' || height > ancestorNode.maxHeight) {
        ancestorNode.maxHeight = height
      }
      if (typeof ancestorNode.minHeight === 'undefined' || ancestorNode.minHeight > height) {
        ancestorNode.minHeight = height
      }
    }
  }
  for (const nodeRef of adjacentNodes) {
    if (!ancestors.includes(nodeRef)) {
      annotateNodes(graph, nodeRef, adjacentDepth, node, [...ancestors, nodeName])
    }
  }
  return annotatedGraph
}

export function getNode<TNodeData, TEdgeData>(graph: Graph<TNodeData, TEdgeData>, name: string) {
  const n = graph.nodes[name]
  if (!n) {
    throw new Error(`Could not get node ${name}`)
  }
  return n
}

function compare<T>(v1: T, v2: T, ...selectors: Array<(v: T) => string | number | boolean | undefined>) {
  for (const s of selectors) {
    const v1Val = s(v1)
    const v2Val = s(v2)
    if (typeof v1Val === 'undefined') {
      return -1
    }
    if (typeof v2Val === 'undefined') {
      return 1
    }
    if (v1Val < v2Val) {
      return -1
    }
    if (v1Val > v2Val) {
      return 1
    }
  }
  return 0
}
