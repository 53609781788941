import { useCallback } from 'react'
import { Autocomplete } from './Form'
import { PaginatedRequestParams } from '../../api/nm-types'
import { Api } from '../../store'
import { MetadataTag } from 'common/api/v1/types'
import { distinct } from 'common/utils'
import { GridItemProps } from './Form/GridItem'
import { AutocompleteProps } from './Form/Autocomplete'
import { useFormContext } from 'react-hook-form'

type Props = {
  formName: string
  type: 'appliance' | 'input' | 'output'
} & Pick<GridItemProps, 'newLine'> &
  Pick<AutocompleteProps<any>, 'commentProps'>

export const TagInputField = ({ formName, type, ...otherProps }: Props) => {
  const { getValues } = useFormContext()
  const fieldValue = getValues(formName) || []

  const api = useCallback(async (params: PaginatedRequestParams<any>) => {
    const listResult = await Api.tagApi.fetchTags(params)
    // Super user receives tags from all groups - no point in showing duplicates
    const uniqueTags = distinct(listResult.items, (a, b) => a.name === b.name)
    return {
      items: uniqueTags.map((t) => t.name),
      total: uniqueTags.length,
    }
  }, [])

  return (
    <Autocomplete<MetadataTag['name']>
      {...otherProps}
      name={formName}
      label={`Tags`}
      required={false}
      api={api}
      multiple
      allowCustomOptions={true}
      validators={{}}
      filterSelectedOptions={true}
      comment={`Assign searchable tags to this ${type}`}
      defaultOption={fieldValue}
      getOptionValue={(option) => option}
      getOptionLabel={(option) => option}
      optionComparator={(o1, o2) => o1 === o2}
      autoSelect
    />
  )
}
