import { useEffect, useMemo, useReducer, useState } from 'react'
import { autocompleteClasses } from '@mui/material/Autocomplete'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FilterBy, filtersToUrlParams, FilterTemplate, urlParamsToFilters } from './FilterTemplate'
import CircularProgress from '@mui/material/CircularProgress'
import Popper from '@mui/material/Popper'
import useAutocomplete from '@mui/material/useAutocomplete'
import Chip from '@mui/material/Chip'
import debounce from 'lodash/debounce'
import { omit } from 'common/api/v1/helpers'
import { styled } from '@mui/material/styles'
import { ApplicationException } from '../../ApplicationException'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Search from '@mui/icons-material/Search'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { usePageParams } from '../../../../utils'
import { GlobalState } from '../../../../store'
import { areObjectsEqual, equals } from 'common/util'
import { saveUrlParams as saveUrlParamsInRedux } from '../../../../redux/actions/urlParamActions'
import { PaginatedRequestParams } from '../../../../api/nm-types'
import { UrlParamCache } from '../../../../redux/reducers/urlParamReducer'
import { distinct } from 'common/utils'

export type UrlParamsFilteredSearchBarProps<T> = Pick<
  FilteredSearchBarProps,
  'searchTokenFilters' | 'rawTextFilter'
> & {
  // Key for storing url params in redux or undefined if we don't want to store URL params (e.g. service overview)
  urlParamCacheKey: keyof UrlParamCache | undefined
  mapUrlParamToFilterFn: (key: keyof T, value: string) => FilterBy | undefined
  mapFilterToUrlParamFn: (filter: FilterBy) => Partial<T>
}

interface FilteredSearchBarProps {
  // Filters that require a search token/template + operator, e.g. searchToken=name, operator='matches'
  searchTokenFilters: FilterTemplate[]

  // The filter to apply to raw text searches.
  // A raw text search is a "quick search" that doesn't require selecting a template, operator and value
  // but rather simply enter a string and press enter to search immediately.
  rawTextFilter: FilterTemplate

  appliedFilters: FilterBy[]
  onFiltersApplied: (filters: FilterBy[]) => void
}

interface FilterValue {
  value: string
  displayName: string
}
interface WipFilter {
  key: string
  operator?: FilterBy['operator']
  value?: FilterValue
}
type SavedFilter = Required<WipFilter>

interface CommonState {
  autocomplete: {
    placeholder: string
    // The text displayed in the search input text field
    searchText: string
    // Whether to allow the user entering their own custom values (i.e. don't constrain the options to the possibleValues).
    // e.g. when constructing a filter using the matches operator, e.g. "<key> matches <custom input>"
    allowCustomOptions: boolean
    // If the options popup should automatically open. If false the user must manually click to open it.
    autoOpenOptionsPopup: boolean
    // If the first available option in the list should automatically receive focus (convenient when navigating using keyboard)
    autoFocusFirstOption: boolean
  }
}

interface SelectTemplateState extends CommonState {
  state: 'selectTemplate'
}
interface SelectOperatorState extends CommonState {
  state: 'selectOperator'
  template: FilterTemplate
}
interface SelectValueState extends CommonState {
  state: 'selectValue'
  template: FilterTemplate
  operator: FilterBy['operator']
}

type State = SelectTemplateState | SelectOperatorState | SelectValueState

const selectTemplateState = (): SelectTemplateState => {
  return {
    state: 'selectTemplate',
    autocomplete: {
      placeholder: autocompletePlaceholder({
        state: 'selectTemplate',
      }),
      searchText: '',
      allowCustomOptions: false,
      autoOpenOptionsPopup: false, // Close popup after a filter is applied
      autoFocusFirstOption: true,
    },
  }
}

const selectOperatorState = (template: FilterTemplate): SelectOperatorState => ({
  state: 'selectOperator',
  template,
  autocomplete: {
    placeholder: autocompletePlaceholder({ state: 'selectOperator' }),
    searchText: '', // Reset search text
    allowCustomOptions: false, // Only allow user to select operator from list of predefined filters
    autoOpenOptionsPopup: true,
    autoFocusFirstOption: true,
  },
})

const selectValueState = (template: FilterTemplate, operator: SelectValueState['operator']): SelectValueState => {
  return {
    state: 'selectValue',
    template,
    operator,
    autocomplete: {
      placeholder: autocompletePlaceholder({ state: 'selectValue', template }),
      searchText: '', // Reset search text
      allowCustomOptions: filterRequiresCustomInput(template), // Allow user to enter custom value if there are no predefined values to select from
      autoOpenOptionsPopup: true,
      autoFocusFirstOption: true,
    },
  }
}

function reducer(
  prevState: State,
  action:
    | { type: 'wipFilterTemplateSelected'; template: FilterTemplate }
    | { type: 'wipFilterOperatorSelected'; operator: FilterBy['operator'] }
    | { type: 'wipFilterValueSelected' }
    | { type: 'wipFilterRemoved' }
    | { type: 'searchTextEntered'; text: string }
    | { type: 'filtersApplied'; filters: SavedFilter[] },
): State {
  switch (action.type) {
    case 'wipFilterTemplateSelected': {
      if (prevState.state !== 'selectTemplate') {
        throw new Error(`Invalid state transition to selectOperator from ${prevState.state} (expected selectTemplate)`)
      }
      return selectOperatorState(action.template)
    }
    case 'wipFilterOperatorSelected': {
      if (prevState.state !== 'selectOperator') {
        throw new Error(`Invalid state transition to selectValue from ${prevState.state} (expected selectOperator)`)
      }
      return selectValueState(prevState.template, action.operator)
    }
    case 'wipFilterValueSelected': {
      if (prevState.state !== 'selectValue') {
        throw new Error(`Invalid state transition to selectTemplate from ${prevState.state} (expected selectValue)`)
      }
      return selectTemplateState()
    }
    case 'wipFilterRemoved': {
      return selectTemplateState()
    }
    case 'filtersApplied': {
      const isConstructingFilter = prevState.state !== 'selectTemplate'
      return {
        ...prevState,
        autocomplete: {
          ...prevState.autocomplete,
          searchText: '', // Reset search text on apply
          placeholder: autocompletePlaceholder({
            state: prevState.state,
            template: 'template' in prevState ? prevState.template : undefined,
          }),
          // Keep options open if we're currently working on a filter
          // e.g. user has selected template + operator and then clicks "X" on a previously applied filter
          autoOpenOptionsPopup: isConstructingFilter,
        },
      }
    }
    case 'searchTextEntered': {
      return { ...prevState, autocomplete: { ...prevState.autocomplete, searchText: action.text } }
    }
  }
}

export const UrlParamFilteredSearchBar = <T extends PaginatedRequestParams>({
  searchTokenFilters,
  rawTextFilter,
  urlParamCacheKey,
  mapUrlParamToFilterFn,
  mapFilterToUrlParamFn,
}: UrlParamsFilteredSearchBarProps<T>) => {
  const dispatch = useDispatch()

  const [urlParams, setUrlParams] = usePageParams<T>()

  const { urlParamCache } = useSelector(
    ({ urlParamReducer }: GlobalState) => ({ urlParamCache: urlParamReducer.cachedUrlParams }),
    equals,
  )

  const currentlyAppliedFilters = useMemo(
    () => urlParamsToFilters(urlParams, mapUrlParamToFilterFn),
    [urlParams, mapUrlParamToFilterFn],
  )

  const populateUrlWithSelectedFilters = (newFilters: FilterBy[]) => {
    const newUrlParams = filtersToUrlParams(newFilters, currentlyAppliedFilters, mapFilterToUrlParamFn)
    setUrlParams({ ...newUrlParams, pageNumber: '0' } as T) // Reset page to 0 when applying filters
  }

  useEffect(() => {
    if (!urlParamCacheKey) return
    // Observe url params and cache them in redux on change
    const cachedUrlParams = urlParamCache[urlParamCacheKey]
    if (!areObjectsEqual(urlParams, cachedUrlParams)) {
      const action = saveUrlParamsInRedux(urlParamCacheKey, urlParams as T)
      dispatch(action)
    }
  }, [JSON.stringify(urlParams), urlParamCacheKey, dispatch])

  return (
    <FilteredSearchBar
      appliedFilters={currentlyAppliedFilters}
      rawTextFilter={rawTextFilter}
      searchTokenFilters={searchTokenFilters}
      onFiltersApplied={populateUrlWithSelectedFilters}
    />
  )
}

const FilteredSearchBar = ({
  appliedFilters: currentFilters,
  searchTokenFilters,
  rawTextFilter,
  onFiltersApplied,
}: FilteredSearchBarProps) => {
  const appliedFilters = useMemo(() => currentFilters.map(toSavedFilter).flat(), [JSON.stringify(currentFilters)])
  const [state, dispatch] = useReducer(reducer, selectTemplateState(), undefined)

  useEffect(() => {
    dispatch({ type: 'filtersApplied', filters: appliedFilters })
  }, [JSON.stringify(appliedFilters)])

  const requestApplyFilters = (reason: string) => {
    if (isOptionsPopupOpen) return
    if (isPerformingRawTextSearch) {
      // Allow typing a custom string and pressing enter to search immediately by value
      // i.e. not having to select template, operator and value
      doApplyFilters(
        [
          // Remove existing rawTextFilter since it will be re-inserted
          ...appliedFilters.filter((f) => f.key !== rawTextFilter.key && f.operator !== rawTextFilter.operator),
          {
            key: rawTextFilter.key,
            operator: rawTextFilter.operator,
            value: {
              value: state.autocomplete.searchText,
              displayName: state.autocomplete.searchText,
            },
          },
        ],
        `${reason} (raw text search)`,
      )
    } else {
      // Allow pressing enter to search when options popup is closed
      doApplyFilters(appliedFilters, reason)
    }
  }
  const doApplyFilters = (filters: SavedFilter[], _reason: string) => {
    const distinctKeepingLastOccurrence = distinct(filters.toReversed(), (a, b) => {
      const canFilterOccurMultipleTimes = a.operator === 'isOneOf'
      return (
        a.key === b.key &&
        a.operator === b.operator &&
        (!canFilterOccurMultipleTimes || a.value?.value === b.value?.value)
      )
    }).toReversed()
    // console.log(`applying filters: ${_reason}: ${JSON.stringify(distinctKeepingLastOccurrence)}`)
    onFiltersApplied(toFilterBy(distinctKeepingLastOccurrence))
  }

  const [fetchState, setFetchState] = useState<{
    isLoading: boolean
    result: FilterValue[]
    error: string | undefined
  }>({
    result: [],
    isLoading: false,
    error: undefined,
  })

  useEffect(() => {
    let isMounted = true
    let debouncedFetch:
      | {
          (): Promise<void> | undefined
          cancel(): void
        }
      | undefined = undefined

    const setAutocompleteOptions = async (searchText: string) => {
      if (isPerformingRawTextSearch) {
        // Don't provide any options when performing raw text search
        return []
      }
      switch (state.state) {
        case 'selectTemplate': {
          setFetchState({
            isLoading: false,
            error: undefined,
            result: searchTokenFilters.map((e) => ({ value: e.key, displayName: e.key })),
          })
          break
        }

        case 'selectOperator': {
          setFetchState({
            isLoading: false,
            error: undefined,
            result: [
              {
                value: state.template.operator,
                displayName: formattedOperator(state.template.operator),
              },
            ],
          })
          break
        }
        case 'selectValue': {
          if (state.template.possibleValuesSync) {
            setFetchState({
              isLoading: false,
              error: undefined,
              result: removeAlreadySelectedValues(
                state.template.possibleValuesSync(searchText),
                state.template,
                appliedFilters,
              ),
            })
          } else if (state.template.possibleValues) {
            const template = state.template
            debouncedFetch = debounce(
              () =>
                template
                  .possibleValues?.(searchText)
                  .then((items) => {
                    if (isMounted) {
                      setFetchState({
                        isLoading: false,
                        error: undefined,
                        result: removeAlreadySelectedValues(items, template, appliedFilters),
                      })
                    }
                  })
                  .catch((error) => {
                    if (isMounted) {
                      setFetchState({
                        isLoading: false,
                        result: [],
                        error:
                          error instanceof ApplicationException
                            ? `${error.errorInfo.text} - ${error.errorInfo.details}`
                            : JSON.stringify(error),
                      })
                    }
                  }),
              500,
            )
            setFetchState({
              isLoading: true,
              error: undefined,
              result: [],
            })
            debouncedFetch?.()
          } else {
            // Filter requires custom input
            setFetchState({
              isLoading: false,
              error: undefined,
              result: [],
            })
          }
        }
      }
    }

    setAutocompleteOptions(state.autocomplete.searchText).catch((_) => {
      /* nop*/
    })
    return () => {
      isMounted = false
      debouncedFetch?.cancel()
    }
  }, [state.state, appliedFilters, state.autocomplete.searchText])

  const hasEnteredText = state.autocomplete.searchText.length > 0
  const isPerformingRawTextSearch = state.state === 'selectTemplate' && hasEnteredText
  const selectedFilterHasSelectableOptions = !state.autocomplete.allowCustomOptions
  const canOptionsPopupOpen = !isPerformingRawTextSearch && selectedFilterHasSelectableOptions
  const [wantsOptionPopupOpen, setWantsOptionPopupOpen] = useState(false)
  const isOptionsPopupOpen = canOptionsPopupOpen && wantsOptionPopupOpen
  useEffect(
    () => {
      // Update wantsOptionPopupOpen accordingly after a state change, i.e. let the new state decide if the popup should be opened by default or not.
      if (state.autocomplete.autoOpenOptionsPopup !== wantsOptionPopupOpen) {
        setWantsOptionPopupOpen(state.autocomplete.autoOpenOptionsPopup)
      }
    },
    // don't trigger effect on changes to 'wantsOptionPopupOpen' since then we can't toggle it (i.e. only observe state.autcomplete)
    [state.autocomplete],
  )

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    focused: isAutocompleteFocused,
    groupedOptions: autocompleteOptions,
    setAnchorEl: setOptionsPopupAnchor,
    anchorEl: optionsPopupAnchor,
  } = useAutocomplete({
    open: isOptionsPopupOpen,
    openOnFocus: false, // false because we want explicit control of when the popup should open
    onOpen: () => setWantsOptionPopupOpen(true),
    onClose: () => setWantsOptionPopupOpen(false),
    options: fetchState.result,
    getOptionLabel: (o) => {
      const isCustomOption = typeof o === 'string'
      return isCustomOption ? o : o.displayName
    },
    freeSolo: state.autocomplete.allowCustomOptions,
    autoSelect: state.autocomplete.allowCustomOptions,
    autoHighlight: state.autocomplete.autoFocusFirstOption,
    inputValue: state.autocomplete.searchText,
    value: null, // value is never used but we set it to null to make the autocomplete controlled (and also to avoid the warnings about value not one of the available options)
    isOptionEqualToValue: (option, value) => option.value === value.value,
    onInputChange: (_event, enteredText, reason) => {
      // Don't update `enteredText` when reason == 'reset' since it then contains
      // the **formatted** name of the selected value, not the actual selected value.
      // E.g. "xxx (appliance software is out of date)" instead of simply "xxx".
      if (['input', 'clear'].includes(reason)) {
        dispatch({ type: 'searchTextEntered', text: enteredText })
      }
    },
    onChange: (_event, selectedOption) => {
      // A value/option was selected from the popup
      if (!selectedOption) return
      if (state.state === 'selectTemplate') {
        if (typeof selectedOption === 'string') {
          // Programmer error - should not happen (state.autocomplete.allowCustomOptions is false)
          throw new Error(`Selected option has unexpected type`)
        }
        dispatch({
          type: 'wipFilterTemplateSelected',
          template: searchTokenFilters.find((e) => e.key === selectedOption.value)!,
        })
      } else if (state.state === 'selectOperator') {
        if (typeof selectedOption === 'string') {
          // Programmer error - should not happen (state.autocomplete.allowCustomOptions is false)
          throw new Error(`Selected option has unexpected type`)
        }
        dispatch({ type: 'wipFilterOperatorSelected', operator: selectedOption.value as FilterBy['operator'] })
      } else if (state.state === 'selectValue') {
        const value =
          typeof selectedOption === 'string' ? { value: selectedOption, displayName: selectedOption } : selectedOption
        dispatch({ type: 'wipFilterValueSelected' })
        doApplyFilters(
          [...appliedFilters, { key: state.template.key, operator: state.operator, value }],
          'wipFilterValueSelected',
        )
      }
    },
  })

  const inputProps = getInputProps()
  const scrollInputIntoHorizontallyView = () => {
    if (inputProps.ref && 'current' in inputProps.ref) {
      inputProps.ref.current?.scrollIntoView({ block: 'nearest', inline: 'end' })
    }
  }

  useEffect(() => {
    // Scroll input into view once on initial render (when autocomplete is not necessarily focused)
    scrollInputIntoHorizontallyView()
  }, [])

  useEffect(() => {
    if (!isAutocompleteFocused) return
    if (appliedFilters.length === 0) return
    // Keep input field in view when many filters are selected and horizontal scrollbar is showing
    scrollInputIntoHorizontallyView()
  }, [isAutocompleteFocused, appliedFilters, inputProps])

  return (
    <Root {...getRootProps()} data-testid="fliteredSearch-root">
      <Box data-testid={'filteredSearch-wrapper1'}>
        <Box
          data-testid={'filteredSearch-wrapper2'}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            data-testid={'filteredSearch-inputWrapper'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              overflowX: 'auto',
              flexGrow: 1,
            }}
          >
            {appliedFilters.map((filter, index) => {
              return (
                <FilterView
                  key={`${filter.key}${filter.operator}${filter.value.value}`}
                  onDelete={() => doApplyFilters(appliedFilters.toSpliced(index, 1), 'delete chip')}
                  filter={filter}
                  dataTestId={`filteredSearch-appliedFilter-${filter.key}`}
                />
              )
            })}
            {'template' in state && (
              <FilterView
                filter={{ key: state.template.key, operator: 'operator' in state ? state.operator : undefined }}
                onDelete={() => dispatch({ type: 'wipFilterRemoved' })}
                dataTestId={`filteredSearch-wipFilter-${state.template.key}`}
              />
            )}
            <div data-testid="filteredSearch-optionsPopupAnchor" ref={setOptionsPopupAnchor} style={{ width: 1 }} />
            <Input
              autoFocus={false}
              data-testid={'filteredSearch-input'}
              {...omit(inputProps, 'value')}
              value={state.autocomplete.searchText}
              placeholder={state.autocomplete.placeholder}
              onKeyDown={(event) => {
                // Convenient keyboard shortcuts
                if (event.key === 'Enter') {
                  if (state.state === 'selectTemplate') {
                    requestApplyFilters('enter key pressed')
                  }
                } else if (event.key === 'Backspace') {
                  if (hasEnteredText) {
                    // Erase text only (i.e. don't perform any quick-actions).
                    return
                  }
                  if (state.state === 'selectTemplate') {
                    if (appliedFilters.length > 0) {
                      // Allow pressing backspace to remove last applied filter and search
                      doApplyFilters(
                        appliedFilters.toSpliced(appliedFilters.length - 1, 1),
                        'selectedTemplate::backspace',
                      )
                    }
                  } else {
                    // Allow pressing backspace to remove current wip-filter
                    dispatch({ type: 'wipFilterRemoved' })
                  }
                }
              }}
            />
          </Box>

          <IconButton
            disabled={isOptionsPopupOpen}
            onClick={() => {
              requestApplyFilters('search button clicked')
            }}
          >
            <Search />
          </IconButton>
          <IconButton
            data-testid={'filteredSearch-clear'}
            disabled={appliedFilters.length === 0}
            onClick={() => {
              if (appliedFilters.length > 0) {
                doApplyFilters([], 'clear button clicked')
              }
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Popper
        open={isOptionsPopupOpen}
        sx={{ zIndex: 2 }} // Set zIndex on to avoid MUI checkboxes behind the Popper from being focused due to this bug (https://github.com/mui/material-ui/issues/20856)
        anchorEl={optionsPopupAnchor}
        placement="bottom-start"
        modifiers={[{ name: 'offset', options: { offset: [0, 12] } }]} // offset options y=12px below anchorEl
      >
        <Paper>
          <OptionsContainer {...getListboxProps()} data-testid={'filteredSearchOptionsContainer-2'}>
            {fetchState.isLoading && (
              <li>
                <CircularProgress color="inherit" size={25} />
              </li>
            )}
            {fetchState.error && (
              <li>
                <Typography component="span" variant="body2" color="error">
                  {fetchState.error}
                </Typography>
              </li>
            )}
            {!fetchState.isLoading && !fetchState.error && autocompleteOptions.length === 0 && (
              <li>
                <Typography component="span" variant="body1" color="textPrimary">
                  No available options
                </Typography>
              </li>
            )}
            {!fetchState.isLoading &&
              !fetchState.error &&
              autocompleteOptions.length > 0 &&
              (autocompleteOptions as typeof fetchState.result).map((option, index) => {
                const { key, ...optionProps } = getOptionProps({ option, index })
                return (
                  <li key={key} {...optionProps}>
                    <Typography component="span" variant="body1" color="textPrimary">
                      {option.displayName}
                    </Typography>
                  </li>
                )
              })}
          </OptionsContainer>
        </Paper>
      </Popper>
    </Root>
  )
}

function removeAlreadySelectedValues(
  options: FilterValue[],
  currentTemplate: FilterTemplate,
  appliedFilters: SavedFilter[],
) {
  return options.filter((value) => {
    const isValueAlreadySelected = appliedFilters.some((existingFilter) =>
      filterEquals(existingFilter, { key: currentTemplate.key, operator: currentTemplate.operator, value }),
    )
    return !isValueAlreadySelected
  })
}

function autocompletePlaceholder({ state, template }: { state: State['state']; template?: FilterTemplate }) {
  switch (state) {
    case 'selectTemplate':
      return 'select search token or enter raw search text'
    case 'selectOperator':
      return 'select operator'
    case 'selectValue': {
      return template && filterRequiresCustomInput(template) ? `enter ${template.key}` : 'select value'
    }
  }
}

function filterRequiresCustomInput(template: FilterTemplate): boolean {
  // no predefined values to select from
  return !template.possibleValues && !template.possibleValuesSync
}

function filterEquals(a: WipFilter, b: WipFilter): boolean {
  return a.key === b.key && a.operator === b.operator && a.value?.value === b.value?.value
}
function formattedOperator(op: FilterBy['operator']): string {
  return op === 'isOneOf' ? 'is one of' : op
}

function toSavedFilter(f: FilterBy): SavedFilter[] {
  if ('value' in f) {
    return [{ key: f.key, operator: f.operator, value: { value: f.value, displayName: f.displayName ?? f.value } }]
  }
  return f.values.map((childFilter) => ({
    key: f.key,
    operator: f.operator,
    value: {
      value: childFilter.value,
      displayName: childFilter.displayName,
    },
  }))
}
function toFilterBy(filters: SavedFilter[]): FilterBy[] {
  const groupedByKey: { [key: SavedFilter['key']]: FilterBy } = {}
  for (const { key, operator, value } of filters) {
    const existingFilter: FilterBy | undefined = groupedByKey[key]
    if (!existingFilter) {
      groupedByKey[key] =
        operator === 'isOneOf'
          ? { key, operator, values: [value] }
          : {
              key,
              operator,
              value: value.value,
              displayName: value.displayName,
            }
    } else if (existingFilter.operator === 'isOneOf') {
      existingFilter.values.push(value)
    }
  }
  return Object.values(groupedByKey)
}

const FilterView = ({
  filter: { key, operator, value },
  onDelete,
  dataTestId,
}: {
  filter: SavedFilter | WipFilter
  onDelete: () => void
  dataTestId: string
}) => {
  const isWip = !value
  return (
    <HorizontallyShrinkableChip
      label={`${key} ${operator ? formattedOperator(operator) : ''} ${value?.displayName || ''}`}
      data-testid={dataTestId}
      onDelete={onDelete}
      size="small"
      variant={isWip ? 'outlined' : 'filled'}
    />
  )
}

const Root = styled('div')(({ theme }) => ({
  borderRadius: 800,
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
  '&:hover': {
    backgroundColor: `${theme.palette.action.selected}`,
  },
}))

const HorizontallyShrinkableChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(0.5),
  margin: theme.spacing(0.5),
  height: '100%',
  '& .MuiChip-label': {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'clip',
  },
}))

const Input = styled('input')(({ theme }) => ({
  height: 40,
  marginLeft: 10,
  border: 0,
  outline: 0,
  flexGrow: 1,
  fieldSizing: 'content', // size input by its content so that its placeholder isn't truncated.
  backgroundColor: 'transparent',
  '::placeholder': {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.disabled,
  },
}))

const OptionsContainer = styled('ul')(({ theme }) => ({
  minWidth: 200,
  listStyle: 'none',
  paddingLeft: 0,
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.divider,

  '& li': {
    padding: 6,
  },
  '& li[aria-selected="true"]': {
    backgroundColor: theme.palette.action.selected,
  },
  [`& li.${autocompleteClasses.focused}`]: {
    backgroundColor: theme.palette.action.focus,
    cursor: 'pointer',
  },
}))
