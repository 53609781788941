import {
  Address,
  ApplianceFeatures,
  ApplianceType,
  ApplianceVersion,
  IpPortMode,
  Output,
  ZixiLinkMode,
  ZixiMode,
  ZixiOutputPort,
} from 'common/api/v1/types'

import { Checkbox, Select, TextInput } from '../../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../../utils'
import LinksArray, { LinkFields, linkSetDefault } from './LinksArray'
import { supportsZixiAdaptiveFec } from 'common/versions'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'
import { useFormContext } from 'react-hook-form'

export enum ZixiFields {
  zixiMode = 'zixiMode',
  localPort = 'localPort',
  streamId = 'streamId',
  password = 'password',
  fecLatency = 'fecLatency',
  optimizeFec = 'optimizeFec',
  adaptiveFec = 'adaptiveFec',
  maxFecOverhead = 'maxFecOverhead',
  pullPort = 'pullPort',
  retransmitBuf = 'retransmitBuf',
  maxBitrateMbps = 'maxBitrateMbps',
  unrecoveredPacketsDetection = 'unrecoveredPacketsDetection',
  unrecoveredPacketsThreshold = 'unrecoveredPacketsThreshold',
  linkMode = 'linkMode',
  linkSet1 = 'linkSet1',
  linkSet2 = 'linkSet2',
}
export const zixiDefaults = () =>
  createDefaultFiledValues(
    Object.keys(ZixiFields),
    [ZixiFields.unrecoveredPacketsDetection, ZixiFields.optimizeFec, ZixiFields.adaptiveFec],
    {
      [ZixiFields.retransmitBuf]: 500,
      [ZixiFields.maxBitrateMbps]: 20,
      [ZixiFields.fecLatency]: 30,
      [ZixiFields.linkMode]: ZixiLinkMode.single,
      [ZixiFields.maxFecOverhead]: 0,
      [ZixiFields.linkSet1]: [{ ...linkSetDefault }],
      [ZixiFields.linkSet2]: [{ ...linkSetDefault }],
    },
  )
export const getZixiFieldsToSave = (port: ZixiOutputPort) => [
  ZixiFields.zixiMode,
  ZixiFields.streamId,
  ZixiFields.password,
  ZixiFields.unrecoveredPacketsDetection,
  ...(port.unrecoveredPacketsDetection ? [ZixiFields.unrecoveredPacketsThreshold] : []),
  ...(port.zixiMode === ZixiMode.push
    ? [
        ZixiFields.fecLatency,
        ZixiFields.maxBitrateMbps,
        ZixiFields.optimizeFec,
        ZixiFields.adaptiveFec,
        ZixiFields.maxFecOverhead,
        ZixiFields.retransmitBuf,
        ZixiFields.linkMode,
        ZixiFields.linkSet1,
        ...(port.linkMode === ZixiLinkMode.single ? [] : [ZixiFields.linkSet2]),
      ]
    : []),
]

interface ZixiFormProps {
  addresses: Array<Address>
  namePrefix: string
  applianceType: ApplianceType
  applianceVersion: ApplianceVersion
  applianceFeatures: ApplianceFeatures
}
const ZixiForm = ({ addresses, namePrefix, applianceType, applianceFeatures, applianceVersion }: ZixiFormProps) => {
  const { getValues, watch } = useFormContext<Output & AllowUncontrolled>()

  const port = getValues(namePrefix) as ZixiOutputPort

  const zixiModeKey = `${namePrefix}.${ZixiFields.zixiMode}`
  const unrecoveredPacketsDetectionKey = `${namePrefix}.${ZixiFields.unrecoveredPacketsDetection}`
  const linkModeKey = `${namePrefix}.${ZixiFields.linkMode}`

  // Watch specific fields since they control conditional rendering
  watch(zixiModeKey)
  watch(unrecoveredPacketsDetectionKey)
  watch(linkModeKey)

  const isCoreAppliance = applianceType === ApplianceType.core
  const isVa = isVaApplianceType(applianceType)
  const localAddressSelector = `${namePrefix}.${ZixiFields.linkSet1}[0].${LinkFields.localIp}`
  const supportedModes = applianceFeatures.output?.modes.find((m) => m.mode === IpPortMode.zixi)?.subModes ?? []
  const isAdaptiveFecSupported = supportsZixiAdaptiveFec(applianceType, applianceVersion)

  return (
    <>
      <Select name={zixiModeKey} label="Connection mode" options={supportedModes} required />

      <TextInput name={`${namePrefix}.${ZixiFields.streamId}`} label="Stream id" required newLine />
      <TextInput name={`${namePrefix}.${ZixiFields.password}`} label="Password" />

      {port.zixiMode === ZixiMode.push && (
        <>
          <Select
            name={`${namePrefix}.${ZixiFields.linkMode}`}
            label="Link mode"
            options={isCoreAppliance ? [ZixiLinkMode.single] : Object.values(ZixiLinkMode)}
            required
          />

          {port.linkMode === ZixiLinkMode.single && (
            <>
              <Select
                name={localAddressSelector}
                label="Local address"
                options={makeAddressOptions(getValues(localAddressSelector), addresses)}
                newLine
                required
                validators={{
                  addressIn: { addresses },
                }}
              />
              <TextInput
                name={`${namePrefix}.${ZixiFields.linkSet1}[0].${LinkFields.remoteIp}`}
                label="Remote host"
                required
                validators={{ ip: {} }}
              />
              <TextInput
                name={`${namePrefix}.${ZixiFields.linkSet1}[0].${LinkFields.remotePort}`}
                label="Remote UDP port"
                type="number"
                required
                noNegative
                validators={{ port: { isUdp: true } }}
              />
            </>
          )}
          {port.linkMode !== ZixiLinkMode.single && <LinksArray addresses={addresses} namePrefix={namePrefix} />}

          <TextInput
            name={`${namePrefix}.${ZixiFields.retransmitBuf}`}
            label="Retransmission buffer (ms)"
            type="number"
            required
            noNegative
            newLine
            validators={{
              number: {
                lessThanOrEqualTo: 30000,
                message: 'Must be no more than 30000',
              },
            }}
          />
          {isCoreAppliance && (
            <TextInput
              name={`${namePrefix}.${ZixiFields.maxBitrateMbps}`}
              label="Max bitrate (Mbps)"
              tooltip={'Recommended: double the expected bitrate of the stream'}
              type="number"
              required
              noNegative
              validators={{
                number: {
                  greaterThanOrEqualTo: 1,
                  message: 'Must be greater than 0',
                },
              }}
            />
          )}
          <TextInput
            name={`${namePrefix}.${ZixiFields.maxFecOverhead}`}
            label="Max fec overhead (%)"
            type="number"
            noNegative
            validators={{
              number: {
                lessThanOrEqualTo: 100,
                message: 'Must be no more than 100',
              },
            }}
          />
          <TextInput
            name={`${namePrefix}.${ZixiFields.fecLatency}`}
            label="Fec latency (ms)"
            type="number"
            noNegative
            validators={{
              number: {
                lessThanOrEqualTo: Math.pow(2, 32) - 1,
                message: `Must be no more than ${Math.pow(2, 32) - 1}`,
              },
            }}
          />
          {isVa && isAdaptiveFecSupported && (
            <Checkbox
              name={`${namePrefix}.${ZixiFields.adaptiveFec}`}
              label="Adaptive fec"
              tooltip={'Enables FEC congestion avoidance'}
            />
          )}
          {isVa && !isAdaptiveFecSupported && (
            <Checkbox name={`${namePrefix}.${ZixiFields.optimizeFec}`} label="Optimize fec" />
          )}
        </>
      )}

      {isVa && (
        <Checkbox
          name={unrecoveredPacketsDetectionKey}
          label="Unrecovered packets alarm"
          tooltip="Turn detection of unrecovered packets on or off, including associated alarm. Threshold is in packets per minute. Generates an alarm if unrecovered packets per minute is higher than the threshold."
          newLine
        />
      )}
      {port.unrecoveredPacketsDetection && (
        <TextInput
          name={`${namePrefix}.${ZixiFields.unrecoveredPacketsThreshold}`}
          label="Threshold (packets/min)"
          type="number"
          noNegative
          validators={{
            number: {
              lessThanOrEqualTo: Math.pow(2, 64),
              message: `Must be no more than ${Math.pow(2, 64)}`,
            },
          }}
        />
      )}
    </>
  )
}

export default ZixiForm
