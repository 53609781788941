import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import { ExternalRegionMode, Role, type Region } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { ButtonsPane, Form, Paper, RHF, SafeRouting, TextInput } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Pendable from '../../common/Pendable'
import { useConfirmationDialog } from '../../common/ConfirmDialog'
import { formTransform, useUser } from '../../../utils/index'
import { Meta } from './meta'
import { getRegionDetails, clearRegion, updateRegion, deleteRegion } from '../../../redux/actions/regionsActions'
import { RegionDetails } from '../../../api/region/api'

import { useRoutes } from '../../../store'
import { FormProps } from 'src/components/common/Form/RHF'

type State = RegionDetails

const ScalingPane = () => {
  return (
    <Paper title="Scaling">
      <TextInput
        label="Video Scale"
        name="videoScale.replicas"
        required
        type="number"
        noNegative
        validators={{
          number: {
            greaterThanOrEqualTo: 0,
            message: 'Cannot be negative',
          },
        }}
      />
      <TextInput
        label="Thumb Scale"
        name="thumbScale.replicas"
        required
        type="number"
        noNegative
        validators={{
          number: {
            greaterThanOrEqualTo: 0,
            message: 'Cannot be negative',
          },
        }}
      />
    </Paper>
  )
}

export const Edit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getRegionDetails(id!))
    return () => {
      dispatch(clearRegion())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { regionDetails, loading } = useSelector(
    ({ regionsReducer }: GlobalState) => ({
      regionDetails: regionsReducer.regionDetails,
      loading: regionsReducer.loading,
    }),
    shallowEqual,
  )

  const user = useUser()
  if (user.role !== Role.super) {
    navigate(-1)
    return null
  }

  const onSubmit = (regionDetails: State) => {
    dispatch(
      updateRegion({
        regionId: regionDetails.region.id,
        regionName: regionDetails.region.name,
        videoScaleCount: regionDetails.videoScale.replicas!,
        thumbScaleCount: regionDetails.thumbScale.replicas!,
      }),
    )
  }

  return (
    <Wrapper name={['Regions', regionDetails?.region.name]}>
      <Grid container spacing={0}>
        <Pendable pending={loading}>
          {regionDetails ? (
            <>
              <Meta regionDetails={regionDetails} />
              <RHF<State>
                defaultValues={regionDetails}
                onSubmit={(values) => {
                  onSubmit(formTransform(values))
                }}
                component={RegionForm}
              />
            </>
          ) : null}
        </Pendable>
      </Grid>
    </Wrapper>
  )
}

const RegionForm = ({ getValues, formState }: FormProps<State>) => {
  const navigate = useNavigate()
  const routes = useRoutes()
  const dispatch = useDispatch<AppDispatch>()
  const user = useUser()
  const { showConfirmation } = useConfirmationDialog()

  const { saving, regionDetails, devMode } = useSelector(
    ({ regionsReducer, settingsReducer }: GlobalState) => ({
      saving: regionsReducer.saving,
      regionDetails: regionsReducer.regionDetails,
      devMode: settingsReducer.devMode,
    }),
    shallowEqual,
  )

  const isEditableRegion = [ExternalRegionMode.core, ExternalRegionMode.externalK8s].includes(
    getValues().region.external,
  )
  const showDeleteButton = devMode && !regionDetails?.region.default_region && user.role === Role.super
  const handleDeleteRegion = (region: Region) => {
    showConfirmation(() => {
      dispatch(deleteRegion(region))
      navigate(routes.regions())
    }, `Are you sure you want to delete the region "${region.name}"?`)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={formState.isDirty && !formState.isSubmitSuccessful && Boolean(regionDetails)} />
        <Form id="region-form" noValidate>
          {isEditableRegion ? <ScalingPane /> : null}
          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.regions()),
              },
              Save: {
                disabled: !isEditableRegion,
                id: 'button-save',
                savingState: !!saving,
                primary: true,
                type: 'submit',
              },
            }}
            secondary={
              regionDetails && showDeleteButton
                ? {
                    'DELETE REGION': { onClick: () => handleDeleteRegion(regionDetails.region) },
                  }
                : {}
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}
