import Delete from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { useDispatch } from 'react-redux'
import { deleteOverlay } from '../../redux/actions/overlaysActions'
import { AppDispatch } from 'src/store'
import { useConfirmationDialog } from '../../utils/hooks'

export const DeleteButton = ({ id }: { id: string }) => {
  const dispatch = useDispatch<AppDispatch>()
  const setConfirm = useConfirmationDialog()

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    setConfirm(() => {
      dispatch(deleteOverlay(id))
    }, 'Are you sure you want to delete this overlay? Removing an overlay used by an active stream will cause it to glitch for a moment.')
  }

  return (
    <Stack direction="row" justifyContent="flex-end">
      <IconButton
        onClick={(e) => {
          handleDelete(e)
        }}
      >
        <Delete />
      </IconButton>
    </Stack>
  )
}
