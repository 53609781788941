import { PaginatedRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListK8sServiceFilterKey {
  name = 'name',
}

export function mapK8sFilterToUrlParam(filter: FilterBy): Partial<PaginatedRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListK8sServiceFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToK8sServiceFilter(key: keyof PaginatedRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListK8sServiceFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeListK8sServicesFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListK8sServiceFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
