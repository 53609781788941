import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { Group, GroupRecipientList, GroupRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { useUser } from '../../../utils'
import { GroupsRequestParams } from '../../../api/nm-types'
import { useFormContext } from 'react-hook-form'

const styles = {
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '48px',
    minWidth: '140px',
  },
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
    '&:hover': {
      borderColor: (theme: Theme) => theme.palette.error.dark,
    },
  },
}

const { groupListsApi, groupsApi } = Api

const AddRemoveButton = ({ id }: { id: string }) => {
  const { setValue, getValues } = useFormContext<GroupRecipientList & Pick<GroupRecipientListUpdate, 'addGroups'>>()
  const values = getValues()
  const [isAdding, setIsAdding] = useState(getValues().addGroups.includes(id))
  const onAdd = (id: Group['id']) => {
    setIsAdding(true)
    setValue('addGroups', values.addGroups.concat(id))
  }
  const onCancel = (id: Group['id']) => {
    setIsAdding(false)
    setValue(
      'addGroups',
      values.addGroups.filter((groupId) => groupId !== id),
    )
  }

  return (
    <Box sx={styles.actions}>
      {isAdding ? (
        <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
          Cancel
        </Button>
      ) : (
        <Button onClick={() => onAdd(id)} variant="outlined" size="small" name="add-group">
          Add
        </Button>
      )}
    </Box>
  )
}

const AvailableGroups = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { getValues } = useFormContext<GroupRecipientList & Pick<GroupRecipientListUpdate, 'addGroups'>>()
  const values = getValues()
  const { group } = useUser()

  return (
    <Grid item xs={12}>
      <PaginatedList<GroupsRequestParams, Group>
        api={
          values.id
            ? function (params) {
                return groupListsApi.getGroups(values.id, false, params)
              }
            : groupsApi.getPureGroups.bind(groupsApi)
        }
        emptyMessage="no available groups"
        hideSearch={hideFilter}
        notFoundMessage="no matching groups"
        otherParams={{ userGroup: group }}
        onListChange={({ filter, total = 0 }) => setHideFilter(!filter && total < 10)}
        searchPlaceholder="Find specific group…"
        List={({ list }) => (
          <Table
            id="availablegroups-table"
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                title: 'name',
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                title: 'add',
                getValue: ({ id }) => <AddRemoveButton id={id} />,
                props: {
                  align: 'right',
                },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default AvailableGroups
