import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'

import Wrapper from '../../common/Wrapper'
import Alarms from './Alarms'
import { usePageParams } from '../../../utils'
import {
  ListAlarmFilterKey,
  makeListAlarmsFilter,
  mapAlarmFilterToUrlParam,
  mapUrlParamToAlarmFilter,
} from '../listAlarmFilter'
import { AlarmsRequestParams } from '../../../api/nm-types'
import { GlobalState } from '../../../store'
import { UrlParamFilteredSearchBar } from '../../common/Filters/FilterView/FilteredSearchBar'

const styles = {
  paper: {
    padding: (theme: Theme) => theme.spacing(2),
  },
}

const AlarmsTable = () => {
  const devMode = useSelector(({ settingsReducer }: GlobalState) => settingsReducer.devMode, shallowEqual)
  const [urlParams, setUrlParams] = usePageParams<
    Omit<AlarmsRequestParams, 'rowsPerPage' | 'asc' | 'desc'> & { applianceName?: string }
  >()
  const { applianceId, applianceName } = urlParams

  useEffect(() => {
    // Remove 'applianceName' from url params if applianceId filter is removed. (applianceName is only used by frontend to display the name)
    const keepApplianceName = !!applianceId
    if (applianceName && !keepApplianceName) {
      setUrlParams({
        ...urlParams,
        applianceName: '',
      })
    }
  }, [applianceId, applianceName])

  const filters = makeListAlarmsFilter()
  return (
    <Wrapper name={['Alarms', applianceId ? applianceName : undefined]}>
      <Toolbar disableGutters>
        <div style={{ flexShrink: 1, flexGrow: 1 }}>
          <UrlParamFilteredSearchBar
            urlParamCacheKey={'alarms'}
            mapUrlParamToFilterFn={mapUrlParamToAlarmFilter}
            mapFilterToUrlParamFn={mapAlarmFilterToUrlParam}
            searchTokenFilters={filters}
            rawTextFilter={filters.find((f) => f.key === ListAlarmFilterKey.name)!}
          />
        </div>
      </Toolbar>
      <Paper sx={styles.paper}>
        <Alarms showTexts showClearButton={devMode} />
      </Paper>
    </Wrapper>
  )
}

export default AlarmsTable
