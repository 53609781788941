import Grid from '@mui/material/Grid'

import { Paper } from '../../common/Form'
import Wrapper from '../../common/Wrapper'
import Alarms from '../../alarms/Table/Alarms'
import Map, { MapDataType } from '../../geo/Map'

import Availability from './Availability'
import Graph from './Graph'
import { Link } from '../../common/Link'

import { useRoutes } from '../../../store'

const classes = {
  paper: {
    margin: 0,
    overflow: 'hidden',
  },
  stretched: {
    height: '100%',
  },
}

export const Dashboard = () => {
  const routes = useRoutes()

  return (
    <Wrapper name="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} lg={6}>
          <Map dataType={MapDataType.appliances} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ ...classes.paper, ...classes.stretched }} title="Availability">
            <Availability />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={classes.paper}>
            <Graph />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper sx={classes.paper} title={<Link to={routes.alarms()}>Alarms</Link>}>
            <Alarms size={'small'} />
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}
