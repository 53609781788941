import React, { useContext } from 'react'
import { OnSubmit } from './RHF'

const Form = ({ id, noValidate, children }: { id: string; noValidate?: boolean; children: React.ReactNode }) => {
  const ctx = useContext(OnSubmit)

  return (
    <form id={id} noValidate={noValidate} onSubmit={ctx?.onSubmit}>
      {children}
    </form>
  )
}

export default Form
