import { InputTransformationSectionProps } from './InputTransformationSection'
import { InputFormProps } from '../Edit/InputForm'
import { useCallback, useEffect, useState } from 'react'
import Pendable from '../../../components/common/Pendable'
import { Autocomplete, Paper } from '../../common/Form'
import { PidMapTable } from './PidMapTable'
import { useFormContext } from 'react-hook-form'

export const MptsDemuxSection = ({ currentParentInput }: InputTransformationSectionProps) => {
  return (
    <>
      <Paper
        title="Service filter"
        tooltip="Services/programs selected here will be kept and all others will be removed.
      
            Constant bit rate transport streams will become variable bit rate transport streams when a service filter is applied."
      >
        <ServiceFilterSection currentParentInput={currentParentInput} />
      </Paper>
      <Paper
        title="PID remapping"
        tooltip="Note that PID remapping may cause constant bit rate transport streams to become variable bit rate transport streams.
      
            Null-packets will be kept in the stream in the absence of PID deletion rules. This means a constant bit rate
            stream will continue having constant bit rate. Note also that if this is combined with a service filter the
            null-packets will still be kept but the transport stream will have variable bit rate.
            "
      >
        <PidMapTable />
      </Paper>
    </>
  )
}

interface ServiceFilterState {
  parentId: string
  tooltip: string
  defaultOptionIds: number[]
  items: ServiceFilterItem[]
}
interface ServiceFilterItem {
  name: string
  id: number
}

const ServiceFilterSection = ({ currentParentInput }: InputTransformationSectionProps) => {
  const fieldName = 'deriveFrom.ingestTransform.services'
  const { getValues, setValue } = useFormContext<InputFormProps>()
  const values = getValues()

  const [filterState, setFilterItem] = useState<ServiceFilterState>()

  const api = useCallback(
    () =>
      Promise.resolve({
        items: filterState?.items.map((x) => x.id) ?? [],
        total: filterState?.items.length ?? 0,
        filter: undefined,
      }),
    [filterState],
  )

  useEffect(() => {
    if (!(values?.deriveFrom?.ingestTransform?.type === 'mpts-demux')) return

    // Create options from all services currently in tsInfo
    const inputTsInfoServices = currentParentInput.tsInfo?.[0]?.services ?? []
    const itemsInTsInfo: ServiceFilterItem[] = inputTsInfoServices
      .filter((x) => x.id !== undefined)
      .map((x) => ({
        id: x.id!,
        name: x.name ?? x.id!.toString(),
      }))

    // Create options for saved services not in tsInfo anymore
    const savedServiceIds = values?.deriveFrom?.ingestTransform?.services ?? []
    const itemsSavedButNotInTsInfo = savedServiceIds
      .filter((savedId) => !itemsInTsInfo.some((x) => x.id === savedId))
      .map((id) => ({
        id: id!,
        name: `${id}`,
      }))

    // ...and merge them
    const items = itemsInTsInfo.concat(itemsSavedButNotInTsInfo)

    // IDs of the currently saved services
    const defaultOptionIds = savedServiceIds

    const tooltip =
      "Services to include. If a service isn't available in the current stream, it can be added by manually entering its Service ID"
    setFilterItem({
      parentId: currentParentInput.id,
      tooltip: tooltip,
      items: items,
      defaultOptionIds: defaultOptionIds,
    })
  }, [currentParentInput, setValue])

  const loading = filterState === undefined

  if (loading) {
    return (
      <Pendable pending={loading}>
        <></>
      </Pendable>
    )
  }

  return (
    <Autocomplete<number>
      key={filterState.parentId}
      required={true}
      name={fieldName}
      label="Services"
      tooltip={filterState.tooltip}
      defaultOption={filterState.defaultOptionIds}
      api={api}
      getOptionValue={(option) => option}
      getOptionLabel={(option) => filterState.items.find((x) => x.id === option)?.name ?? option.toString()}
      optionComparator={(o1, o2) => o1 == o2}
      multiple
      allowCustomOptions
      filterSelectedOptions
      autoSelect
      validators={{
        integers: {},
      }}
      xs={4}
    />
  )
}
