import { Overlay } from 'common/api/v1/types'
import { useEffect, useRef } from 'react'

export const PreviewCanvas = ({ overlay, resolution }: { overlay: Overlay; resolution: string }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  // More descriptive values for the preview, to help with size estimation
  const previewExamples = {
    timecode: '00:00:00:00',
    datetime: '1970-01-01 00:00:00',
    codec: 'h264',
    encoder: 'h264_nvenc',
  }

  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  useEffect(() => {
    if (overlay && canvasRef.current) {
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      if (!ctx) return

      const [rw, rh] = resolution.split('x').map((value) => parseInt(value))
      canvas.width = rw
      canvas.height = rh

      // Clear the canvas before rendering the overlay components
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Render components onto the canvas
      overlay.components.forEach((component) => {
        switch (component.type) {
          case 'image': {
            const img = new Image()
            img.src = component.data
            img.onload = () => {
              const hRatio = component.maxWidth / img.width
              const vRatio = component.maxHeight / img.height
              const ratio = Math.min(hRatio, vRatio)

              let x = component.x.value
              if (component.x.baseline === 'end') {
                x = canvas.width - x - component.maxWidth
              }

              let y = component.y.value
              if (component.y.baseline === 'end') {
                y = canvas.height - y - component.maxHeight
              }
              const centerShiftX = x + (component.maxWidth - img.width * ratio) / 2
              const centerShiftY = y + (component.maxHeight - img.height * ratio) / 2
              ctx.globalAlpha = component.opacity
              ctx.drawImage(
                img,
                0,
                0,
                img.width,
                img.height,
                centerShiftX,
                centerShiftY,
                img.width * ratio,
                img.height * ratio,
              )
              ctx.globalAlpha = 1
            }
            break
          }
          case 'text': {
            ctx.textBaseline = 'top'
            ctx.font = `${component.fontSize}px sans-serif`

            const text = component.preset
              ? Object.keys(previewExamples).includes(component.preset)
                ? previewExamples[component.preset]
                : component.preset
              : component.text
            const textMeasurements = ctx.measureText(text)
            const textWidth = textMeasurements.width
            const textHeight = textMeasurements.fontBoundingBoxAscent + textMeasurements.fontBoundingBoxDescent

            let x = component.x.value
            if (component.x.baseline === 'end') {
              x = canvas.width - x - textWidth
            }

            let y = component.y.value
            if (component.y.baseline === 'end') {
              y = canvas.height - y - textHeight
            }

            if (component.box) {
              const c = hexToRgb(component.box.color)
              if (c) {
                const rgba = `rgba(${c.r}, ${c.g}, ${c.b}, ${component.box.opacity})`
                ctx.fillStyle = rgba
                ctx.strokeStyle = rgba
              }

              const boxX = x - component.box.borderWidth
              const boxY = y - component.box.borderWidth
              const boxWidth = textWidth + component.box.borderWidth * 2
              const boxHeight = textHeight + component.box.borderWidth * 2

              ctx.fillRect(boxX, boxY, boxWidth, boxHeight)
              ctx.strokeRect(boxX, boxY, boxWidth, boxHeight)
            }

            ctx.fillStyle = component.color
            ctx.fillText(text, x, y)
            break
          }
        }
      })
    }
  }, [overlay, resolution])

  const canvasStyles = {
    // "Transparent" checkerboard background
    backgroundColor: 'lightgray',
    backgroundImage: `
          linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc),
          linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc)
        `,
    backgroundSize: '40px 40px',
    backgroundPosition: '0 0, 20px 20px',

    // Keep the 16:9 aspect ratio
    maxWidth: '1280px',
    maxHeight: '720px',
    width: '100%',
    height: '100%',
  }

  return <canvas ref={canvasRef} style={canvasStyles} />
}
