import { GroupsRequestParams } from '../../api/nm-types'
import { FilterBy, FilterTemplate } from '../common/Filters/FilterView/FilterTemplate'

export enum ListGroupFilterKey {
  name = 'name',
}

export function mapGroupFilterToUrlParam(filter: FilterBy): Partial<GroupsRequestParams> {
  if ('value' in filter) {
    switch (filter.key) {
      case ListGroupFilterKey.name:
        return { filter: filter.value }
    }
  }
  return {}
}

export function mapUrlParamToGroupFilter(key: keyof GroupsRequestParams, value: string): FilterBy | undefined {
  switch (key) {
    case 'filter':
      return {
        key: ListGroupFilterKey.name,
        operator: 'matches',
        value,
      }
  }
  return undefined
}

export function makeListGroupsFilter(): FilterTemplate[] {
  const filterTemplates: FilterTemplate[] = [{ key: ListGroupFilterKey.name, operator: 'matches' }]
  return filterTemplates.sort((a, b) => a.key.localeCompare(b.key))
}
