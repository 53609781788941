import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

/**
 * Helper to focus fields that failed validation. It uses React Hook Form context.
 */
const FocusError = () => {
  const {
    formState: { errors, isSubmitting, isValidating },
  } = useFormContext()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const selector = `[name="${keys[0]}"]`
        const errorElement = document.querySelector(selector) as HTMLElement
        if (errorElement) {
          const parent = errorElement.closest('.MuiFormControl-root')
          parent && parent.scrollIntoView()
        }
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}

export default FocusError
