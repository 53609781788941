import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { OverlayComponent, OverlayComponentText } from 'common/api/v1/types'
import { MuiColorInput } from 'mui-color-input'
import { useState } from 'react'

export const BoxControls = ({
  component,
  index,
  updateComponent,
}: {
  component: OverlayComponentText
  index: number
  updateComponent: (index: number, component: OverlayComponent) => void
}) => {
  const calculateHex8 = () => {
    if (!component.box) return '#00000080'

    const hex = component.box!.color
    const opacity = Math.round(component.box!.opacity * 255)
      .toString(16)
      .padStart(2, '0')
    return hex + opacity
  }

  const [colorInput, setColorInput] = useState(calculateHex8())

  const handleChange = (newValue: string) => {
    setColorInput(newValue)

    // Parse hex8 to hex + opacity as a number
    const hex = newValue.substring(0, 7)
    const opacity = parseInt(newValue.substring(7), 16) / 255

    updateComponent(index, { ...component, box: { ...component.box!, color: hex, opacity } })
  }

  return (
    <>
      <FormControlLabel
        control={<Switch />}
        label="Background"
        checked={component.box !== undefined}
        onChange={(event) => {
          if ((event as React.ChangeEvent<HTMLInputElement>).target.checked) {
            updateComponent(index, { ...component, box: { color: '#000000', borderWidth: 1, opacity: 0.5 } })
          } else {
            updateComponent(index, { ...component, box: undefined })
            setColorInput('#00000080')
          }
        }}
      />

      {component.box !== undefined && (
        <Stack direction="row" spacing={2}>
          <MuiColorInput format="hex8" value={colorInput} onChange={handleChange} fullWidth />
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="border-width-label">Border width</InputLabel>
            <OutlinedInput
              id="border-width"
              label="Border width"
              type="number"
              endAdornment={<InputAdornment position="end">px</InputAdornment>}
              value={component.box.borderWidth}
              onChange={(event) =>
                updateComponent(index, {
                  ...component,
                  box: { ...component.box!, borderWidth: parseInt(event.target.value) || 0 },
                })
              }
              onWheel={(e) => (e.target as HTMLElement).blur()}
            />
          </FormControl>
        </Stack>
      )}
    </>
  )
}
