import { Checkbox, Paper, Select } from '../../../../common/Form'
import {
  ApplianceSettings,
  MatroxDecoderApplianceSettings,
  MatroxDecoderConfig,
  MatroxDecoderFramerateFamily,
  MatroxDecoderOutputResolution,
  MatroxDecoderOutputResolutionFamilyOne,
  MatroxDecoderOutputResolutionFamilyThree,
  MatroxDecoderOutputResolutionFamilyTwo,
  MatroxSdiOutputPort,
} from 'common/api/v1/types'
import { RichOption } from '../../../../common/Form/Select'
import {
  getResolutionsInFramerateFamily,
  is4kResolution,
  isMatroxSdi12gPort,
  isResolutionInFramerateFamily,
} from 'common/matrox'

enum MatroxSdiFields {
  decoderSettings = 'decoderSettings',
}

export function matroxSdiPortDefaults(settings?: ApplianceSettings): {
  [MatroxSdiFields.decoderSettings]: MatroxDecoderConfig
} {
  const currentlySelectedFamily =
    settings && 'genlock' in settings && 'framerateFamily' in settings.genlock
      ? settings.genlock.framerateFamily
      : MatroxDecoderFramerateFamily.familyThree

  const options = getResolutionOptions(0, currentlySelectedFamily, undefined)
  const initialOption = options.find((option) => !option.disabled) ?? options[0]
  return {
    [MatroxSdiFields.decoderSettings]: {
      outputSettings: {
        isAncEnabled: false,
        resolution: initialOption.value as MatroxDecoderOutputResolution,
      },
    },
  }
}

export const getMatroxSdiPortFormFields = () => {
  const fields: string[] = [MatroxSdiFields.decoderSettings]
  return fields
}

interface Props {
  namePrefix: string
  physicalPortIndex: string
  port: MatroxSdiOutputPort
  settings: MatroxDecoderApplianceSettings
}

// Expected format '720p50', '1080i25'
const outputResolutionRegexp = /([0-9]+)([a-zA-Z])([0-9]+)/
function compareAscendingByResolutionScanAndFramerate(a: string, b: string): number {
  const [_, heightAStr, scanMethodA, frameRateAStr] = a.match(outputResolutionRegexp) ?? []
  const [__, heightBStr, scanMethodB, frameRateBStr] = b.match(outputResolutionRegexp) ?? []
  if (heightAStr && scanMethodA && frameRateAStr && heightBStr && scanMethodB && frameRateBStr) {
    const heightA = Number(heightAStr)
    const heightB = Number(heightBStr)
    if (heightA === heightB) {
      if (scanMethodA === scanMethodB) {
        const frameRateA = Number(frameRateAStr)
        const frameRateB = Number(frameRateBStr)
        return frameRateA - frameRateB
      } else {
        return scanMethodA.localeCompare(scanMethodB)
      }
    } else {
      return heightA - heightB
    }
  }
  return a.localeCompare(b)
}

function getResolutionOptions(
  portIndex: number,
  currentlySelectedFamilyOnDevice: MatroxDecoderFramerateFamily,
  currentResolution: string | undefined,
): RichOption[] {
  const sdi4kTooltip = 'will disable all other SDI ports on the appliance'
  const notAvailable4kTooltip = 'only available on 12G port SDI-4'

  const allFramerateFamilies = [
    MatroxDecoderFramerateFamily.familyOne,
    MatroxDecoderFramerateFamily.familyTwo,
    MatroxDecoderFramerateFamily.familyThree,
  ]

  function formattedFramerate(res: MatroxDecoderOutputResolution): string {
    // Convert frame rate to field rate for interlaced resolutions to be consistent with other Network Manager UI
    // (Matrox displays everything as frame rate).
    switch (res) {
      case MatroxDecoderOutputResolutionFamilyOne['1080i25']:
        return '1080i50'
      case MatroxDecoderOutputResolutionFamilyTwo['1080i29.97']:
        return '1080i59.94'
      case MatroxDecoderOutputResolutionFamilyThree['1080i30']:
        return '1080i60'
      default:
        return res
    }
  }
  const allFramerates = allFramerateFamilies.flatMap(getResolutionsInFramerateFamily)
  const options: RichOption[] = allFramerates.map((res) => ({
    name: formattedFramerate(res),
    value: res,
    disabled:
      (is4kResolution(res) && !isMatroxSdi12gPort(portIndex)) ||
      !isResolutionInFramerateFamily(res, currentlySelectedFamilyOnDevice),
    tooltip: is4kResolution(res) ? (isMatroxSdi12gPort(portIndex) ? sdi4kTooltip : notAvailable4kTooltip) : undefined,
  }))

  if (currentResolution && !options.find((res) => res.value === currentResolution)) {
    options.push({
      name: currentResolution,
      value: currentResolution,
      disabled: true,
      tooltip: 'Not a member of the current framerate family on the appliance',
    })
  }
  return options.sort((a, b) => compareAscendingByResolutionScanAndFramerate(a.value!.toString(), b.value!.toString()))
}

const MatroxSdiPortForm = ({ namePrefix, physicalPortIndex, port, settings }: Props) => {
  const resolutionOptions = getResolutionOptions(
    Number(physicalPortIndex),
    settings.genlock.framerateFamily,
    port.decoderSettings.outputSettings.resolution,
  )

  return (
    <Paper title="Output Settings">
      <Select
        label="Resolution"
        name={`${namePrefix}.${MatroxSdiFields.decoderSettings}.outputSettings.resolution`}
        required
        tooltip={
          'Only resolutions belonging to the currently selected frame rate family on the appliance are selectable.\n\nThe frame rate family of the appliance can be can be changed in the Appliance Details page.'
        }
        options={resolutionOptions}
        validators={{
          oneOf: { validValues: new Set(resolutionOptions.filter((o) => !o.disabled).map((o) => o.value)) },
        }}
      />
      <Checkbox
        label={'Include ancillary data'}
        name={`${namePrefix}.${MatroxSdiFields.decoderSettings}.outputSettings.isAncEnabled`}
      />
    </Paper>
  )
}

export default MatroxSdiPortForm
