import { useCallback, useEffect } from 'react'
import Button from '@mui/material/Button'

import { AudioCodec, AudioStream, AudioSystemStandard, sdiAudioPairs, SupportedAudioCodec } from 'common/api/v1/types'
import { GridItem, Paper, Select } from '../../common/Form'
import { RichOption } from '../../common/Form/Select'
import { useFormContext } from 'react-hook-form'

interface AudioStreamFormProps {
  audioStream: AudioStream
  namePrefix: string
  supportedCodecs: SupportedAudioCodec[]
  onRemove: () => void
  onUpdate: (audioStream: AudioStream) => void
}
const AudioStreamForm = ({ namePrefix, supportedCodecs, onRemove }: AudioStreamFormProps) => {
  const { getValues, setValue, watch } = useFormContext()

  const audioCodecKey = `${namePrefix}.codec`
  const audioBitrateKey = `${namePrefix}.bitrate`
  const audioBitDepthKey = `${namePrefix}.bitDepth`

  const audioStream = getValues(namePrefix) as AudioStream
  watch(audioCodecKey)

  const selectedCodec = audioStream.codec
  const codecFeatures = supportedCodecs.find(({ name }: SupportedAudioCodec) => name === selectedCodec)
  const codecOptions: RichOption[] = supportedCodecs.map((c) => ({ name: c.name, value: c.name }))
  useEffect(
    () => ensureSelectedValueIsAvailable(audioCodecKey, selectedCodec, codecOptions),
    [selectedCodec, codecOptions],
  )

  const selectedBitrate = audioStream.bitrate
  const bitrateOptions: RichOption[] = (codecFeatures?.bitratesKbps ?? []).map((b) => ({
    name: b.toString(),
    value: b,
  }))
  useEffect(
    () => ensureSelectedValueIsAvailable(audioBitrateKey, selectedBitrate, bitrateOptions),
    [selectedBitrate, bitrateOptions],
  )

  const selectedBitDepth = audioStream.bitDepth
  const bitDepthOptions: RichOption[] = (codecFeatures?.bitDepth ?? []).map((b) => ({ name: b.toString(), value: b }))
  useEffect(
    () => ensureSelectedValueIsAvailable(audioBitDepthKey, selectedBitDepth, bitDepthOptions),
    [selectedBitDepth, bitDepthOptions],
  )

  const ensureSelectedValueIsAvailable = useCallback(
    (formFieldKey: string, currentValue: RichOption['value'], availableValues: RichOption[]) => {
      const isCurrentlySelectedOptionAvailable = availableValues
        .filter((o) => !o.disabled)
        .map((o) => o.value)
        .includes(currentValue)
      const firstAvailableValue = availableValues[0]?.value
      if (!isCurrentlySelectedOptionAvailable && firstAvailableValue !== undefined) {
        setValue(formFieldKey, firstAvailableValue, { shouldValidate: true })
      }
    },
    [setValue],
  )

  return (
    <Paper>
      {codecOptions.length > 0 && (
        <Select
          label="Audio Codec"
          name={audioCodecKey}
          required
          options={codecOptions}
          disabled={!!selectedCodec && codecOptions.length === 1}
        />
      )}

      <Select label="Audio Pair" name={`${namePrefix}.pair`} required options={sdiAudioPairs} />

      {bitrateOptions.length > 0 && (
        <Select
          label="Audio Bitrate (Kbps)"
          name={audioBitrateKey}
          required
          options={bitrateOptions}
          disabled={!!selectedBitrate && bitrateOptions.length === 1}
        />
      )}

      {bitDepthOptions.length > 0 && (
        <Select
          label="Audio Bit depth"
          name={audioBitDepthKey}
          required
          options={bitDepthOptions}
          disabled={!!selectedBitDepth && bitDepthOptions.length === 1}
        />
      )}

      {selectedCodec === AudioCodec.ac3pt && (
        <Select
          label="System standard"
          name={`${namePrefix}.systemStandard`}
          required
          options={Object.values(AudioSystemStandard)}
        />
      )}
      <GridItem newLine>
        <Button variant="outlined" color="primary" onClick={onRemove}>
          Remove Audio Stream
        </Button>
      </GridItem>
    </Paper>
  )
}

export default AudioStreamForm
