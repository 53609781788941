import { GridItem, Select, TextInput } from '../../../common/Form'
import { RichOption } from '../../../common/Form/Select'
import { ComprimatoNdiOutput, Output } from 'common/api/v1/types'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

interface NdiFormProps {
  namePrefix: string
}

const NdiForm = ({ namePrefix }: NdiFormProps) => {
  const { getValues, setValue, watch } = useFormContext<Output & AllowUncontrolled>()

  const logicalPort: ComprimatoNdiOutput = getValues(namePrefix)

  const modeKey = `${namePrefix}.mode`
  watch(modeKey)

  const selectedMode = logicalPort.mode
  const modeOptions: RichOption[] = useMemo(() => [{ name: 'NDI', value: 'comprimatoNdi' }], [])
  useEffect(() => {
    const defaultMode = modeOptions[0]
    const isValidMode = modeOptions.some((m) => m.value === selectedMode)
    if (!isValidMode && defaultMode) {
      setValue(modeKey, defaultMode.value)
    }
  }, [modeKey, setValue, modeOptions, selectedMode])

  return (
    <>
      <Select
        label="Mode"
        name={modeKey}
        xs={4}
        lg={4}
        xl={4}
        required
        newLine
        options={modeOptions}
        disabled={selectedMode ? modeOptions.length === 1 : false}
        validators={{
          oneOf: { validValues: new Set(modeOptions.filter((o) => !o.disabled).map(({ value }) => value)) },
        }}
      />
      <GridItem xs={8} lg={8} xl={8}>
        <TextInput name={`${namePrefix}.name`} label="Name" required />
      </GridItem>
    </>
  )
}

export default NdiForm
