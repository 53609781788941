import { FC, isValidElement, ReactNode, useEffect, useState } from 'react'
import cn from 'classnames'
import { Theme, TypographyVariant as Variant } from '@mui/material/styles'
import Button, { type ButtonProps } from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../Tooltip'
import Typography from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HelpOutline from '@mui/icons-material/HelpOutline'

const styles = {
  buttonContainer: {
    justifyContent: 'flex-end',
    marginRight: 1,
    '& > div + div': {
      marginLeft: 2,
    },
  },
  tooltip: {
    fontSize: (theme: Theme) => theme.typography.body2.fontSize,
  },
  icon: {
    marginLeft: 1,
    transform: 'translateY(3px)',
  },
  paper: {
    padding: [1, 2],
    marginY: 2,
    width: '100%',
    '&.outlined': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      border: (theme: Theme) => `1px solid ${theme.palette.background.paper}`,
      '&.error': {
        borderColor: (theme: Theme) => theme.palette.error.main,
      },
    },
  },
}

interface Action {
  title: string
  onClick: ButtonProps['onClick']
  id?: string
  props?: ButtonProps
}
interface PaperProps {
  actionsPane?: Array<Action | ReactNode>
  className?: string // 'outlined' | 'error'
  sx?: MuiPaperProps['sx']
  collapsible?: boolean
  collapsed?: boolean
  id?: string
  title?: string | ReactNode
  titleTypography?: Variant
  onCollapseChange?: (collapsed: boolean) => void
  tooltip?: string | ReactNode
  children?: ReactNode
}
/**
 * Some enhancements on the top of MaterialUI Paper
 * @param collapsible - should we show button which expands/collapses the paper
 * @param title - paper's title
 * @param children - content
 * @param collapsed - if it is collapsible we can make it collapsed by default
 * @param actionsPane - bottom line of buttons
 * @param titleTypography - Typography variant for title
 * @param id
 */
const Paper: FC<PaperProps> = ({
  className = '',
  sx = {},
  collapsible = false,
  title = '',
  children,
  collapsed: col = false,
  actionsPane = [],
  titleTypography,
  id: dataTestId,
  onCollapseChange,
  tooltip,
}) => {
  const [collapsed, setCollapsed] = useState(col)
  useEffect(() => {
    setCollapsed(col)
  }, [col])
  const toggle = () => {
    if (collapsible) {
      setCollapsed(!collapsed)
    }
    if (onCollapseChange) {
      onCollapseChange(!collapsed)
    }
  }

  return (
    <MuiPaper
      sx={{ ...styles.paper, ...sx }}
      className={className}
      data-testid={dataTestId}
      data-test-id={dataTestId || `paper-${title}`}
    >
      {(title || collapsible) && (
        <Toolbar disableGutters variant="dense">
          <Typography
            variant={titleTypography || 'h2'}
            sx={{
              flexGrow: 1,
              cursor: 'pointer',
            }}
            className={cn(collapsible)}
            onClick={toggle}
          >
            {title}
            {tooltip && (
              <Tooltip title={tooltip} sx={{ tooltip: styles.tooltip }} placement="right">
                <HelpOutline sx={styles.icon} />
              </Tooltip>
            )}
          </Typography>
          {collapsible && (
            <Tooltip title={collapsed ? 'Expand' : 'Collapse'} placement="top">
              <IconButton onClick={toggle}>{collapsed ? <ExpandMore /> : <ExpandLess />}</IconButton>
            </Tooltip>
          )}
        </Toolbar>
      )}
      <Collapse in={!collapsed}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            marginLeft: '0',
          }}
        >
          {children}
        </Grid>
        {Boolean(actionsPane.length) && (
          <Toolbar disableGutters variant="dense" sx={styles.buttonContainer}>
            {actionsPane.map((item, ind) => (
              <FormControl margin="normal" key={ind}>
                {isValidElement(item) ? (
                  item
                ) : (
                  <Button
                    id={item ? (item as Action).id : undefined}
                    variant="contained"
                    color="secondary"
                    onClick={(item as Action).onClick}
                    {...(item as Action).props}
                  >
                    {(item as Action).title}
                  </Button>
                )}
              </FormControl>
            ))}
          </Toolbar>
        )}
      </Collapse>
    </MuiPaper>
  )
}

export default Paper
