import { createSlice } from '@reduxjs/toolkit'

import {
  createUser,
  fetchCurrentUser,
  impersonateUser,
  loginUser,
  logoutUser,
  setAuthorizationError,
  stopImpersonation,
  updateUser,
} from '../actions/userActions'
import { isOneOf } from '../actions'
import { EnrichedUserWithSettings } from '../../api/nm-types'
import { createLoadingReducer } from './shared'
import type { ErrorInfo } from '../../components/common/ApplicationException'

export interface UserReducerState {
  loading: boolean
  saving?: boolean
  loginFail?: ErrorInfo
  user: EnrichedUserWithSettings | unknown
  changingUser: boolean
}

const initialStateUser: UserReducerState = { user: {}, loading: false, changingUser: false }

const { isLoadingAction, loadingReducer } = createLoadingReducer<UserReducerState>(
  loginUser,
  logoutUser,
  impersonateUser,
  stopImpersonation,
  fetchCurrentUser,
)

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateUser,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        setAuthorizationError,
        (state, { payload: loginFail }): UserReducerState => ({ ...state, loginFail, changingUser: false }),
      )
      .addCase(
        loginUser.fulfilled,
        (state, { payload }): UserReducerState => ({ ...state, user: payload, changingUser: false }),
      )
      .addCase(logoutUser.fulfilled, (): UserReducerState => initialStateUser)
      .addCase(
        impersonateUser.fulfilled,
        (state, { payload: user }): UserReducerState => ({ ...state, user, changingUser: false }),
      )
      .addCase(
        stopImpersonation.fulfilled,
        (state, { payload: user }): UserReducerState => ({ ...state, user, changingUser: false }),
      )
      .addCase(fetchCurrentUser.fulfilled, (state, { payload }): UserReducerState => ({ ...state, user: payload }))
      .addMatcher(
        isOneOf([loginUser.pending, logoutUser.pending, impersonateUser.pending, stopImpersonation.pending]),
        (state): UserReducerState => ({ ...state, changingUser: true }),
      )
      .addMatcher(
        isOneOf([loginUser.rejected, logoutUser.rejected, impersonateUser.rejected, stopImpersonation.rejected]),
        (state): UserReducerState => ({ ...state, changingUser: false }),
      )
      .addMatcher(
        isOneOf([createUser.pending, updateUser.pending]),
        (state): UserReducerState => ({ ...state, saving: true }),
      )
      .addMatcher(
        isOneOf([createUser.fulfilled, updateUser.fulfilled]),
        (state): UserReducerState => ({
          ...state,
          saving: undefined,
        }),
      )
      .addMatcher(
        isOneOf([createUser.rejected, updateUser.rejected]),
        (state): UserReducerState => ({
          ...state,
          saving: false,
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default userSlice.reducer
