import { createAsyncThunk } from '@reduxjs/toolkit'
import { DeletedOverlay, Overlay } from 'common/api/v1/types'
import { ThunkApi } from 'src/store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'

export const listOverlays = createAsyncThunk<Overlay[], void, ThunkApi>(
  'overlays/listOverlays',
  async (_, { extra: { api } }) => {
    return await api.overlaysApi.listOverlays()
  },
)

export const getOverlay = createAsyncThunk<Overlay, string, ThunkApi>(
  'overlays/getOverlay',
  async (id, { dispatch, extra: { api } }) => {
    try {
      return await api.overlaysApi.getOverlay(id)
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'get overlay' }))
      throw error
    }
  },
)

export const updateOverlay = createAsyncThunk<Overlay, Overlay, ThunkApi>(
  'overlays/updateOverlay',
  async (overlay, { dispatch, extra: { api } }) => {
    try {
      const res = await api.overlaysApi.updateOverlay(overlay)
      dispatch(enqueueSuccessSnackbar('Overlay saved'))
      return res
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'update overlay' }))
      throw error
    }
  },
)

export const deleteOverlay = createAsyncThunk<DeletedOverlay, string, ThunkApi>(
  'overlays/deleteOverlay',
  async (id, { dispatch, extra: { api } }) => {
    try {
      const res = await api.overlaysApi.deleteOverlay(id)
      dispatch(enqueueSuccessSnackbar('Overlay deleted'))
      return res
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: 'delete overlay' }))
      throw error
    }
  },
)
