import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import { PermissionAction } from '../../../../api/inputs/api'
import { GroupWithPermission } from '../../../../api/groups/api'
import { AppDispatch, GlobalState } from '../../../../store'
import { DraftActions, draftInputs, updateInputDistribution } from '../../../../redux/actions/inputsActions'
import { GroupOrGroupRecipientList, InputAccessType } from 'common/api/v1/types'

import Title from '../../../common/Dialog/Title'
import { isGroup } from '../../../../utils'

import Recipients from './Recipients'
import AvailableGroups from './AvailableGroups'
import Pendable from '../../../common/Pendable'

const SharingDialog = () => {
  const {
    draft: { inputs, action },
    dialogSaving,
  } = useSelector(
    ({ inputsReducer }: GlobalState) => ({ draft: inputsReducer.draft, dialogSaving: inputsReducer.dialogSaving }),
    shallowEqual,
  )
  const dispatch = useDispatch<AppDispatch>()
  const editedInput = inputs[0]
  const [receiversChanges, setReceiversChanges] = useState<{ [key: string]: PermissionAction }>({})
  const [availableChanges, setAvailableChanges] = useState<{ [key: string]: InputAccessType }>({})
  const [listChanges, setListChanges] = useState<{ [key: string]: InputAccessType }>({})
  const [initialOpen, setInitialOpen] = useState(true)

  const onClose = () => {
    setAvailableChanges({})
    setReceiversChanges({})
    setListChanges({})
    dispatch(draftInputs({ inputs: [] }))
  }
  const onSave = () => {
    dispatch(
      updateInputDistribution({
        id: editedInput.id,
        permissions: [
          ...Object.entries(receiversChanges).map(([groupId, action]) => ({
            permission: {
              groupId,
              accessType: InputAccessType.view,
            },
            action,
          })),
          ...Object.entries(availableChanges).map(([groupId, accessType]) => ({
            permission: {
              groupId,
              accessType,
            },
            action: PermissionAction.add,
          })),
        ],
        listPermissions: listChanges,
      }),
    )
  }
  useEffect(() => {
    if (initialOpen) setInitialOpen(false)
    else if (!dialogSaving) onClose()
  }, [dialogSaving])

  if (!editedInput) return null

  const onRemoveNew = (group: GroupWithPermission) =>
    setReceiversChanges({ ...receiversChanges, [group.id]: PermissionAction.remove })
  const onSwitchNew = (group: GroupWithPermission) =>
    setReceiversChanges({ ...receiversChanges, [group.id]: PermissionAction.change })
  const onCancelRecipient = (group: GroupWithPermission) => setReceiversChanges(omit(receiversChanges, group.id))
  const onShare = (group: GroupOrGroupRecipientList, permission: InputAccessType) =>
    isGroup(group)
      ? setAvailableChanges({ ...availableChanges, [group.id]: permission })
      : setListChanges({ ...listChanges, [group.id]: permission })
  const onCancelAvailable = (group: GroupOrGroupRecipientList) =>
    isGroup(group) ? setAvailableChanges(omit(availableChanges, group.id)) : setListChanges(omit(listChanges, group.id))

  return (
    <Dialog
      open={!!editedInput && action === DraftActions.share}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      id="share-input-dialog"
    >
      <Title title={`Share "${editedInput.name}" to:`} onClose={onClose} />
      <DialogContent sx={{ paddingBottom: 0 /* Remove bot padding for sticky pagination to look good */ }}>
        <Recipients
          input={editedInput}
          onCancel={onCancelRecipient}
          onRemove={onRemoveNew}
          onSwitch={onSwitchNew}
          changes={receiversChanges}
        />
        <AvailableGroups
          input={editedInput}
          changes={availableChanges}
          listChanges={listChanges}
          onShare={onShare}
          onCancel={onCancelAvailable}
        />
      </DialogContent>
      <DialogActions>
        <Pendable pending={!!dialogSaving}>
          <Button variant="outlined" color="secondary" onClick={onClose} data-test-id="cancel-btn">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSave} data-test-id="save-btn">
            Save
          </Button>
        </Pendable>
      </DialogActions>
    </Dialog>
  )
}

export default SharingDialog
